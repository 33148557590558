import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ThemeOptions } from '../models/theme.model';

@Injectable({
   providedIn: 'root',
})
export class ThemeService {
   constructor(@Inject(DOCUMENT) private document: Document) {}

   setVariable(name, value) {
      this.document.documentElement.style.setProperty(name, value);
   }

   setTheme(theme: ThemeOptions) {
      if (!theme) {
         return;
      }

      const $html = document.querySelector('html');
      $html.classList.toggle(ThemeOptions.DarkMode);

      if (theme) {
         // const configuration = this.localStorageService.getConfiguration();
         // if (configuration.PrimaryColor !== null && configuration.PrimaryColor && configuration.PrimaryColor.length > 0) {
         //    document.body.style.setProperty('--primary-color', configuration.PrimaryColor);
         // }
         // if (configuration.SecondaryColor !== null && configuration.SecondaryColor && configuration.SecondaryColor.length > 0) {
         //    document.body.style.setProperty('--secondary-color', configuration.SecondaryColor);
         // }
         // if (configuration.TextColor !== null && configuration.TextColor && configuration.TextColor.length > 0) {
         //    document.body.style.setProperty('--text-color', configuration.TextColor);
         // }
      } else {
         document.body.style.setProperty('--primary-color', '#303030');
         document.body.style.setProperty('--secondary-color', '#3d3d3d');
         document.body.style.setProperty('--text-color', '#fdfdfd');
      }
   }

   setDarkLightMode(primaryColor: string) {
      const $html = document.querySelector('html');
      $html.classList.toggle(ThemeOptions.DarkMode);

      if ($html.classList.value.includes(ThemeOptions.DarkMode)) {
         document.body.style.setProperty('--primary-color', '#303030');
         localStorage.setItem('pede-mais-garcom-theme', ThemeOptions.DarkMode);
      } else {
         document.body.style.setProperty('--primary-color', primaryColor || '#004a8f');
         localStorage.setItem('pede-mais-garcom-theme', ThemeOptions.LightMode);
      }
   }

   isDarkMode() {
      const user = JSON.parse(localStorage.getItem('pede-mais-delivery-user'));
      return user.Theme === ThemeOptions.DarkMode;
   }

   setFontSize(fontSize: number) {
      if (fontSize && fontSize > 1) {
         const $html = document.querySelector('html');
         $html.style.fontSize = `${fontSize}%`;
      }
   }
}
