import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgxMaskModule } from 'ngx-mask';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { ErrorHandlerService } from './core/services/error-handler.service';
import { SharedModule } from './shared/shared.module';

@NgModule({
   declarations: [AppComponent],
   imports: [
      BrowserModule,
      BrowserAnimationsModule,
      HttpClientModule,
      ToastrModule.forRoot({
         progressAnimation: 'increasing',
         preventDuplicates: false,
      }),
      SharedModule,
      AppRoutingModule,
      CoreModule,
      NgxMaskModule.forRoot(),
   ],
   providers: [
      // {
      //    provide: HTTP_INTERCEPTORS,
      //    useClass: LoadingInterceptor,
      //    multi: true,
      // },
      { provide: ErrorHandler, useClass: ErrorHandlerService },
   ],
   bootstrap: [AppComponent],
})
export class AppModule {}
