import { Injectable } from '@angular/core';
import { DeliveryParameterization } from '../models/delivery-service.model';
import { TrackInitiateCheckout } from '../models/tracking.model';
import { FacebookPixelService } from './facebook-pixel.service';
import { GoogleAnalyticsService } from './google-analytics.service';

@Injectable({
   providedIn: 'root',
})
export class TrackerService {
   constructor(private facebookPixelService: FacebookPixelService, private googleAnalyticsService: GoogleAnalyticsService) {}

   trackPageView(parameterization: DeliveryParameterization, route: string) {
      if (parameterization.IsFacebookPixelEnabled) {
         this.facebookPixelService.trackPageView(parameterization.FacebookPixelId, route);
      }

      if (parameterization.IsGoogleAnalyticEnabled) {
         this.googleAnalyticsService.trackPageView(parameterization.GoogleMetricId, parameterization.IsGoogleAnalyticEnabled, route);
      }
   }

   trackInitiateCheckout(parameterization: DeliveryParameterization, params: TrackInitiateCheckout) {
      if (parameterization.IsFacebookPixelEnabled) {
         this.facebookPixelService.trackInitiateCheckout(parameterization.FacebookPixelId, params);
      }

      if (parameterization.IsGoogleAnalyticEnabled) {
         this.googleAnalyticsService.trackInitiateCheckout(
            parameterization.GoogleMetricId,
            parameterization.IsGoogleAnalyticEnabled,
            params
         );
      }
   }

   trackPurchase(parameterization: DeliveryParameterization, totalValue: number) {
      if (parameterization.IsFacebookPixelEnabled) {
         this.facebookPixelService.trackPurchase(parameterization.FacebookPixelId, totalValue);
      }

      if (parameterization.IsGoogleAnalyticEnabled) {
         this.googleAnalyticsService.trackPurchase(parameterization.GoogleMetricId, parameterization.IsGoogleAnalyticEnabled, totalValue);
      }
   }
}
