import { Language } from 'src/app/shared/models/language.model';
import { ThemeOptions } from './theme.model';

export class UserSummary {
   Id: string;
   Name: string;
   CellPhone: string;
   Email: string;
   DocumentNumber: string;
}

export class User {
   Id: string;
   Name: string;
   PerfilImage: string;
   CellPhone: string;
   Email: string;
   DocumentNumber: string;
   Password: string;
   PasswordConfirmation: string;
   Language: Language;
   Theme: ThemeOptions;
   IsDarkModeTheme: boolean;
   FontSize: number;
   CanRateCompany: boolean;
   Rating?: number;
   RatingDescription?: string;
   CompanyRatedOn: Date;
   LastAccessOn: Date;
   Addresses: UserAddress[];

   constructor() {
      this.Theme = ThemeOptions.LightMode;
      this.IsDarkModeTheme = false;
      this.LastAccessOn = new Date();
      this.CanRateCompany = true;
      this.Language = new Language();
      this.FontSize = 1;
      this.Addresses = [];
   }
}

export class AddUserAddress {
   StreetName: string;
   StreetNumber: string;
   Complement: string;
   Reference: string;
   Neighborhood: string;
   PostalCode: string;
   City: string;
   State: string;
   Country: string;
   Latitude?: number;
   Longitude?: number;
}

export class UserAddress {
   Id: string;
   StreetName: string;
   StreetNumber: string;
   Complement: string;
   Reference: string;
   Neighborhood: string;
   PostalCode: string;
   City: string;
   State: string;
   Country: string;
   Latitude?: number;
   Longitude?: number;
   ShortAddress: string;
   ShortCityAddress: string;
   FullAddress: string;
   IsFavorite: boolean;
}

export class UpdateUser {
   Name: string;
   CellPhone: string;
   Email: string;
   DocumentNumber: string;
}
