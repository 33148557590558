import { Component, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheet } from '@angular/material/bottom-sheet';
import { FunctionService } from '../../utils/function.service';

@Component({
   selector: 'modal-share-social-media',
   templateUrl: './share-social-media.component.html',
   styleUrls: ['./share-social-media.component.scss'],
})
export class ShareSocialMediaComponent implements OnInit {
   public friendlyUrl: string;

   constructor(
      private _bottomSheet: MatBottomSheet,
      private functionService: FunctionService,
      @Inject(MAT_BOTTOM_SHEET_DATA) public data: { friendlyUrl: string }
   ) {
      this.friendlyUrl = data.friendlyUrl;
   }

   ngOnInit(): void {}

   close() {
      this._bottomSheet.dismiss();
   }

   shareOnWhatsapp() {
      const webOrMobile = this.functionService.isMobile() ? 'api' : 'web';
      const whatsAppLink = `https://${webOrMobile}.whatsapp.com/send/?text=Confira%20esse%20card%C3%A1pio!%20https://pede.kcms.app/${this.friendlyUrl}`;
      window.open(whatsAppLink, '_blank');
      this.close();
   }

   shareOnFacebok() {
      const facebookLink = `https://www.facebook.com/sharer/sharer.php?u=https://pede.kcms.app/${this.friendlyUrl}`;
      window.open(facebookLink, '_blank');
      this.close();
   }

   copyLink() {
      this.functionService.copyTextToClipboard(`https://pede.kcms.app/${this.friendlyUrl}`, 'CopyUrl');
      this.close();
   }
}
