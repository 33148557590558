import { DeliveryServiceProduct, DeliveryServiceProductStep } from './delivery-service.model';
import { DiscountCouponCategoryType, DiscountCouponType } from './discount-coupon.model';

export class CartItem {
   Id: string;
   ProductId: string;
   ProductBarcode: string;
   ProductName: string;
   OriginalProductName: string;

   MarketingClassificationId: string;
   MarketingClassificationName: string;
   OriginalMarketingClassificationName: string;

   ProductUnitAcronym: string;
   CanBeFractioned: boolean;

   AdditionalInfo: string;
   ImageUrl: string;

   Observation: string;

   MaxQuantityOnSale: number;
   IsProductsAgeRestricted: boolean;
   ServesHowManyPeople?: number | 0;

   CostValue: number;
   GrossValue: number;

   Quantity: number;
   QuantityToShow: number;

   ProductStepMinValue: number;
   SellValue: number;
   OriginalSellValue: number;
   SubItemsValue: number;
   TotalValue: number;

   StepIndex?: number;
   SubItems: CartSubItem[];

   ProductSteps?: DeliveryServiceProductStep[];
   CompositionMembers?: DeliveryServiceProduct[];

   IsCombo: boolean;
   HasComplements: boolean;
   IsComposition: boolean;

   constructor() {
      this.Quantity = 0;
      this.QuantityToShow = 0;
      this.CostValue = 0;
      this.SellValue = 0;
      this.SubItemsValue = 0;
      this.GrossValue = 0;
      this.TotalValue = 0;
      this.IsCombo = false;
      this.HasComplements = false;
      this.IsComposition = false;
      this.SubItems = [];
   }
}

export class CartSubItem {
   Id: string;
   ProductId: string;
   ProductBarcode: string;

   ProductName: string;
   OriginalProductName: string;

   MarketingClassificationId: string;
   MarketingClassificationName: string;
   OriginalMarketingClassificationName: string;

   Quantity: number;
   QuantityToShow?: number;
   FractionedQuantity?: string;

   SellValue: number;
   OriginalSellValue: number;
   GrossValue: number;
   TotalValue: number;

   StepIndex: number;
   CanBeFractioned?: boolean;

   constructor() {
      this.Quantity = 0;
      this.QuantityToShow = 0;
      this.SellValue = 0;
      this.GrossValue = 0;
      this.TotalValue = 0;
   }
}

export class CartAddress {
   Id: string;
   FullAddress: string;
   ShortAddress: string;
   ShortCityAddress: string;
   StreetName: string;
   StreetNumber: string;
   Neighborhood: string;
   Complement: string;
   Reference: string;
   City: string;
   PostalCode: string;
   State: string;
   Country: string;
   Latitude?: number;
   Longitude?: number;

   constructor() {}
}

export class DeliveryFee {
   CompanyMessage?: string;
   CustomerMessage?: string;
   DeliveryFee: number;
   TimeToDeliver: number;
   Distance?: number;
}

export class CartPaymentForm {
   Id: string;
   Name: string;
   OriginalName: string;
   Icon: string;
   ChangeValueFor: number;
   IsPrepaid: boolean;

   constructor() {
      this.ChangeValueFor = 0;
      this.IsPrepaid = false;
   }
}

export class CartDiscountCoupon {
   constructor(
      id: string,
      code: string,
      minimumOrderValue: number,
      isAvailable: boolean,
      couponType?: DiscountCouponType,
      couponValue?: number,
      couponCategory?: DiscountCouponCategoryType
   ) {
      this.Id = id;
      this.Code = code;
      this.MinimumOrderValue = minimumOrderValue;
      this.IsAvailable = isAvailable;
      this.CouponType = couponType || DiscountCouponType.Percentage;
      this.CouponValue = couponValue || 0;
      this.CouponCategory = couponCategory || DiscountCouponCategoryType.OnFreeDelivery;
      this.CouponCategoryName = `DiscountCouponCategory${couponCategory}`;
      this.CouponTypeName = `DiscountCouponType${couponType}`;
   }
   Id: string;
   CompanyId: number;
   Code: string;
   MinimumOrderValue: number;
   IsAvailable: boolean;
   CouponType?: DiscountCouponType;
   CouponValue: number;
   CouponCategory: DiscountCouponCategoryType;
   CouponCategoryName: string;
   CouponTypeName: string;
}

export class CartSummary {
   HasItems: boolean;
   ItemsCount: number;
   SubtotalValue: number;
   DeliveryFee: number;
   DiscountCouponValue: number;
   TotalValue: number;

   constructor() {
      this.HasItems = false;
      this.ItemsCount = 0;
      this.SubtotalValue = 0;
      this.DeliveryFee = 0;
      this.DiscountCouponValue = 0;
      this.TotalValue = 0;
   }
}

export class CartOnlinePayment {
   public CompanyId: number;
   public IntegrationType: CartIntegrationType;
   public IntegrationId: string;
   public IntegrationCode: string;
   public ReferenceId: string;
   public OrderId: string;
   public MediatorCode: string;
   public Status: CartOnlinePaymentStatusType;
   public CopyAndPaste: string;
   public QrCode: string;
   public ExpiresAt: string;
   public TotalValue: number;

   constructor() {
      this.Status = CartOnlinePaymentStatusType.Creating;
   }
}

export class Cart {
   CompanyId: number;
   Version: string;
   Items: CartItem[] = [];
   IsDelivery: boolean;
   IsPickUp: boolean;
   IsScheduled: boolean;
   Status: CartStatusType;
   OrderMinValueForFreeDeliveryFee: number;
   EstimatedTimeToPrepare: number;
   Address: CartAddress;
   DeliveryFee: DeliveryFee;
   HasDiscountCoupon: boolean;
   DiscountCoupon: CartDiscountCoupon;
   PaymentForm: CartPaymentForm;
   OnlinePayment?: CartOnlinePayment;
   Summary: CartSummary;
   LastModifiedOn: string;

   constructor(companyId: number, version: string) {
      this.CompanyId = companyId;
      this.Version = version;
      this.Status = CartStatusType.Avaiable;
      this.IsDelivery = false;
      this.IsPickUp = false;
      this.IsScheduled = false;
      this.HasDiscountCoupon = false;
      this.OrderMinValueForFreeDeliveryFee = 0;
      this.EstimatedTimeToPrepare = 0;
      this.Items = [];
      this.Address = null;
      this.DeliveryFee = null;
      this.PaymentForm = null;
      this.OnlinePayment = null;
      this.Summary = new CartSummary();
      this.LastModifiedOn = new Date().toString();
   }
}

export enum CartStatusType {
   Avaiable = 0,
   Busy = 1,
   InPayment = 2,
   Paid = 3,
   Expired = 4,
}

export enum CartIntegrationType {
   KcmsPix = 1,
   PicPay = 2,
}

export enum CartOnlinePaymentStatusType {
   Creating = 0,
   Created = 1,
   Approved = 2,
   Rejected = 3,
   Canceled = 5,
   Expired = 7,
}
