<div class="header-bottom-sheet">
   <i class="fa-light fa-xmark" (click)="close()"></i>
</div>
<div class="coupon-content">
   <mat-form-field class="example-full-width">
      <input matInput type="text" maxlength="30" [(ngModel)]="discountCouponCode" placeholder="{{'CouponCode' | i18n}}"
         autofocus>
   </mat-form-field>
   <button type="button" [disabled]="!discountCouponCode" (click)="addDiscountCoupon()">
      {{'ToApply' | i18n}}
   </button>
</div>