import { Component, Input, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { take } from 'rxjs/operators';
import { OrderHistoryGroupedByDate } from 'src/app/core/models/order-history.model';
import { DeliveryService } from 'src/app/core/services/delivery-service.service';
import { DeliveryParameterization } from './../../../models/delivery-service.model';
import { DetailOrderHistoryComponent } from './detail-order.component';
import { RateOrderComponent } from './rate-order/rate-order.component';

@Component({
   selector: 'kcms-card-order',
   templateUrl: './card-order.component.html',
   styleUrls: ['./card-order.component.scss'],
})
export class CardOrderHistoryComponent implements OnInit {
   @Input() parameterization: DeliveryParameterization;
   @Input() isRepeat = false;
   @Input() isReview = false;
   @Input() orderHistory: OrderHistoryGroupedByDate[];

   constructor(private _bottomSheet: MatBottomSheet, private deliveryService: DeliveryService) {}

   ngOnInit(): void {}

   handleOpenDetail(orderId: string) {
      if (orderId) {
         this.deliveryService
            .getDeliveryOrderDetails(orderId)
            .pipe(take(1))
            .subscribe(
               (order) => {
                  this._bottomSheet.open(DetailOrderHistoryComponent, {
                     data: { order: order },
                  });
               },
               (err) => {}
            );
      }
   }

   openRateModal(orderId) {
      this._bottomSheet
         .open(RateOrderComponent, {
            data: { companyId: this.parameterization.CompanyId, orderId: orderId },
         })
         .afterDismissed()
         .subscribe(() => {
            this.orderHistory.forEach((ret) => {
               ret.OrderList.filter((n) => n.Id === orderId).map((order) => {
                  order.AlreadyReviewed = true;
               });
            });
         });
   }
}
