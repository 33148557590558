import { Injectable } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';

@Injectable({
   providedIn: 'root',
})
export class DocumentService {
   private title = 'og:title';

   constructor(private meta: Meta, private titleService: Title) {}

   setDocumentTitle(title: string) {
      this.titleService.setTitle(`KCMS Pedemais ${title}`);
   }

   updateMetaTag(tagDefinition: MetaDefinition, isProperty: boolean) {
      if (isProperty) {
         this.meta.updateTag({ property: tagDefinition.property, content: tagDefinition.content }, `property='${tagDefinition.property}'`);
      } else {
         this.meta.updateTag({ name: tagDefinition.property, content: tagDefinition.content }, `name='${tagDefinition.name}'`);
      }
   }

   overflowService(overflow: boolean = true) {
      const $html = document.querySelector('html');
      if (overflow) {
         $html.style.overflow = 'auto';
      } else {
         $html.style.overflow = 'hidden';
      }
   }
}
