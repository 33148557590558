<ng-container *ngIf="showModal && parameterization">
    <div class="modal modal-address multiple-dialog" @modal>
        <div class="address-content">
            <div class="header-modal">
                <i class="fa-solid fa-arrow-left" (click)="close(false)"></i>
                <div class="title-modal"> {{'AddressSearch' | i18n}} </div>
            </div>
            <div class="new-group-address">
                <div class="group-list-addresses" *ngIf="userInfo.Addresses && userInfo.Addresses.length > 0">
                    <div class="address-item" *ngFor="let address of userInfo.Addresses; let i = index,"
                        [ngClass]="{'main' : address.IsFavorite}">
                        <i class="fa-solid fa-circle-check icon-check" *ngIf="address.IsFavorite"></i>
                        <div class="address-name" (click)="setMainAddress(address)">
                            {{address.ShortAddress}}
                            <p> {{address.ShortCityAddress}} </p>
                        </div>
                        <i class="fa-light fa-trash-can icon-delete" *ngIf="!address.IsFavorite"
                            (click)="removeAddress(address.Id)"></i>
                    </div>
                </div>
                <div class="no-address" *ngIf="userInfo.Addresses && userInfo.Addresses.length === 0">
                    <i class="fa-light fa-location-dot-slash"></i>
                    <div class="not-found-text">
                        {{'NoAddressFound' | i18n}}
                    </div>
                </div>
                <div class="group-address">
                    <div class="footer-search">
                        <button *ngIf="parameterization.IsPickupEnabled && showPickupOption" type="button"
                            class="btn-defaut" (click)="setPickupOrderMode()" [disabled]="isSaving">
                            <i class="fa-light fa-store"></i>
                            {{'PickUpInStore' | i18n}}
                        </button>
                        <button type="button" (click)="openAddOrderAddress()" [disabled]="isSaving">
                            <i class="fa-light fa-location-crosshairs"></i>
                            {{'NewAddres' | i18n}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="showOverlay" @overlay class="overlay"></div>
</ng-container>
<modal-add-order-address [parameterization]="parameterization"
    (afterSaveAddress)="setMainAddress($event)"></modal-add-order-address>