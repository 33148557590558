import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { DeliveryParameterization } from 'src/app/core/models/delivery-service.model';
import { AddUserAddress, UserAddress } from 'src/app/core/models/user.model';
import { AddressService } from 'src/app/core/services/address.service';
import { DeliveryService } from 'src/app/core/services/delivery-service.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { UserService } from 'src/app/core/services/user.service';
import { transitionAnimation } from 'src/app/core/utils/animations';
import { FunctionService } from 'src/app/core/utils/function.service';

@Component({
   selector: 'modal-add-order-address',
   templateUrl: './add-order-address.component.html',
   styleUrls: ['./add-order-address.component.scss'],
   animations: [transitionAnimation],
})
export class AddOrderAddressComponent implements OnInit, OnDestroy {
   private subscriptions: Subscription[] = [];
   public showModal = false;
   public isSearching = false;
   public urlToBack: string;
   public showAddressData: boolean;
   public showAutoComplete: boolean;
   public isNotAddressNumber: boolean = false;
   public address: AddUserAddress = new AddUserAddress();
   public sessionToken: any;
   public isMobile = this.functionService.isMobile();

   // @ViewChild(MapComponent) mapComponent: MapComponent;
   @Input() parameterization: DeliveryParameterization;

   @Output() afterSaveAddress = new EventEmitter<UserAddress>();

   @HostListener('window:popstate', ['$event'])
   onPopState(event: Event) {
      if (this.showModal) {
         this.close();
      }
   }

   constructor(
      private deliveryService: DeliveryService,
      private notificationService: NotificationService,
      private userService: UserService,
      private functionService: FunctionService,
      private addressService: AddressService,
      private loadingService: LoadingService
   ) {}

   ngOnInit(): void {}

   ngOnDestroy() {
      if (this.subscriptions) {
         this.subscriptions.forEach((s) => s.unsubscribe());
      }
   }

   open(url: string) {
      this.isSearching = false;
      this.showModal = true;
      this.showAddressData = false;
      this.showAutoComplete = false;
      this.address = new AddUserAddress();

      this.urlToBack = url;
      // history.pushState(null, 'Pedemais Delivery | Enderecos', this.urlToBack.split('produtos').join('enderecos'));

      if (this.isMobile) this.deliveryService.overflowService(false);

      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
   }

   close() {
      // history.pushState(null, 'Pedemais Delivery', this.urlToBack);
      this.deliveryService.overflowService(true);
      this.showModal = false;
   }

   setShowAutoComplete() {
      this.showAutoComplete = true;
      this.showAddressData = false;
   }

   alterAddressNumber($event) {
      this.address.StreetNumber = this.isNotAddressNumber ? 'S/N' : '';
   }

   searchAddress($event) {
      if (this.address.PostalCode?.length == 9) {
         this.loadingService.show();
         this.addressService.getAddressByPostalCode(this.address.PostalCode).subscribe(
            (res) => {
               if (res) {
                  this.address = res;
                  this.showAddressData = true;
               } else {
                  this.showAddressData = false;
                  this.notificationService.showAlertBox('InvalidPostalCode');
               }
               this.loadingService.hide();
            },
            (err) => {
               this.showAddressData = false;
               this.loadingService.hide();
               this.notificationService.showAlertBox('InvalidPostalCode');
            }
         );
      } else {
         this.showAddressData = false;
      }
   }

   selectAddress(address: AddUserAddress) {
      this.address = address;
      this.showAutoComplete = false;
      this.showAddressData = true;
   }

   invalidAddress(): boolean {
      if (!this.address.StreetName || this.address.StreetName.trim().length < 3) {
         return true;
      } else if (!this.address.StreetNumber || this.address.StreetNumber.trim().length < 1) {
         return true;
      } else if (!this.address.Neighborhood || this.address.Neighborhood.trim().length < 3) {
         return true;
      } else {
         return false;
      }
   }

   saveAddress() {
      this.loadingService.show();
      if (this.address.Latitude != 0 && this.address.Longitude != 0) {
         this.addAddress();
      } else {
         const addressNumber = this.isNotAddressNumber ? '0' : this.address.StreetNumber.trim();
         const addressInput = `${this.address.StreetName.trim()}, ${addressNumber} - ${this.address.Neighborhood.trim()}, ${this.address.City.trim()} - ${this.address.State.trim()}, ${this.address.PostalCode.trim()}, Brazil`;

         var geocoder = new google.maps.Geocoder();

         geocoder.geocode({ address: addressInput }, (place, status) => {
            if (status === google.maps.GeocoderStatus.OK) {
               this.address.Latitude = place[0].geometry.location.lat();
               this.address.Longitude = place[0].geometry.location.lng();
            }
            this.addAddress();
         });
      }
   }

   addAddress() {
      const newAddress = this.userService.addAddress(this.address);
      this.loadingService.hide();
      this.afterSaveAddress.emit(newAddress);
      this.close();
   }
}
