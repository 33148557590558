import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
   providedIn: 'root',
})
export class PixService {
   domainName = 'DeliveryServicePayment';

   constructor(private apiService: ApiService) {}

   generateCopyAndPastPix(companyId: number, pix: any): Observable<any> {
      return this.apiService.post(`${this.domainName}/GenerateCopyAndPastePix/${companyId}`, pix);
   }
}
