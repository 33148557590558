<div class="content-history">
    <div class="group-new-order" *ngFor="let history of orderHistory">
        <div class="date-new-order">
            {{history.FormattedDate}}
        </div>
        <div class="content-new-order">
            <ng-container *ngFor="let order of history.OrderList">
                <div class="order">
                    <div class="top">
                        <!-- <img [src]="order.CompanyLogoUrl" alt=""> -->
                        <div class="group-order">
                            <div class="group-name">
                                <div class="name">
                                    {{order.CompanyName}}
                                </div>
                                <div class="order-code">
                                    #{{order.OrderCode}}
                                </div>
                            </div>
                            <div class="status-code" *ngIf="!isReview">
                                <i class="fa-solid fa-circle-dot"></i>
                                {{ order.OrderCenterStatusCode | i18n}}
                            </div>
                            <div class="status-code review" *ngIf="isReview && !order.AlreadyReviewed"
                                (click)="openRateModal(order.Id)">
                                <i class="fa-light fa-star"></i>
                                {{'RateTheOrder' | i18n}}
                            </div>
                        </div>
                    </div>
                    <div class="footer">
                        <div class="total-order">{{'Total' | i18n: [order.TotalValue | money]}}</div>
                        <div class="group-buttons">
                            <!--button type="button" *ngIf="isRepeat"><i class="fa-light fa-repeat"></i></button-->
                            <button type="button" (click)="handleOpenDetail(order.Id)"><i
                                    class="fa-light fa-eye"></i></button>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>