import { Component, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { WhatsAppService } from '../../services/whatsapp-message.service';

@Component({
   selector: 'modal-whatsapp',
   templateUrl: './whatsapp.component.html',
   styleUrls: ['./whatsapp.component.scss'],
})
export class WhatsappComponent implements OnInit {
   constructor(private _bottomSheet: MatBottomSheet, private whatsAppService: WhatsAppService) {}

   ngOnInit(): void {}

   handleSetAction(isOpenOrderHistory = false) {
      if (isOpenOrderHistory) {
         this._bottomSheet.dismiss(isOpenOrderHistory);
      } else {
         this.sendMenssageWhatsApp();
      }
   }

   handleClose() {
      this._bottomSheet.dismiss();
   }

   sendMenssageWhatsApp() {
      const whatsAppLink = this.whatsAppService.getMessageLink();
      window.open(whatsAppLink, '_blank');
   }
}
