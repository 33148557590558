<ng-container *ngIf="showModal && address">
   <div class="modal modal-address multiple-dialog" @modal>
      <div class="address-content">
         <div class="header-modal">
            <i class="fa-solid fa-arrow-left" (click)="close()"></i>
            <div class="title-modal"> {{'AddressSearch' | i18n}} </div>
         </div>
         <div class="group-address">
            <div class="group-input-search" *ngIf="!showAutoComplete">
               <mat-form-field class="example-full-width">
                  <mat-label>{{'Cep' | i18n}}*</mat-label>
                  <input matInput type="text" [(ngModel)]="address.PostalCode" inputmode="numeric"
                     placeholder="00000-000" mask="00000-000" [dropSpecialCharacters]="false" autofocus
                     (input)="searchAddress($event)">
                  <mat-hint class="show-auto-complete" *ngIf="!showAddressData"
                     (click)="setShowAutoComplete()">{{'IDontHaveZipCode' |
                     i18n}}</mat-hint>
               </mat-form-field>
            </div>
            <div class="group-input-search" *ngIf="showAutoComplete">
               <kcms-address-autocomplete [parameterization]="parameterization"
                  (placeChanged)="selectAddress($event)"></kcms-address-autocomplete>
            </div>
            <div class="group-list-address" *ngIf="showAddressData">
               <i class="fa-light fa-location-dot"></i>
               <div class="address">
                  <strong>{{address.StreetName}}</strong>
                  <span>{{address.Neighborhood}}, {{address.City}}/{{address.State}} </span>
               </div>
            </div>
            <div class="group-form-address" *ngIf="showAddressData">
               <mat-form-field class="example-full-width">
                  <mat-label>{{'AddressLine' | i18n}}</mat-label>
                  <input matInput type="text" [(ngModel)]="address.StreetName" placeholder="" [required]="true"
                     [minlength]="3">
               </mat-form-field>
               <div class="group-address-number">
                  <mat-form-field class="example-full-width">
                     <mat-label>{{'Number' | i18n}}</mat-label>
                     <input matInput type="text" appOnlyNumber [(ngModel)]="address.StreetNumber" placeholder="123"
                        [disabled]="isNotAddressNumber" [minlength]="1" required>
                  </mat-form-field>
                  <mat-checkbox class="example-margin" [checked]="isNotAddressNumber" [(ngModel)]="isNotAddressNumber"
                     (change)="alterAddressNumber($event)">
                     {{'NotAddressNumber' | i18n}}
                  </mat-checkbox>
               </div>
               <mat-form-field class="example-full-width">
                  <mat-label>{{'Complement' | i18n}}</mat-label>
                  <input matInput type="text" [(ngModel)]="address.Complement" placeholder="Apto/Casa/Fundos">
               </mat-form-field>
               <mat-form-field class="example-full-width">
                  <mat-label>{{'Reference' | i18n}}</mat-label>
                  <input matInput type="text" [(ngModel)]="address.Reference" placeholder="">
               </mat-form-field>
               <mat-form-field class="example-full-width">
                  <mat-label>{{'Neighborhood' | i18n}}</mat-label>
                  <input matInput type="text" [(ngModel)]="address.Neighborhood" placeholder="" [required]="true"
                     [minlength]="3">
               </mat-form-field>
            </div>
         </div>

      </div>
      <div class="group-buttons-footer">
         <button type="button" (click)="saveAddress()" [disabled]="invalidAddress()">
            {{ 'SaveAddress' | i18n}}
         </button>
      </div>
   </div>
</ng-container>