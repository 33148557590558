<ng-container *ngIf="showModal && userSummary">
    <div class="modal multiple-dialog modal-customer" @modal>
        <div class="customer-content">
            <div class="header-modal">
                <i class="fa-solid fa-arrow-left" (click)="close()"></i>
                <div class="title-modal"></div>
            </div>
            <h4>
                {{'ConfirmYourPersonalData' | i18n}}
            </h4>
            <mat-form-field class="example-full-width">
                <mat-label>{{'Name' | i18n}}</mat-label>
                <input #userName matInput type="text" [(ngModel)]="userSummary.Name" placeholder="{{'Name' | i18n}}"
                    autofocus required>
            </mat-form-field>
            <mat-form-field class="example-full-width">
                <mat-label>{{'CellPhone' | i18n}}</mat-label>
                <input #cellPhone matInput type="text" [(ngModel)]="userSummary.CellPhone"
                    placeholder="(00) 0 0000-0000" mask="(00) 0 0000-0000" [dropSpecialCharacters]="false" required>
            </mat-form-field>
            <mat-form-field class="example-full-width">
                <mat-label>{{'DocumentNumber' | i18n}}</mat-label>
                <input #documentNumber matInput type="text" [(ngModel)]="userSummary.DocumentNumber"
                    placeholder="000.000.000-00" mask="000.000.000-00||00.000.000/0000-00"
                    [dropSpecialCharacters]="false">
            </mat-form-field>
            <div class="message-order">
                {{'BySubmittingTheOrderYouAgreeToShareTheData' | i18n }}
                <a href="https://www.kcms.com.br/termos/"
                    target="_blank">{{'BySubmittingTheOrderYouAgreeToShareTheData2' | i18n }}</a>
                {{'BySubmittingTheOrderYouAgreeToShareTheData3' | i18n }}
                <a href="https://www.kcms.com.br/privacidade/"
                    target="_blank">{{'BySubmittingTheOrderYouAgreeToShareTheData4' | i18n }}</a>
                {{'BySubmittingTheOrderYouAgreeToShareTheData5' | i18n }}
            </div>
        </div>
        <div class="group-buttons-footer">
            <button type="button" (click)="handleSaveCustomer()"
                [disabled]="!userSummary.Name || !userSummary.CellPhone || isSendingOrder">
                {{ (isSendingOrder ? 'Sending': 'SendOrder') | i18n}}
            </button>
        </div>
    </div>

</ng-container>
<modal-success></modal-success>