import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CURRENCY_MASK_CONFIG, CurrencyMaskConfig, CurrencyMaskModule } from 'ng2-currency-mask';
import { NgxMaskModule } from 'ngx-mask';
import { MaterialModule } from '../material/material.module';
import { SharedModule } from '../shared/shared.module';
import { AboutCompanyComponent } from './components/about-company/about-company.component';
import { ChangeComponent } from './components/change/change.component';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { CustomerComponent } from './components/customer/customer.component';
import { DiscountCouponComponent } from './components/discount-coupon/discount-coupon.component';
import { FooterComponent } from './components/footer/footer.component';
import { LangComponent } from './components/lang/lang.component';
import { LoadingComponent } from './components/loading/loading.component';
import { AddOrderAddressComponent } from './components/order-address/add/add-order-address.component';
import { AddressAutocompleteComponent } from './components/order-address/autocomplete/address-autocomplete.component';
import { OrderAddressComponent } from './components/order-address/order-address.component';
import { OrderCartComponent } from './components/order-cart/order-cart.component';
import { OrderHistoryComponent } from './components/order-history/order-history.component';
import { CardOrderHistoryComponent } from './components/order-history/shared/card-order.component';
import { DetailOrderHistoryComponent } from './components/order-history/shared/detail-order.component';
import { RateOrderComponent } from './components/order-history/shared/rate-order/rate-order.component';
import { PaymentFormComponent } from './components/payment-form/payment-form.component';
import { PixPaymentComponent } from './components/pix-payment/pix-payment.component';
import { PixComponent } from './components/pix/pix.component';
import { ProductDetailComponent } from './components/product-detail/product-detail.component';
import { RateComponent } from './components/rate/rate.component';
import { ShareSocialMediaComponent } from './components/share-social-media/share-social-media.component';
import { SuccessComponent } from './components/success/success.component';
import { WhatsappComponent } from './components/whatsapp/whatsapp.component';
import { AddressService } from './services/address.service';
import { DiscountCouponService } from './services/discount-coupon.service';
import { FacebookPixelService } from './services/facebook-pixel.service';
import { GoogleAnalyticsService } from './services/google-analytics.service';
import { LocalStorageService } from './services/local-storage.service';
import { NotificationService } from './services/notification.service';
import { OrderService } from './services/order.service';
import { PixPaymentService } from './services/pix-payment.service';
import { FunctionService } from './utils/function.service';

export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
   align: 'left',
   allowNegative: true,
   decimal: ',',
   precision: 2,
   prefix: '',
   suffix: '',
   thousands: '.',
};

const CORE_COMPONENTS = [
   FooterComponent,
   LoadingComponent,
   CustomerComponent,
   OrderAddressComponent,
   AddOrderAddressComponent,
   AddressAutocompleteComponent,
   ProductDetailComponent,
   OrderCartComponent,
   CheckoutComponent,
   ChangeComponent,
   PaymentFormComponent,
   OrderHistoryComponent,
   CardOrderHistoryComponent,
   DetailOrderHistoryComponent,
   LangComponent,
   RateComponent,
   RateOrderComponent,
   SuccessComponent,
   PixComponent,
   PixPaymentComponent,
   AboutCompanyComponent,
   WhatsappComponent,
   ShareSocialMediaComponent,
   DiscountCouponComponent,
];

const MODULES = [MaterialModule, RouterModule, SharedModule, NgxMaskModule, CurrencyMaskModule];

@NgModule({
   declarations: [CORE_COMPONENTS],
   imports: [CommonModule, FormsModule, MODULES],
   exports: [CORE_COMPONENTS],
   providers: [
      NotificationService,
      AddressService,
      LocalStorageService,
      FunctionService,
      FacebookPixelService,
      GoogleAnalyticsService,
      OrderService,
      DiscountCouponService,
      PixPaymentService,
      {
         provide: CURRENCY_MASK_CONFIG,
         useValue: CustomCurrencyMaskConfig,
      },
   ],
})
export class CoreModule {}
