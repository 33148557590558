import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CartAddress } from '../../models/cart.model';
import { DeliveryParameterization } from '../../models/delivery-service.model';
import { DeliveryServiceDeliveryFeeRequest } from '../../models/discount-coupon.model';
import { User, UserAddress } from '../../models/user.model';
import { CartService } from '../../services/cart.service';
import { DeliveryService } from '../../services/delivery-service.service';
import { LoadingService } from '../../services/loading.service';
import { NotificationService } from '../../services/notification.service';
import { UserService } from '../../services/user.service';
import { transitionAnimation } from '../../utils/animations';
import { AddOrderAddressComponent } from './add/add-order-address.component';

@Component({
   selector: 'modal-order-address',
   templateUrl: './order-address.component.html',
   styleUrls: ['./order-address.component.scss'],
   animations: [transitionAnimation],
})
export class OrderAddressComponent implements OnInit {
   public userInfo: User;
   public cartAddress: CartAddress;
   public showPickupOption: boolean;
   public isSaving: boolean;
   public showModal = false;
   public openCartModel = false;
   public showOverlay = false;

   @Input() parameterization: DeliveryParameterization;
   @Output() modalClosed = new EventEmitter<boolean>();

   @ViewChild(AddOrderAddressComponent) addOrderAddressComponent?: AddOrderAddressComponent;

   constructor(
      private userService: UserService,
      private cartService: CartService,
      private deliveryService: DeliveryService,
      private loadingService: LoadingService,
      private notificationService: NotificationService
   ) {}

   ngOnInit(): void {
      this.isSaving = false;
   }

   open(showPickupOption: boolean, openCartModel = false, showOverlay: boolean = false) {
      this.userInfo = this.userService.getUserInfo();
      this.cartAddress = this.cartService.getCartAddress();
      this.isSaving = false;
      this.openCartModel = openCartModel;
      this.showModal = true;
      this.showPickupOption = showPickupOption;
      this.showOverlay = showOverlay;

      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
   }

   close(openCartModal = true) {
      this.showModal = false;

      if (openCartModal) {
         this.modalClosed.emit(this.openCartModel);
         this.loadingService.hide();
      }
   }

   openAddOrderAddress() {
      this.addOrderAddressComponent?.open('');
   }

   setPickupOrderMode() {
      this.isSaving = true;
      this.cartService.alterDeliveryMethod(false);
      this.userService.removedFavoriteAddress();
      this.close();
   }

   removeAddress(addressId: string) {
      this.notificationService.showConfirmBox(null, 'RemoveAddressQuestion', 'warning').then((ret) => {
         if (ret) {
            this.userService.removeAddress(addressId);
         }
      });
   }

   setMainAddress(address: UserAddress) {
      this.loadingService.show();
      this.isSaving = true;
      this.userService.setFavoriteAddress(address.Id);
      this.deliveryAddressChanged(address);
   }

   deliveryAddressChanged(address: UserAddress) {
      if (!this.cartAddress?.Id || (address && address.Id != this.cartAddress?.Id)) {
         const deliveryAddress = this.getDeliveryAddress(address);

         const request = new DeliveryServiceDeliveryFeeRequest(this.parameterization.CompanyId, deliveryAddress, null, null);

         // const request = new DeliveryServiceDeliveryFeeRequest(
         //    this.parameterization.CompanyId,
         //    address.FullAddress,
         //    address.Latitude,
         //    address.Longitude
         // );

         this.deliveryService.getDeliveryFeeInfo(request).subscribe(
            (res) => {
               if (res && !res.IsOutsideCoverageArea) {
                  this.cartService.alterDeliveryAddress(address, res);

                  this.close();
               } else {
                  this.isSaving = false;
                  this.notificationService.showAlertBox('DeliveryAddressOutsideCoverageArea');
                  this.userService.removedFavoriteAddress();
                  this.loadingService.hide();
               }
            },
            (err) => {
               this.isSaving = false;
               this.loadingService.hide();
               this.notificationService.showAlertBox('DeliveryFeeError');
            }
         );
      } else {
         this.cartService.alterDeliveryMethod(true);
         this.close();
      }
   }

   getDeliveryAddress(address: { StreetName: string; StreetNumber: string; Neighborhood: string; City: string; State: string }): string {
      const shortAddress = `${address.StreetName}, ${address.StreetNumber}`;
      const shortCityAddress = `${address.Neighborhood}, ${address.City} - ${address.State}`;
      const formattedAddress = `${shortAddress}, ${shortCityAddress}`;

      return formattedAddress;
   }
}
