import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { Cart, CartItem } from '../../models/cart.model';
import { DeliveryParameterization } from '../../models/delivery-service.model';
import { TrackInitiateCheckout } from '../../models/tracking.model';
import { CartService } from '../../services/cart.service';
import { DeliveryService } from '../../services/delivery-service.service';
import { DiscountCouponService } from '../../services/discount-coupon.service';
import { LoadingService } from '../../services/loading.service';
import { NotificationService } from '../../services/notification.service';
import { TrackerService } from '../../services/tracker.service';
import { transitionAnimation } from '../../utils/animations';
import { FunctionService } from '../../utils/function.service';
import { CheckoutComponent } from '../checkout/checkout.component';
import { DiscountCouponComponent } from '../discount-coupon/discount-coupon.component';
import { OrderAddressComponent } from '../order-address/order-address.component';
import { PaymentFormComponent } from '../payment-form/payment-form.component';
import { ProductDetailComponent } from '../product-detail/product-detail.component';

@Component({
   selector: 'modal-order-cart',
   templateUrl: './order-cart.component.html',
   styleUrls: ['./order-cart.component.scss'],
   animations: [transitionAnimation],
})
export class OrderCartComponent implements OnInit, OnDestroy {
   private subscriptions: Subscription[] = [];
   public cart: Cart;
   public urlToBack: string;
   public showModal = false;
   public discountCouponCode: string;
   public isMobile = this.functionService.isMobile();

   @Input() parameterization: DeliveryParameterization;
   @Output() orderSent = new EventEmitter();

   @ViewChild(ProductDetailComponent) productDetailComponent?: ProductDetailComponent;
   @ViewChild(PaymentFormComponent) paymentFormComponent?: PaymentFormComponent;

   // todo: rever
   @ViewChild(OrderAddressComponent) orderAddressComponent?: OrderAddressComponent;
   @ViewChild(CheckoutComponent) checkoutComponent?: CheckoutComponent;

   @HostListener('window:popstate', ['$event'])
   onPopState(event: Event) {
      if (this.showModal) {
         this.close();
      }
   }

   constructor(
      private notificationService: NotificationService,
      private cartService: CartService,
      private discountCouponService: DiscountCouponService,
      private deliveryService: DeliveryService,
      private loadingService: LoadingService,
      private functionService: FunctionService,
      private _bottomSheet: MatBottomSheet,
      private trackerService: TrackerService
   ) {
      this.subscriptions.push(
         this.cartService.cartChanged$.subscribe((cart: Cart) => {
            this.cart = cart;
            this.discountCouponCode = this.cart?.DiscountCoupon?.Code;
         })
      );
   }

   ngOnInit(): void {}

   ngOnDestroy() {
      if (this.subscriptions) {
         this.subscriptions.forEach((s) => s.unsubscribe());
      }
   }

   open(url: string) {
      this.showModal = true;
      this.urlToBack = url;
      history.pushState(null, 'Pedemais Delivery | Carrinho', this.urlToBack.split('produtos').join('carrinho'));

      if (this.isMobile) this.deliveryService.overflowService(false);

      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);

      this.sendToFacebookPixelAndGoogleAnalytics();
   }

   close() {
      history.pushState(null, 'Pedemais Delivery', this.urlToBack);
      this.deliveryService.overflowService(true);

      this.showModal = false;
   }

   editItem(orderItem: CartItem, index: number) {
      this.productDetailComponent?.openEdition(orderItem, index);
   }

   removeItem(orderItem: CartItem) {
      this.notificationService.showConfirmBox(null, 'DeleteOrNo', 'warning', [orderItem.ProductName]).then((ret) => {
         if (ret) {
            this.cartService.removeProduct(orderItem);
         }
      });
   }

   // todo: rever
   openOrderAddressSheet() {
      this.orderAddressComponent?.open(true);
   }

   //===Payment========================================================================================================

   openPaymentFormModal() {
      this.paymentFormComponent?.open();
   }

   //===DiscountCoupon========================================================================================================
   removeDiscountCoupon() {
      if (this.cart.DiscountCoupon && this.cart.DiscountCoupon.Id) {
         this.loadingService.show();

         this.discountCouponService
            .increaseQuantity(this.parameterization.CompanyId, this.cart.DiscountCoupon.Id)
            .pipe(take(1))
            .subscribe(
               (ret) => {
                  this.cartService.removeDiscountCoupon();
                  this.loadingService.hide();
                  this.notificationService.showSuccessMessage('DiscountCouponRemoved');
               },
               (err) => {
                  this.cartService.removeDiscountCoupon();
                  this.loadingService.hide();
                  this.notificationService.showSuccessMessage('DiscountCouponRemoved');
               }
            );
      }
   }

   addDiscountCoupon() {
      this._bottomSheet.open(DiscountCouponComponent, {
         data: { cart: this.cart },
         autoFocus: true,
      });
   }

   //===========================================================================================================================
   validateOrder() {
      if (!this.parameterization.StoreIsOpen) {
         this.notificationService.showAlertBox('StoreIsOpen', 'Warning', [this.parameterization.CompanyName]);
         return;
      }

      if (!this.parameterization.CanReceiveOrders) {
         this.notificationService.showAlertBox('CanReceiveOrders', 'Warning', [this.parameterization.CompanyName]);
         return;
      }

      if (this.cart.Summary.SubtotalValue < this.cart.DiscountCoupon?.MinimumOrderValue) {
         const minValue = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
         }).format(this.cart.DiscountCoupon.MinimumOrderValue);
         this.notificationService.showAlertBox('DiscountCouponMinValue', null, [String(minValue)]);
         return;
      }

      // todo: rever
      if (this.cart.IsDelivery && !this.cart.Address) {
         this.openOrderAddressSheet();
      } else if (!this.cart.PaymentForm?.Name) {
         this.openPaymentFormModal();
      } else {
         this.showCheckoutModal();
      }
   }

   showCheckoutModal() {
      this.checkoutComponent?.open();
   }

   finishOrder() {
      this.orderSent.emit();
      this.close();
   }

   trackItem(index, item) {
      return item.id;
   }

   trackSubItem(index, subItem) {
      return subItem.id;
   }

   //---FacebookPixelAndGoogleAnalytics-----------------------
   sendToFacebookPixelAndGoogleAnalytics() {
      const param: TrackInitiateCheckout = {
         ContentIds: this.cart.Items.map((n) => n.ProductId),
         ProductNameList: this.cart.Items.map((n) => n.ProductName),
         TotalValue: this.cart.Summary.TotalValue,
         ProductsCount: this.cart.Summary.ItemsCount,
      };

      this.trackerService.trackInitiateCheckout(this.parameterization, param);
   }
}
