import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ViaCepResult } from '../models/address.model';
import { AddUserAddress } from '../models/user.model';
import { ApiService } from './api.service';

@Injectable({
   providedIn: 'root',
})
export class AddressService {
   apiUrl = environment.viaCepBaseUrl;
   domainName = 'ws';

   constructor(private apiService: ApiService) {}

   getAddressByPostalCode(postalCode: string): Observable<AddUserAddress> {
      const pc = postalCode.replace(/[^\d]/g, '');
      return this.apiService.get(`${this.domainName}/${pc}/json`, null, this.apiUrl).pipe(
         map((res: ViaCepResult) => {
            if (res?.erro) {
               return null;
            }
            const address = new AddUserAddress();
            address.PostalCode = res.cep;
            address.StreetName = res.logradouro;
            address.Neighborhood = res.bairro;
            address.City = res.localidade;
            address.State = res.uf;
            address.Country = 'BR';
            return address;
         })
      );
   }

   transform(place: google.maps.places.PlaceResult): AddUserAddress {
      if (!place || !place.address_components) {
         return null;
      }

      const address = new AddUserAddress();

      if (place.geometry && place.geometry.location) {
         address.Latitude = place.geometry.location.lat();
         address.Longitude = place.geometry.location.lng();
      }

      place.address_components.forEach((addressComponent) => {
         if (addressComponent.types && addressComponent.types.length > 0) {
            switch (addressComponent.types[0]) {
               case 'route':
                  address.StreetName = addressComponent.long_name;
                  break;
               case 'street_number':
                  address.StreetNumber = addressComponent.long_name;
                  break;
               case 'sublocality_level_1':
               case 'political':
                  address.Neighborhood = addressComponent.long_name;
                  break;
               case 'administrative_area_level_2':
                  address.City = addressComponent.long_name;
                  break;
               case 'administrative_area_level_1':
                  address.State = addressComponent.short_name;
                  break;
               case 'postal_code':
                  address.PostalCode = addressComponent.long_name;
                  break;
            }
         }
      });

      return address;
   }

   getBoundsLiteral(latitude: number, longitude: number, distance: number): google.maps.LatLngBoundsLiteral {
      // distance = 0.1 ~ 10k
      var bounds: google.maps.LatLngBoundsLiteral = {
         north: latitude + distance,
         south: latitude - distance,
         east: longitude + distance,
         west: longitude - distance,
      };
      return bounds;
   }
}
function observableThrowError(retAddress: any) {
   throw new Error('Function not implemented.');
}
