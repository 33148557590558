const toFloat = (value: number, decimalPlaces: number = 3) => parseFloat(value.toFixed(decimalPlaces));
const toInt = (value: string) => parseInt(value, 10);

const copyObject = (obj: any): any => {
   return obj ? JSON.parse(JSON.stringify(obj)) : null;
};

const toJson = (obj: any): any => {
   if (obj) {
      try {
         return JSON.parse(obj);
      } catch (error) {
         return null;
      }
   } else {
      return null;
   }
};

const isDecimal = (quantity: number) => parseFloat(quantity.toString()) !== toInt(quantity.toString());

const convertToFraction = (value: number): string => {
   if (value % 1 !== 0) {
      const divider = Math.round(1 / value);
      return `1/${divider}`;
   } else {
      return value.toString();
   }
};

const addDays = (date: Date, days: number): Date => {
   date.setDate(date.getDate() + days);
   return date;
};

const addHours = (date: Date, hours: number): Date => {
   date.setHours(date.getDate() + hours);
   return date;
};

const addMinutes = (date: Date, minutes: number): Date => {
   date.setMinutes(date.getMinutes() + minutes);
   return date;
};

const decrementFractionatedProductQuantity = (quantity: number): number => {
   if (quantity <= 1) {
      let divider = Math.round(1 / quantity);
      divider += divider === 8 ? 0 : 1;
      quantity = toFloat(1 / divider);
   } else {
      quantity--;
   }
   return quantity;
};

const incrementFractionatedProductQuantity = (quantity: number): number => {
   if (quantity < 1) {
      let divider = Math.round(1 / quantity);
      divider -= 1;
      quantity = toFloat(1 / divider);
   } else {
      quantity++;
   }
   return quantity;
};

const formatProductName = (name: string, barCode: string) => `${name} (${barCode})`;

const patterOnlyNumbers = () => {
   return new RegExp(/[0-9,]/);
};

const getRandomInt = (max: number = 99999999) => {
   return Math.floor(Math.random() * max);
};

const normalizeAccentedChars = (s: string) => {
   return s ? s.normalize('NFD').replace(/[\u0300-\u036f]/g, '') : s;
};

const getShortAddress = (address: { StreetName: string; StreetNumber: string; Complement: string }): string => {
   const complement = address.Complement && address.Complement.length > 0 ? `, ${address.Complement}` : '';
   return `${address.StreetName}, ${address.StreetNumber}${complement.trim()}`;
};

const getShortCityAddress = (address: { Neighborhood: string; City: string; State: string }): string => {
   return `${address.Neighborhood}, ${address.City} - ${address.State}`;
};

const getFullAddress = (address: {
   StreetName: string;
   StreetNumber: string;
   Complement: string;
   Reference?: string;
   Neighborhood: string;
   City: string;
   State: string;
}): string => {
   const shortAddress = getShortAddress(address);
   const shortCityAddress = getShortCityAddress(address);
   const reference = address.Reference && address.Reference.length > 0 ? `, ${address.Reference}` : '';
   const formattedAddress = `${shortAddress}${reference.trim()}, ${shortCityAddress}`;
   return formattedAddress;
};

const s4 = (): string => {
   return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
};

const generateId = (): string => {
   return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
};

export {
   addDays,
   addHours,
   addMinutes,
   convertToFraction,
   copyObject,
   decrementFractionatedProductQuantity,
   formatProductName,
   generateId,
   getFullAddress,
   getRandomInt,
   getShortAddress,
   getShortCityAddress,
   incrementFractionatedProductQuantity,
   isDecimal,
   normalizeAccentedChars,
   patterOnlyNumbers,
   toFloat,
   toInt,
   toJson,
};
