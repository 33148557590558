import { CurrencyPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Cart, CartItem, CartSubItem } from '../models/cart.model';
import { DeliveryParameterization } from '../models/delivery-service.model';
import { UserSummary } from '../models/user.model';
import { FunctionService } from '../utils/function.service';
import { isDecimal } from '../utils/functions';

@Injectable({
   providedIn: 'root',
})
export class WhatsAppService {
   private currencyPipe = new CurrencyPipe('pt');
   private whatsAppLink: string = null;

   constructor(private functionService: FunctionService) {}

   getMessageLink() {
      return this.whatsAppLink;
   }

   buildWhatsAppLink(cart: Cart, userSummary: UserSummary, parameterization: DeliveryParameterization) {
      const subTotal = this.currencyPipe.transform(cart.Summary.SubtotalValue, 'BRL') || 0;
      const total = this.currencyPipe.transform(cart.Summary.TotalValue, 'BRL') || 0;
      const deliveryFee =
         cart.Summary.DeliveryFee > 0
            ? this.currencyPipe.transform(cart.Summary.DeliveryFee, 'BRL')
            : cart.Summary.DeliveryFee === 0 && cart.DeliveryFee?.CustomerMessage
            ? cart.DeliveryFee?.CustomerMessage
            : 0;
      const discountCouponValue =
         cart.Summary.DiscountCouponValue > 0 ? this.currencyPipe.transform(cart.Summary.DiscountCouponValue, 'BRL') : 0;
      const change = this.currencyPipe.transform(cart.PaymentForm.ChangeValueFor, 'BRL') || 0;
      const paymentName = cart.PaymentForm.OriginalName;
      const datetime = moment().format('DD/MM/YYYY HH:mm');
      const items = this.getProductsToMessageWhatsApp(cart.Items);

      let message = `
      *GOSTOU DE PEDIR NO NOSSO APP? 😊*
      %0aAdicione na tela inicial do seu celular e peça com mais rapidez e agilidade!
      %0ahttps://pede.kcms.app/${parameterization.FriendlyUrl}
      %0a
      %0a*KCMS Pedemais - Novo Pedido*
      %0a${datetime}
      %0a${items}
      %0a
      %0aEntrega ou Retirada: *${cart.IsDelivery ? 'Entrega' : 'Retirada'}*     
      ${cart.IsDelivery && cart.DeliveryFee.TimeToDeliver > 0 ? `%0a*Tempo estimado em:* ${cart.DeliveryFee.TimeToDeliver} Min` : ''}    
      ${
         !cart.IsDelivery && parameterization.EstimatedTimeToPrepare > 0
            ? `%0a*Tempo estimado em:* ${parameterization.EstimatedTimeToPrepare} Min`
            : ''
      }    
      ${cart.IsDelivery ? `%0a*Endereço:* ${cart.Address.FullAddress}` : ''}       
      ${cart.IsDelivery && cart.Address.Reference ? `%0a*Referência:* ${cart.Address.Reference}` : ''}
      %0a
      ${subTotal !== 0 ? `%0a*Subtotal:* ${subTotal}` : ''} 
      %0a*Taxa de entrega:* ${deliveryFee !== 0 ? `${deliveryFee}` : 'Grátis'} 
      ${discountCouponValue !== 0 ? `%0a*Desconto:* ${discountCouponValue}` : ''} 
      %0a*Total:* ${total}
      %0a
      %0a*Pagamento:* ${paymentName}  ${cart.PaymentForm.IsPrepaid ? '(Pago Online)' : ''}    
      ${cart.PaymentForm.ChangeValueFor > 0 ? `%0a*Troco para:* ${change}` : ''}
      %0a
      %0a*Nome:* ${userSummary.Name}       
      %0a*Telefone:* ${userSummary.CellPhone}
      `;

      const completePhoneNumber = `55${parameterization.PhoneNumber}`;
      const mobileNumber = completePhoneNumber.replace(/\W/g, '');
      const webOrMobile = this.functionService.isMobile() ? 'api' : 'web';
      const whatsAppLink = `https://${webOrMobile}.whatsapp.com/send/?phone=${mobileNumber}&text=${message}`;

      this.whatsAppLink = whatsAppLink;
      return whatsAppLink;
   }

   getProductsToMessageWhatsApp(cartItems: CartItem[]) {
      let object = [];

      cartItems.forEach((currentValue: CartItem) => {
         let subItems = [];
         let subItemsWhatsApp = [];
         const stepsCanBeFractioned = currentValue.SubItems.filter((n) => isDecimal(n.Quantity)).length;

         subItems = currentValue.SubItems.reduce((subItemObject, currentSubItem: CartSubItem) => {
            if (currentSubItem.CanBeFractioned) {
               const message = `%0a  • ${currentValue.Quantity} x ${this.getFraction(stepsCanBeFractioned)} - ${
                  currentSubItem.ProductName
               }`;
               subItemObject.push(message);
            } else {
               const subItemQuantity = currentSubItem.Quantity * currentValue.Quantity;
               const subItemTotal = currentSubItem.SellValue * subItemQuantity;
               const sign = currentValue.HasComplements ? '  +' : '  •';
               const subItemValue = currentValue.HasComplements ? ` - ${this.currencyPipe.transform(subItemTotal, 'BRL')}` : '';
               const message = `%0a${sign} ${subItemQuantity} x ${currentSubItem.ProductName}${subItemValue}`;
               subItemObject.push(message);
            }

            return subItemObject;
         }, []);

         subItemsWhatsApp = subItems;

         object.push(
            `%0a*${currentValue.Quantity} x ${currentValue.ProductName}* - ${this.currencyPipe.transform(
               currentValue.SellValue > 0 ? currentValue.Quantity * currentValue.SellValue : currentValue.TotalValue,
               'BRL'
            )}`,
            ...subItemsWhatsApp,
            `${currentValue.Observation ? `%0a_obs: ${currentValue.Observation}_` : ''}`
         );
      }, []);

      return object;
   }

   getFraction(qtd: number) {
      return `1/${qtd}`;
   }
}
