import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheet } from '@angular/material/bottom-sheet';
import * as moment from 'moment';
import { take } from 'rxjs/operators';
import { Cart, CartIntegrationType, CartOnlinePayment, CartOnlinePaymentStatusType, CartStatusType } from '../../models/cart.model';
import { DeliveryParameterization } from '../../models/delivery-service.model';
import { CreateDeliveryPaymentRequest, CreateDeliveryPaymentResponse, PaymentTransactionStatusType } from '../../models/pix-payment.model';
import { User } from '../../models/user.model';
import { CartService } from '../../services/cart.service';
import { EventService } from '../../services/event.service';
import { LoadingService } from '../../services/loading.service';
import { NotificationService } from '../../services/notification.service';
import { PixPaymentService } from '../../services/pix-payment.service';
import { FunctionService } from '../../utils/function.service';
import { getRandomInt, normalizeAccentedChars } from '../../utils/functions';

@Component({
   selector: 'kcms-pix-payment',
   templateUrl: './pix-payment.component.html',
   styleUrls: ['./pix-payment.component.scss'],
})
export class PixPaymentComponent implements OnInit, OnDestroy {
   private interval: any;
   public parameterization: DeliveryParameterization;
   public codeCopied: boolean;
   public response: CreateDeliveryPaymentResponse;
   public status: PaymentTransactionStatusType;
   public cart: Cart;
   public user: User;

   constructor(
      private _bottomSheet: MatBottomSheet,
      @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
      private functionService: FunctionService,
      private notificationService: NotificationService,
      private loadingService: LoadingService,
      private pixPaymentService: PixPaymentService,
      private eventService: EventService,
      private cartService: CartService
   ) {
      this.parameterization = data.parameterization;
      this.cart = data.cart;
      this.user = data.user;
   }

   ngOnInit(): void {
      this.generateCopyAndPastPix();
   }

   ngOnDestroy(): void {
      this.tryClearInterval();
   }

   private tryClearInterval() {
      try {
         clearInterval(this.interval);
      } catch {}
   }

   private generateCopyAndPastPix() {
      if (!this.cart.OnlinePayment) {
         this.loadingService.show();
         const request = this.mapToCreateDeliveryPayment();
         this.pixPaymentService
            .generateCopyAndPastPix(request)
            .pipe(take(1))
            .subscribe(
               (ret) => {
                  this.response = ret;
                  this.status = PaymentTransactionStatusType.Created;
                  const op = this.mapToOnlinePayment(request, this.response);
                  this.cartService.setOnlinePayment(op);
                  this.initializePixPaymentStatusInterVal();
                  this.eventService.publishPixOrderSent();
                  this.loadingService.hide();
               },
               (err) => {
                  this.notificationService.showAlertMessage('PixOnlineGenerateCodeError');
                  this.loadingService.hide();
               }
            );
      } else {
         this.response = new CreateDeliveryPaymentResponse();
         this.response.Id = this.cart.OnlinePayment.IntegrationId;
         this.response.PixCopyAndPaste = this.cart.OnlinePayment.CopyAndPaste;
         this.response.PixQrCode = this.cart.OnlinePayment.QrCode;
         this.initializePixPaymentStatusInterVal();
      }
   }

   close() {
      this._bottomSheet.dismiss();
   }

   getReferenceCode(): string {
      const format = 'DDMMYY';
      const guid = getRandomInt();
      const referenceCode = `pedemais-${this.parameterization.CompanyId}-${moment().local().format(format)}-${guid}`;
      return referenceCode;
   }

   private initializePixPaymentStatusInterVal() {
      this.tryClearInterval();
      const seconds = 15;
      this.interval = setInterval(() => {
         this.getPixPaymentStatus(this.response.Id);
      }, seconds * 1000);
   }

   private getPixPaymentStatus(paymentId: string) {
      this.loadingService.show();
      this.pixPaymentService
         .getStatus(paymentId)
         .pipe(take(1))
         .subscribe(
            (ret) => {
               this.status = ret.Status;
               this.checkPaymentStatus(ret.Code);
               this.loadingService.hide();
            },
            (err) => {
               // this.notificationService.showAlertMessage('');
               this.loadingService.hide();
            }
         );
   }

   private checkPaymentStatus(referenceId: string) {
      if (this.status.toString() == 'Approved') {
         this.cartService.alterOnlinePaymentStatus(CartOnlinePaymentStatusType.Approved, CartStatusType.Paid, referenceId);
         this.close();
      } else if (this.status.toString() == 'Canceled') {
         this.notificationService.showSuccessMessage('Canceled');
         this.cartService.alterOnlinePaymentStatus(CartOnlinePaymentStatusType.Canceled, CartStatusType.Paid, referenceId);
         this.cartService.clearCart(this.cart.CompanyId);
         this.close();
      } else if (this.status.toString() == 'Rejected') {
         this.notificationService.showSuccessMessage('Rejected');
         this.cartService.alterOnlinePaymentStatus(CartOnlinePaymentStatusType.Rejected, CartStatusType.Paid, referenceId);
         this.cartService.clearCart(this.cart.CompanyId);
         this.close();
      } else if (this.status.toString() == 'Expired') {
         this.notificationService.showSuccessMessage('Expired');
         this.cartService.alterOnlinePaymentStatus(CartOnlinePaymentStatusType.Expired, CartStatusType.Paid, referenceId);
         this.cartService.clearCart(this.cart.CompanyId);
         this.close();
      } else {
         console.log(this.status);
      }
   }

   copyCode() {
      this.codeCopied = true;
      this.functionService.copyTextToClipboard(this.cart.OnlinePayment?.CopyAndPaste);
   }

   private mapToCreateDeliveryPayment(): CreateDeliveryPaymentRequest {
      const expiresIn = 5 * 60;
      const request = new CreateDeliveryPaymentRequest(this.parameterization.CompanyId, expiresIn);

      request.RequestId = this.functionService.guid();

      request.ReferenceCode = this.getReferenceCode();
      request.CustomerName = this.user.Name;
      request.CustomerPhoneNumber = this.user.CellPhone;
      request.CustomerDocumentNumber = this.user.DocumentNumber;
      request.Value = this.cart.Summary.TotalValue;

      request.Title = request.ReferenceCode;
      request.Description = `${normalizeAccentedChars(this.user.Name.substring(0, 15))} - R$ ${this.cart.Summary.TotalValue}`;
      return request;
   }

   private mapToOnlinePayment(request: CreateDeliveryPaymentRequest, response: CreateDeliveryPaymentResponse): CartOnlinePayment {
      const op = new CartOnlinePayment();
      op.CompanyId = this.parameterization.CompanyId;
      op.IntegrationType = CartIntegrationType.KcmsPix;
      op.IntegrationId = response.Id;
      op.OrderId = response.OrderId;
      op.IntegrationCode = request.ReferenceCode;
      op.Status = CartOnlinePaymentStatusType.Created;
      op.CopyAndPaste = response.PixCopyAndPaste;
      op.QrCode = response.PixQrCode;
      op.ExpiresAt = '';
      op.TotalValue = request.Value;
      op.MediatorCode = 'flagship';
      return op;
   }
}
