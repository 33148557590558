<ng-container *ngIf="showModal">
    <div class="modal modal-order-history" @modal>
        <div class="resume-content">
            <div class="header-modal">
                <i class="fa-solid fa-arrow-left" (click)="close()"></i>
                <div class="title-modal"> {{'PaymentForm' | i18n}} </div>
            </div>
            <div class="no-payment-form"
                *ngIf="paymentOnline && paymentOnline.length === 0 && paymentDelivery && paymentDelivery.length === 0">
                {{'PaymentMethodsNotConfigured' | i18n}}
            </div>

            <div class="title-group-payment" *ngIf="paymentOnline && paymentOnline.length > 0">{{'PayOnline' | i18n}}
            </div>
            <div class="group-payment" *ngIf="paymentOnline && paymentOnline.length > 0">
                <div *ngFor="let payment of paymentOnline">
                    <div class="payment" [ngClass]="{'actived' : payment.Name === currentPaymentForm?.Name}"
                        (click)="selectPaymentForm(payment)">
                        <img src="/assets/images/payment-form/{{payment.Icon}}.png" alt="{{payment.Name | i18n}}">
                        <div class="name-payment">
                            {{payment.Name | i18n}}
                        </div>
                    </div>
                </div>
            </div>

            <div class="title-group-payment" *ngIf="paymentDelivery && paymentDelivery.length > 0">{{'PayOnDelivery' |
                i18n}}
            </div>
            <div class="group-payment" *ngIf="paymentDelivery && paymentDelivery.length > 0">
                <div *ngFor="let payment of paymentDelivery">
                    <div class="payment" [ngClass]="{'actived' : payment.Name === currentPaymentForm?.Name}"
                        (click)="selectPaymentForm(payment)">
                        <img src="/assets/images/payment-form/{{payment.Icon}}.png" alt="{{payment.Name | i18n}}">
                        <div class="name-payment">
                            {{payment.Name | i18n}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>