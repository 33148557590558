import { Component, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheet } from '@angular/material/bottom-sheet';
import { take } from 'rxjs/operators';
import { Cart } from '../../models/cart.model';
import { DiscountCoupon } from '../../models/discount-coupon.model';
import { CartService } from '../../services/cart.service';
import { DiscountCouponService } from '../../services/discount-coupon.service';
import { LoadingService } from '../../services/loading.service';
import { NotificationService } from '../../services/notification.service';

@Component({
   selector: 'bottom-sheet-discount-coupon',
   templateUrl: './discount-coupon.component.html',
   styleUrls: ['./discount-coupon.component.scss'],
})
export class DiscountCouponComponent implements OnInit {
   public discountCouponCode: string;
   public cart: Cart;

   constructor(
      private _bottomSheet: MatBottomSheet,
      @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
      private loadingService: LoadingService,
      private discountCouponService: DiscountCouponService,
      private cartService: CartService,
      private notificationService: NotificationService
   ) {
      this.cart = data.cart;
   }

   ngOnInit(): void {}

   close() {
      this._bottomSheet.dismiss();
   }

   //===DiscountCoupon========================================================================================================
   addDiscountCoupon() {
      if (this.discountCouponCode && this.discountCouponCode.trim().length > 0) {
         this.loadingService.show();
         this.discountCouponService
            .getDiscountCouponByCode(this.cart.CompanyId, this.discountCouponCode)
            .pipe(take(1))
            .subscribe(
               (ret) => {
                  if (ret && ret.IsAvailable) {
                     if (this.cart.Summary.TotalValue < ret.MinimumOrderValue) {
                        const minValue = new Intl.NumberFormat('pt-BR', {
                           style: 'currency',
                           currency: 'BRL',
                        }).format(ret.MinimumOrderValue);
                        this.notificationService.showAlertBox('DiscountCouponMinValue', null, [String(minValue)]);

                        this.loadingService.hide();
                        return;
                     }

                     if (ret.CouponCategory === 2 && this.cart.Summary.DeliveryFee === 0) {
                        this.notificationService.showAlertBox('CouponCannotBeUsedAsThereIsNoDeliveryFee');
                        this.discountCouponCode = '';
                        this.loadingService.hide();
                        return;
                     }

                     this.decreaseDiscountCouponQuantity(ret);
                  } else {
                     this.loadingService.hide();
                     this.notificationService.showAlertBox('DiscountCouponUnavailable');
                  }
               },
               (err) => {
                  this.loadingService.hide();
                  this.notificationService.showAlertBox('DiscountCouponNotFound');
               }
            );
      }
   }

   decreaseDiscountCouponQuantity(discountCoupon: DiscountCoupon) {
      this.loadingService.show();
      this.discountCouponService
         .decreaseQuantity(this.cart.CompanyId, discountCoupon.Id)
         .pipe(take(1))
         .subscribe(
            (ret) => {
               this.cartService.addDiscountCoupon(discountCoupon);
               this.loadingService.hide();
               this.notificationService.showSuccessMessage('DiscountCouponAppliedMesage', 6, '', [discountCoupon.Code]);
               this.close();
            },
            (err) => {
               this.loadingService.hide();
               this.notificationService.showAlertBox('DiscountCouponNotFound');
            }
         );
   }
}
