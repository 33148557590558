import { Injectable } from '@angular/core';
import { Cart } from '../models/cart.model';
import { DeliveryParameterization } from '../models/delivery-service.model';
import { User } from '../models/user.model';
import { toJson } from '../utils/functions';

@Injectable({
   providedIn: 'root',
})
export class LocalStorageService {
   private _orderStorageName = 'pede-mais-delivery-order';
   private _parameterizationStorageName = 'pede-mais-delivery-parameterization';
   private _cartStorageName = 'pede-mais-delivery-cart';
   private _userStorageName = 'pede-mais-delivery-user';
   private _orderHistoryStorageName = 'pede-mais-delivery-order-history';
   private _orderHistoryIdsStorageName = 'pede-mais-delivery-order-history-ids';
   private _orderLinkWhatsapp = 'pede-mais-delivery-order-link-whatsapp';

   constructor() {}

   private saveItem(key: string, value: any, onSessionStorage: boolean = false) {
      const str = JSON.stringify(value);
      onSessionStorage ? window.sessionStorage.setItem(key, str) : window.localStorage.setItem(key, str);
   }

   private getItem(key: string, fromSessionStorage: boolean = false): any {
      const str = fromSessionStorage ? window.sessionStorage.getItem(key) : window.localStorage.getItem(key);
      return toJson(str);
   }

   private removedItem(key: string): any {
      window.localStorage.removeItem(key);
   }

   setUserInfo(user: User) {
      if (user) {
         user.LastAccessOn = new Date();
         this.saveItem(this._userStorageName, user);
      }
   }

   getUserInfo(): User {
      const user = this.getItem(this._userStorageName);
      return user;
   }

   getCartInfo(): Cart {
      const cart = this.getItem(this._cartStorageName);
      return cart;
   }

   setCartInfo(cart: Cart) {
      try {
         this.saveItem(this._cartStorageName, cart);
      } catch (error) {}
   }

   getParameterizationInfo(): DeliveryParameterization {
      const parameterization = this.getItem(this._parameterizationStorageName, true);
      return parameterization;
   }

   setParameterizationInfo(parameterization: DeliveryParameterization) {
      try {
         this.saveItem(this._parameterizationStorageName, parameterization, true);
      } catch (error) {}
   }

   // //--------------------
   // setOrderHistory(orderId: string) {
   //    const pedeMaisStorage = this.getDeliveryOrderStorage() || new PedeMaisStorage();

   //    if (!pedeMaisStorage.OrderHistoryIdList) pedeMaisStorage.OrderHistoryIdList = [];

   //    pedeMaisStorage.OrderHistoryIdList.push(orderId);
   //    this.saveItem(this._orderHistoryStorageName, pedeMaisStorage);
   // }

   // getOrderHistoryIds(): string[] {
   //    const pedeMaisStorage = this.getDeliveryOrderStorage() || new PedeMaisStorage();

   //    return pedeMaisStorage.OrderHistoryIdList || [];
   // }

   // setDeliveryOrderStorage(deliveryApp: PedeMaisStorage) {
   //    if (deliveryApp) {
   //       this.saveItem(this._orderHistoryStorageName, deliveryApp);
   //    } else {
   //       throw 'object is null or undefined';
   //    }
   // }

   // getDeliveryOrderStorage(): PedeMaisStorage {
   //    const appString = this.getItem(this._orderHistoryStorageName) || new PedeMaisStorage();
   //    return appString;
   // }

   getOrderHistoryIdsNew(): string[] {
      const ids = this.getItem(this._orderHistoryIdsStorageName) || [];
      return ids;
   }

   getQuantityOrderHistoryIdsNew(): string[] {
      const ids = this.getItem(this._orderHistoryIdsStorageName) || [];
      return ids.length;
   }

   setOrderHistoryIdsNew(ids: string[]) {
      this.saveItem(this._orderHistoryIdsStorageName, ids);
   }

   removeOrderHistory() {
      this.removedItem(this._orderHistoryIdsStorageName);
   }

   setOrderLinkWhatsApp(link: string) {
      this.saveItem(this._orderLinkWhatsapp, link);
   }

   getOrderLinkWhatsAp(): string {
      const link = this.getItem(this._orderLinkWhatsapp);
      return link;
   }

   removeOrderLinkWhatsAp() {
      this.removedItem(this._orderLinkWhatsapp);
   }
}
