import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import * as moment from 'moment';
import { take } from 'rxjs/operators';
import { DeliveryServiceOrderHistoryResponse, OrderHistory, OrderHistoryGroupedByDate } from '../../models/order-history.model';
import { User } from '../../models/user.model';
import { DeliveryService } from '../../services/delivery-service.service';
import { OrderHistoryService } from '../../services/order-history.service';
import { transitionAnimation } from '../../utils/animations';
import { FunctionService } from '../../utils/function.service';
import { DeliveryParameterization } from './../../models/delivery-service.model';

@Component({
   selector: 'modal-order-history',
   templateUrl: './order-history.component.html',
   styleUrls: ['./order-history.component.scss'],
   animations: [transitionAnimation],
})
export class OrderHistoryComponent implements OnInit, OnDestroy {
   private interval: any;
   private sectionDateFormat = 'dddd D MMMM YYYY';
   public showModal = false;
   public urlToBack: string;
   public doingItems: OrderHistoryGroupedByDate[] = [];
   public finishedItems: OrderHistoryGroupedByDate[] = [];
   public isMobile = this.functionService.isMobile();

   @Input() parameterization: DeliveryParameterization;
   @Input() userInfo: User;

   @HostListener('window:popstate', ['$event'])
   onPopState(event: Event) {
      if (this.showModal) {
         this.close();
      }
   }

   orderHistory: OrderHistory[] = [];

   constructor(
      private deliveryService: DeliveryService,
      private functionService: FunctionService,
      private orderHistoryService: OrderHistoryService
   ) {}

   ngOnInit(): void {}

   ngOnDestroy(): void {
      this.tryClearInterval();
   }

   private tryClearInterval() {
      try {
         clearInterval(this.interval);
      } catch {}
   }

   open(url: string) {
      this.showModal = true;

      this.urlToBack = url;
      history.pushState(null, 'Pedemais Delivery | Pedidos', this.urlToBack.split('produtos').join('pedidos'));

      if (this.isMobile) this.deliveryService.overflowService(false);

      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
      this.configSearch();
   }

   close() {
      history.pushState(null, 'Pedemais Delivery', this.urlToBack);
      this.deliveryService.overflowService(true);
      this.tryClearInterval();
      this.showModal = false;
   }

   private configSearch() {
      const ids = this.orderHistoryService.getOrderHistoryIds();

      if (ids && ids.length > 0) {
         this.getOrderHistoryList(ids);

         this.interval = setInterval(() => {
            this.getOrderHistoryList(ids);
         }, 30000);
      }
   }

   getOrderHistoryList(ids: string[]) {
      if (this.showModal) {
         if (ids && ids.length > 0) {
            this.deliveryService
               .getDeliveryOrderHistory(this.parameterization.CompanyId, ids)
               .pipe(take(1))
               .subscribe(
                  (orderList) => {
                     this.groupOrdersByDate(orderList);
                  },
                  (err) => {}
               );
         }
      }
   }

   private groupOrdersByDate(orderList: DeliveryServiceOrderHistoryResponse[]) {
      this.doingItems = [];
      this.finishedItems = [];

      orderList.reduce((acc: OrderHistoryGroupedByDate[], obj) => {
         const defaultLocale = moment.locale(this.userInfo.Language.Key);
         const date = moment(obj.CreatedOn).format('MM-DD-YYYY');
         const key = date;

         if (!acc.find((n) => n.Date === key)) {
            acc.push({
               Date: key,
               FormattedDate: moment(key, 'MM-DD-YYYY').locale('pt').format(this.sectionDateFormat),
               OrderList: [],
            });
         }

         const index = acc.findIndex((n) => n.Date === key);
         acc[index].OrderList.push(obj);

         if (obj.OrderCenterStepCode >= 3) {
            if (!this.finishedItems.find((n) => n.Date === key)) {
               this.finishedItems.push({
                  Date: key,
                  FormattedDate: moment(key, 'MM-DD-YYYY').format(this.sectionDateFormat),
                  OrderList: [],
               });
            }

            const finishedItemsIndex = this.finishedItems.findIndex((n) => n.Date === key);
            this.finishedItems[finishedItemsIndex].OrderList.push(obj);
         } else {
            if (!this.doingItems.find((n) => n.Date === key)) {
               this.doingItems.push({
                  Date: key,
                  FormattedDate: moment(key, 'MM-DD-YYYY').format(this.sectionDateFormat),
                  OrderList: [],
               });
            }

            const doingItemsIndex = this.doingItems.findIndex((n) => n.Date === key);
            this.doingItems[doingItemsIndex].OrderList.push(obj);
         }

         moment.locale(defaultLocale);

         return acc;
      }, []);
   }
}
