import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DiscountCoupon, DiscountCouponServiceResponse } from '../models/discount-coupon.model';
import { ApiService } from './api.service';

@Injectable({
   providedIn: 'root',
})
export class DiscountCouponService {
   private domainName = 'DiscountCoupon';

   constructor(private apiService: ApiService) {}

   private todayIsAvailable(coupon: DiscountCouponServiceResponse): boolean {
      switch (new Date().getDay()) {
         case 0:
            return coupon.AllowedOnSunday;
         case 1:
            return coupon.AllowedOnMonday;
         case 2:
            return coupon.AllowedOnTuesday;
         case 3:
            return coupon.AllowedOnWednesday;
         case 4:
            return coupon.AllowedOnThursday;
         case 5:
            return coupon.AllowedOnFriday;
         case 6:
            return coupon.AllowedOnSaturday;
      }

      return false;
   }

   private defineCouponMapForGet(coupon: DiscountCouponServiceResponse): DiscountCoupon {
      let result: DiscountCoupon = null;

      if (!coupon) return result;

      const today = new Date();
      today.setHours(0, 0, 0, 0);
      let todayIsAvailable = this.todayIsAvailable(coupon);

      const startDate = new Date(coupon.StartDate);
      const endDate = new Date(coupon.EndDate);
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(0, 0, 0, 0);

      const isAvailable = coupon.IsActive && coupon.Quantity > 0 && startDate <= today && endDate >= today && todayIsAvailable;

      result = new DiscountCoupon(
         coupon.Id,
         coupon.CompanyId,
         coupon.Code,
         coupon.MinimumOrderValue,
         isAvailable,
         coupon.CouponType,
         coupon.CouponValue,
         coupon.CouponCategory
      );

      return result;
   }

   getDiscountCouponByCode(companyId: number, code: string): Observable<DiscountCoupon> {
      return this.apiService
         .post(`${this.domainName}/Code`, { CompanyId: companyId, Code: code })
         .pipe(map((res) => this.defineCouponMapForGet(res)));
   }

   decreaseQuantity(companyId: number, id: string) {
      return this.apiService.patch(`${this.domainName}/${companyId}/DecreaseQuantity/${id}`, {});
   }

   increaseQuantity(companyId: number, id: string) {
      return this.apiService.patch(`${this.domainName}/${companyId}/IncreaseQuantity/${id}`, {});
   }
}
