import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
   providedIn: 'root',
})
export class ApiService {
   private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
   constructor(private http: HttpClient) {}

   private getApiBaseUrl(apiUrl?: string): string {
      if (!apiUrl) {
         apiUrl = environment.apiBaseUrl + 'api/';
      }

      return apiUrl;
   }

   get(domain: string, param?: any, apiUrl?: string): Observable<any> {
      const apiBaseUrl = this.getApiBaseUrl(apiUrl);
      let url = apiBaseUrl + domain;
      if (param) {
         url += '/' + param;
      }

      return this.http.get(url, { headers: this.headers });
   }

   post(domain: string, data: any, apiUrl?: string): Observable<any> {
      const apiBaseUrl = this.getApiBaseUrl(apiUrl);
      let url = apiBaseUrl + domain;

      return this.http.post(url, JSON.stringify(data), { headers: this.headers });
   }

   put(domain: string, data: any, apiUrl?: string): Observable<any> {
      const apiBaseUrl = this.getApiBaseUrl(apiUrl);
      let url = apiBaseUrl + domain;

      return this.http.put(url, JSON.stringify(data), { headers: this.headers, observe: 'response' });
   }

   delete(domain: string, guid: string, apiUrl?: string): Observable<any> {
      const apiBaseUrl = this.getApiBaseUrl(apiUrl);
      let url = apiBaseUrl + domain + '/' + guid;

      return this.http.delete(url, { headers: this.headers });
   }

   patch(domain: string, data: any, apiUrl?: string): Observable<any> {
      const apiBaseUrl = this.getApiBaseUrl(apiUrl);
      let url = apiBaseUrl + domain;

      return this.http.patch(url, data);
   }
}
