import { Directive, HostListener } from '@angular/core';

@Directive({
   selector: '[appOnlyAlphanumeric]',
})
export class OnlyAlphanumericDirective {
   constructor() {}

   @HostListener('input', ['$event'])
   onInput(event: any) {
      const inputValue = event.target.value;
      event.target.value = inputValue.replace(/[0-9]/g, '');
   }
}
