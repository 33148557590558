import { Component, Input, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { CartPaymentForm } from '../../models/cart.model';
import { DeliveryServicePaymentForm } from '../../models/delivery-service.model';
import { CartService } from '../../services/cart.service';
import { DeliveryService } from '../../services/delivery-service.service';
import { transitionAnimation } from '../../utils/animations';
import { FunctionService } from '../../utils/function.service';
import { ChangeComponent } from '../change/change.component';

@Component({
   selector: 'modal-payment-form',
   templateUrl: './payment-form.component.html',
   styleUrls: ['./payment-form.component.scss'],
   animations: [transitionAnimation],
})
export class PaymentFormComponent implements OnInit {
   public showModal = false;
   public isMobile = this.functionService.isMobile();
   public paymentOnline = [];
   public paymentDelivery = [];

   @Input() cartTotalValue: number;
   @Input() paymentForms: DeliveryServicePaymentForm[];
   @Input() currentPaymentForm: CartPaymentForm;

   constructor(
      private deliveryService: DeliveryService,
      private cartService: CartService,
      private _bottomSheet: MatBottomSheet,
      private functionService: FunctionService
   ) {}

   ngOnInit(): void {}

   open() {
      this.showModal = true;
      if (this.isMobile) this.deliveryService.overflowService(false);
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
      this.setGroupPaymentFormOnline();
   }

   close() {
      this.showModal = false;
      this.deliveryService.overflowService(true);
   }

   setGroupPaymentFormOnline() {
      this.paymentOnline = this.paymentForms.filter((n) => n.IsPrepaid);
      this.paymentDelivery = this.paymentForms.filter((n) => !n.IsPrepaid);
   }

   selectPaymentForm(paymentForm: DeliveryServicePaymentForm) {
      if (paymentForm.AllowChangeValue) {
         this._bottomSheet
            .open(ChangeComponent, {
               data: { changeValue: this.currentPaymentForm?.ChangeValueFor, cartTotalValue: this.cartTotalValue },
               autoFocus: true,
            })
            .afterDismissed()
            .subscribe((paidValue: number) => {
               if (paidValue) {
                  this.sendPaymentForm(paymentForm, paidValue);
               } else {
                  this.sendPaymentForm(paymentForm, paidValue === 0 ? 0 : this.currentPaymentForm?.ChangeValueFor);
               }
            });
      } else {
         this.sendPaymentForm(paymentForm, 0);
      }
   }

   private sendPaymentForm(paymentForm: DeliveryServicePaymentForm, paidValue: number) {
      const payment: CartPaymentForm = {
         Id: null,
         Name: paymentForm.Name,
         OriginalName: paymentForm.OriginalName,
         Icon: paymentForm.Icon,
         ChangeValueFor: paidValue || 0,
         IsPrepaid: paymentForm.IsPrepaid,
      };
      this.cartService.setPaymentForm(payment);
      this.close();
   }
}
