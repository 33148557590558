<div class="share-content">
   <div class="group-icons">
      <i class="fa-brands fa-whatsapp whatsapp" (click)="shareOnWhatsapp()"></i>
      <i class="fa-brands fa-facebook-f facebook" (click)="shareOnFacebok()"></i>
   </div>

   <div class="copy-link">
      <div (click)="copyLink()">
         <i class="fa-light fa-copy"></i>{{'CopyLink' | i18n}}
      </div>
   </div>
</div>