import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
   selector: 'app-footer',
   templateUrl: 'footer.component.html',
   styleUrls: ['footer.component.scss'],
})
export class FooterComponent implements OnInit {
   @Input() friendlyUrl: string;

   constructor(private router: Router) {}

   ngOnInit() {}
}
