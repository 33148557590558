export class PixPayment {}

export class CreateDeliveryPaymentRequest {
   CompanyId: number;
   RequestId: string;
   ReferenceCode: string;
   CustomerName: string;
   CustomerDocumentNumber: string;
   CustomerPhoneNumber: string;
   Value: number;
   GenerateQrCodeImage: boolean;
   ExpiresIn: number;
   Title: string;
   Description: string;

   constructor(companyId: number, expiresIn: number) {
      this.CompanyId = companyId;
      this.GenerateQrCodeImage = true;
      this.ExpiresIn = expiresIn;
   }
}

export class DeliveryPaymentBaseResponse {
   Result: any;
}

export class CreateDeliveryPaymentResponse {
   Id: string;
   OrderId: string;
   PixCopyAndPaste: string;
   PixQrCode: string;
}

export class GetPaymentStatusResponse {
   Id: string;
   Code: string;
   Status: PaymentTransactionStatusType;
}

export enum PaymentTransactionStatusType {
   Creating = 0,
   Created = 1,
   Approved = 2,
   Rejected = 3,
   Canceling = 4,
   Canceled = 5,
   Partial = 6,
   Expired = 7,
   Unknown = 9,
}
