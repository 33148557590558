<ng-container *ngIf="showModal">
    <div class="modal modal-success" @modal>
        <div class="success-content">

            <div class="icon">
                <i class="fa-light fa-circle-check fa-bounce"></i>
            </div>

            <h3>
                {{'YourOrderHasBeenSent' | i18n}}
            </h3>

            <p>
                {{'InAMomentYouWillBeRedirectedToFollowTheStatusOfYourOrder' | i18n}}
            </p>

        </div>

        <div class="footer-version">
            {{'MadeBy' | i18n}} KCMS 💙
            <span>v {{appVersion}}</span>
        </div>
    </div>
    <div (click)="close()" @overlay class="overlay"></div>
</ng-container>