import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
   Cart,
   CartAddress,
   CartDiscountCoupon,
   CartItem,
   CartOnlinePayment,
   CartOnlinePaymentStatusType,
   CartPaymentForm,
   CartStatusType,
   CartSummary,
   DeliveryFee,
} from '../models/cart.model';
import { DeliveryParameterization } from '../models/delivery-service.model';
import { DiscountCoupon, DiscountCouponCategoryType, DiscountCouponType } from '../models/discount-coupon.model';
import { addMinutes, copyObject, toFloat } from '../utils/functions';
import { LocalStorageService } from './local-storage.service';
import { UserService } from './user.service';

@Injectable({
   providedIn: 'root',
})
export class CartService {
   private inactivityMaxTime = 30;
   private cart: Cart;
   private cartChange = new BehaviorSubject<Cart>(null);
   public cartChanged$ = this.cartChange.asObservable();
   private cartSummaryChange = new BehaviorSubject<CartSummary>(new CartSummary());
   public cartSummaryChanged$ = this.cartSummaryChange.asObservable();

   constructor(private localStorageService: LocalStorageService, private userService: UserService) {
      this.cart = this.getFromLocalStorage() || new Cart(0, environment.version);
      if (addMinutes(new Date(this.cart.LastModifiedOn), this.inactivityMaxTime) < new Date()) {
         this.clearCart(0);
      } else {
         this.publishCartChange();
      }
   }

   private getFromLocalStorage(): Cart {
      const cart = this.localStorageService.getCartInfo();
      return cart;
   }

   private publishCartChange() {
      this.cartChange.next(this.cart);
      this.cartSummaryChange.next(this.cart.Summary);
   }

   private saveCartOnLocalStorage(publishChanges: boolean = true) {
      this.cart.LastModifiedOn = new Date().toString();
      this.localStorageService.setCartInfo(this.cart);

      if (publishChanges) {
         this.publishCartChange();
      }
   }

   getCartInfo(): Cart {
      return this.cart;
   }

   getCartSummaryInfo(): CartSummary {
      return this.cart.Summary;
   }

   getCartStatus(): CartStatusType {
      return this.cart.Status;
   }

   getCartAddress(): CartAddress {
      return this.cart.Address;
   }

   clearCart(companyId: number, orderMinValueForFreeDeliveryFee: number = 0, estimatedTimeToPrepare: number = 0) {
      this.cart = new Cart(companyId, environment.version);
      this.cart.OrderMinValueForFreeDeliveryFee = orderMinValueForFreeDeliveryFee;
      this.cart.EstimatedTimeToPrepare = estimatedTimeToPrepare;
      this.saveCartOnLocalStorage();
   }

   validateCartCompany(parameterizarion: DeliveryParameterization, forceClearCart: boolean = false) {
      if (
         this.cart.CompanyId !== parameterizarion.CompanyId ||
         forceClearCart ||
         (this.cart.Version !== environment.version && this.cart.Status !== CartStatusType.InPayment)
      ) {
         this.clearCart(
            parameterizarion.CompanyId,
            parameterizarion.OrderMinValueForFreeDeliveryFee,
            parameterizarion.EstimatedTimeToPrepare
         );
      } else {
         this.cart.OrderMinValueForFreeDeliveryFee = parameterizarion.OrderMinValueForFreeDeliveryFee;
         this.cart.EstimatedTimeToPrepare = parameterizarion.EstimatedTimeToPrepare;
      }

      if (this.cart.IsDelivery && !parameterizarion.IsDeliveryEnabled) {
         this.alterDeliveryMethod(false);
      }

      if (this.cart.IsPickUp && !parameterizarion.IsPickupEnabled) {
         this.alterDeliveryMethod(true);
      }

      if (!this.cart.IsPickUp && parameterizarion.IsPickupEnabled && !this.cart.IsPickUp && !parameterizarion.IsDeliveryEnabled) {
         this.alterDeliveryMethod(false);
      }

      this.calculateCartTotalValue();
   }

   addProduct(item: CartItem) {
      this.cart.Items.push(item);
      this.calculateCartTotalValue();
   }

   updateProduct(item: CartItem, itemIndexToEdit: number) {
      this.cart.Items[itemIndexToEdit] = copyObject(item);
      this.calculateCartTotalValue();
   }

   removeProduct(orderItem: CartItem) {
      this.cart.Items = this.cart.Items.filter((item) => item !== orderItem);
      this.calculateCartTotalValue();
   }

   setPaymentForm(paymentForm: CartPaymentForm) {
      this.cart.PaymentForm = paymentForm;
      this.saveCartOnLocalStorage();
   }

   addDiscountCoupon(discountCoupon: DiscountCoupon) {
      this.cart.DiscountCoupon = this.mapToDiscountCoupon(discountCoupon);
      this.cart.HasDiscountCoupon = true;
      this.calculateCartTotalValue();
   }

   removeDiscountCoupon() {
      this.cart.DiscountCoupon = null;
      this.cart.HasDiscountCoupon = false;
      this.calculateCartTotalValue();
   }

   alterDeliveryMethod(isDelivery: boolean) {
      this.cart.IsDelivery = isDelivery;
      this.cart.IsPickUp = !isDelivery;

      if (!isDelivery) {
         this.removeAddressCart();
      }

      this.calculateCartTotalValue();
   }

   alterDeliveryAddress(address: CartAddress, deliveryFee: DeliveryFee) {
      this.cart.Address = address;
      this.cart.DeliveryFee = deliveryFee;
      this.alterDeliveryMethod(true);
   }

   removeAddressCart() {
      this.cart.Address = null;
      this.saveCartOnLocalStorage();
   }

   setOnlinePayment(onlinePayment: CartOnlinePayment) {
      this.cart.Status = CartStatusType.InPayment;
      this.cart.OnlinePayment = onlinePayment;
      this.saveCartOnLocalStorage();
   }

   alterOnlinePaymentStatus(onlinePaymentStatus: CartOnlinePaymentStatusType, cartStatus: CartStatusType, referenceId: string) {
      this.cart.Status = cartStatus;
      this.cart.OnlinePayment.ReferenceId = referenceId;
      this.cart.OnlinePayment.Status = onlinePaymentStatus;
      this.saveCartOnLocalStorage();
   }

   orderSent() {
      this.clearCart(this.cart.CompanyId, this.cart.OrderMinValueForFreeDeliveryFee, this.cart.EstimatedTimeToPrepare);
      this.userService.removedFavoriteAddress();
   }

   private calculateCartTotalValue() {
      const totalItemsValue = toFloat(
         this.cart.Items.reduce((total, currentItem) => {
            return (total += toFloat(currentItem.TotalValue));
         }, 0)
      );

      const summary = new CartSummary();
      summary.ItemsCount = this.cart.Items.reduce((acc, item) => acc + item.QuantityToShow, 0);
      summary.HasItems = summary.ItemsCount > 0;
      summary.SubtotalValue = totalItemsValue;
      summary.DeliveryFee = this.getDeliveryFeeValue(summary.SubtotalValue);
      summary.DiscountCouponValue = this.getDiscountValue(summary.SubtotalValue, summary.DeliveryFee);
      summary.TotalValue = toFloat(summary.SubtotalValue + summary.DeliveryFee - summary.DiscountCouponValue);

      this.cart.Summary = summary;

      this.saveCartOnLocalStorage();
   }

   private getDeliveryFeeValue(subtotalValue: number) {
      let deliveryFee = 0;
      const coupon = this.cart.DiscountCoupon;

      if (this.cart.IsDelivery && coupon?.CouponCategory !== DiscountCouponCategoryType.OnFreeDelivery) {
         deliveryFee = this.cart.DeliveryFee?.DeliveryFee ?? 0;

         if (
            deliveryFee > 0 &&
            this.cart.OrderMinValueForFreeDeliveryFee > 0 &&
            subtotalValue >= this.cart.OrderMinValueForFreeDeliveryFee
         ) {
            deliveryFee = 0;
         }
      }

      return deliveryFee;
   }

   private mapToDiscountCoupon(request: DiscountCoupon): CartDiscountCoupon {
      const coupon = new CartDiscountCoupon(
         request.Id,
         request.Code,
         request.MinimumOrderValue,
         request.IsAvailable,
         request.CouponType,
         request.CouponValue,
         request.CouponCategory
      );
      return coupon;
   }

   private getDiscountValue(subtotalValue: number, deliveryFee: number): number {
      let discountValue = 0;
      const coupon = this.cart.DiscountCoupon;
      if (!coupon || coupon.MinimumOrderValue > subtotalValue) {
         return discountValue;
      }

      const subtotal =
         coupon.CouponCategory == DiscountCouponCategoryType.OnSale
            ? coupon.CouponType == DiscountCouponType.Percentage
               ? subtotalValue
               : coupon.CouponValue
            : deliveryFee;

      if (coupon.CouponType == DiscountCouponType.Percentage) {
         discountValue = toFloat((subtotal * coupon.CouponValue) / 100);
      } else {
         discountValue = subtotal;
      }

      return discountValue;
   }
}
