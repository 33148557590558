import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { validateBr } from 'js-brasil';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { Cart } from '../../models/cart.model';
import { DeliveryParameterization } from '../../models/delivery-service.model';
import { UserSummary } from '../../models/user.model';
import { CartService } from '../../services/cart.service';
import { DeliveryService } from '../../services/delivery-service.service';
import { DocumentService } from '../../services/document.service';
import { EventService } from '../../services/event.service';
import { LoadingService } from '../../services/loading.service';
import { NotificationService } from '../../services/notification.service';
import { OrderHistoryService } from '../../services/order-history.service';
import { OrderService } from '../../services/order.service';
import { TrackerService } from '../../services/tracker.service';
import { UserService } from '../../services/user.service';
import { WhatsAppService } from '../../services/whatsapp-message.service';
import { transitionAnimation } from '../../utils/animations';
import { FunctionService } from '../../utils/function.service';
import { PixPaymentComponent } from '../pix-payment/pix-payment.component';
import { PixComponent } from '../pix/pix.component';
import { SuccessComponent } from '../success/success.component';

@Component({
   selector: 'modal-checkout',
   templateUrl: './checkout.component.html',
   styleUrls: ['./checkout.component.scss'],
   animations: [transitionAnimation],
})
export class CheckoutComponent implements OnInit, OnDestroy {
   private subscriptions: Subscription[] = [];
   public showModal = false;
   public userSummary = new UserSummary();
   public isMobile = this.functionService.isMobile();
   public isSendingOrder = false;
   public cart: Cart;

   @Input() parameterization: DeliveryParameterization;

   @Output() orderSent = new EventEmitter();

   @ViewChild('cellPhone') cellPhone: ElementRef;
   @ViewChild('userName') userName: ElementRef;
   @ViewChild('documentNumber') documentNumber: ElementRef;
   @ViewChild(SuccessComponent) successComponent?: SuccessComponent;

   constructor(
      private notificationService: NotificationService,
      private orderService: OrderService,
      private functionService: FunctionService,
      private orderHistoryService: OrderHistoryService,
      private loadingService: LoadingService,
      private cartService: CartService,
      private userService: UserService,
      private _bottomSheet: MatBottomSheet,
      public documentService: DocumentService,
      public whatsAppService: WhatsAppService,
      private deliveryService: DeliveryService,
      private eventService: EventService,
      private trackerService: TrackerService
   ) {
      this.subscriptions.push(
         this.eventService.pixOrderSent$.subscribe(() => {
            this.sendOrderSilent();
         })
      );
   }

   ngOnInit(): void {}

   ngOnDestroy() {
      if (this.subscriptions) {
         this.subscriptions.forEach((s) => s.unsubscribe());
      }
   }

   open() {
      if (this.isMobile) this.documentService.overflowService(false);
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);

      this.userSummary = this.userService.getUserSummaryInfo();
      this.cart = this.cartService.getCartInfo();

      this.showModal = true;
   }

   close() {
      this.showModal = false;
      this.documentService.overflowService(true);
   }

   handleSaveCustomer() {
      this.isSendingOrder = true;

      if (this.userSummary.DocumentNumber && !validateBr.cpfcnpj(this.userSummary.DocumentNumber)) {
         this.notificationService.showInfoMessage('DocumentNumberNotValidte', [this.userSummary.DocumentNumber], 10);
         this.documentNumber.nativeElement.focus();
         this.isSendingOrder = false;
         return;
      }

      if (!validateBr.celular(this.userSummary.CellPhone)) {
         this.notificationService.showInfoMessage('CellPhoneNotValidte', [this.userSummary.CellPhone], 10);
         this.cellPhone.nativeElement.focus();
         this.isSendingOrder = false;
         return;
      }

      const regex = /\b[A-Za-zÀ-ú][A-Za-zÀ-ú]+,?\s[A-Za-zÀ-ú][A-Za-zÀ-ú]{2,19}\b/gi;
      if (!regex.test(this.userSummary.Name)) {
         this.notificationService.showInfoMessage('UserNameValidte', [this.userSummary.Name], 10);
         this.userName.nativeElement.focus();
         this.isSendingOrder = false;
         return;
      }

      this.userService.update(this.userSummary, false);

      if (this.cart.PaymentForm.Name.toLocaleLowerCase() === 'pix') {
         if (this.cart.PaymentForm.IsPrepaid) {
            this.showOnlinePixPaymentModal();
         } else {
            this.sendOrder(true);
            // this.showOffLinePixModal();
         }
      } else {
         this.sendOrder();
      }
   }

   sendOrder(showPixModal: boolean = false) {
      this.sendToFacebookPixelAndGoogleAnalytics();
      const order = this.orderService.convertToCreateOrder(this.cart, this.userSummary, this.parameterization);

      this.deliveryService
         .saveOrder(order)
         .pipe(take(1))
         .subscribe(
            (ret) => {
               this.orderHistoryService.setOrderHistoryId(ret.body);

               if (this.parameterization.OrderCenterConfigSendOrderToMessageServiceApp) {
                  this.whatsAppService.buildWhatsAppLink(this.cart, this.userSummary, this.parameterization);
                  this.whatsAppMessage();
               }

               this.cartService.orderSent();

               // if (order.PaymentFormName.toLocaleLowerCase() === 'pix') {
               //    this.showOffLinePixModal();
               // } else {
               //    this.finishOrder();
               // }

               if (showPixModal) {
                  this.showOffLinePixModal();
               } else {
                  this.finishOrder();
               }
            },
            (error) => {
               this.loadingService.hide();
               this.notificationService.showAlertMessage('OrderNotSentTryAgain');
               this.isSendingOrder = false;
            }
         );
   }

   sendOrderSilent() {
      const order = this.orderService.convertToCreateOrder(this.cart, this.userSummary, this.parameterization);

      this.deliveryService
         .saveOrder(order)
         .pipe(take(1))
         .subscribe(
            (ret) => {
               this.orderHistoryService.setOrderHistoryId(ret.body);
            },
            (error) => {
               this.loadingService.hide();
               this.notificationService.showAlertMessage('OrderNotSentTryAgain');
               this.isSendingOrder = false;
            }
         );
   }

   showOnlinePixPaymentModal() {
      const sheet = this._bottomSheet.open(PixPaymentComponent, {
         data: { cart: this.cart, parameterization: this.parameterization, user: this.userSummary },
         closeOnNavigation: false,
         disableClose: true,
         hasBackdrop: true,
      });

      sheet.afterDismissed().subscribe((finish) => {
         // if (finish.cart && finish.validatePix) {
         this.sendOrder();
         // }
      });
   }

   showOffLinePixModal() {
      const sheet = this._bottomSheet.open(PixComponent, {
         data: { parameterization: this.parameterization, cartTotalValue: this.cart.Summary.TotalValue },
         closeOnNavigation: false,
         disableClose: true,
         hasBackdrop: true,
      });

      sheet.afterDismissed().subscribe((res: { success: boolean }) => {
         if (res?.success) {
            this.finishOrder();
         } else {
            this.isSendingOrder = false;
         }
      });
   }

   finishOrder() {
      if (this.parameterization.OrderCenterConfigSendOrderToMessageServiceApp) {
         // this.whatsAppMessage();
      } else {
         this.successComponent?.open(this.parameterization.FriendlyUrl);
      }

      this.orderSent.emit();
      this.isSendingOrder = false;
      this.close();
   }

   whatsAppMessage() {
      // todo: rever
      const whatsAppLink = this.whatsAppService.getMessageLink();
      window.open(whatsAppLink, '_blank');
   }

   //---FacebookPixelAndGoogleAnalytics-----------------------
   sendToFacebookPixelAndGoogleAnalytics() {
      this.trackerService.trackPurchase(this.parameterization, this.cart.Summary.TotalValue);
   }
}
