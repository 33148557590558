import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { validateBr } from 'js-brasil';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { User } from '../../models/user.model';
import { NotificationService } from '../../services/notification.service';
import { transitionAnimation } from '../../utils/animations';

@Component({
   selector: 'modal-customer',
   templateUrl: './customer.component.html',
   styleUrls: ['./customer.component.scss'],
   animations: [transitionAnimation],
})
export class CustomerComponent implements OnInit {
   public showModal = false;
   public user: User;

   @ViewChild('cellPhone') cellPhone: ElementRef;
   @ViewChild('documentNumber') documentNumber: ElementRef;
   @ViewChild('email') email: ElementRef;
   @ViewChild('passwordConfirmation') passwordConfirmation: ElementRef;

   constructor(private localStorageService: LocalStorageService, private notificationService: NotificationService) {}

   ngOnInit(): void {}

   open() {
      this.showModal = true;
      this.user = this.localStorageService.getUserInfo();
   }

   close() {
      this.showModal = false;
   }

   handleSaveCustomer() {
      if (this.user.PasswordConfirmation !== this.user.Password) {
         this.notificationService.showInfoMessage('EnteredPasswordsDoNotMatch');
         this.user.PasswordConfirmation = null;
         this.passwordConfirmation.nativeElement.focus();
         return;
      }

      if (!validateBr.cpf(this.user.DocumentNumber)) {
         this.notificationService.showInfoMessage('DocumentNumberNotValidte', [this.user.DocumentNumber]);
         this.documentNumber.nativeElement.focus();
         return;
      }

      if (!validateBr.email(this.user.Email)) {
         this.notificationService.showInfoMessage('EmailNotValidte', [this.user.Email]);
         this.email.nativeElement.focus();
         return;
      }

      if (!validateBr.celular(this.user.CellPhone)) {
         this.notificationService.showInfoMessage('CellPhoneNotValidte', [this.user.CellPhone]);
         this.cellPhone.nativeElement.focus();
         return;
      }

      this.localStorageService.setUserInfo(this.user);
      this.notificationService.showSuccessMessage('ChangeMadeSuccessfully');
      this.close();
   }
}
