import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OnlyAlphanumericDirective } from './directives/only-alphanumeric.directive';
import { OnlyNumberDirective } from './directives/only-number.directive';
import { I18nPipe } from './pipes/i18n.pipe';
import { MoneyPipe } from './pipes/money.pipe';

@NgModule({
   declarations: [I18nPipe, MoneyPipe, OnlyNumberDirective, OnlyAlphanumericDirective],
   imports: [CommonModule],
   exports: [I18nPipe, MoneyPipe, OnlyNumberDirective, OnlyAlphanumericDirective],
   providers: [MoneyPipe, OnlyNumberDirective, OnlyAlphanumericDirective],
})
export class SharedModule {}
