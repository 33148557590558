<ng-container *ngIf="showModal && cart && parameterization">
    <div class="modal modal-order-history" @modal>

        <div class="resume-content" *ngIf="!parameterization.IsDeliveryEnabled && !parameterization.IsPickupEnabled">
            <div class="header-modal">
                <i class="fa-solid fa-arrow-left" (click)="close()"></i>
                <div class="title-modal"> </div>
            </div>
            <div class="no-settings">
                {{'DeliveryPickupNotSettings' | i18n}}
            </div>
        </div>

        <div class="resume-content" *ngIf="parameterization.StoreIsOpen && !parameterization.CanReceiveOrders">
            <div class="header-modal">
                <i class="fa-solid fa-arrow-left" (click)="close()"></i>
                <div class="title-modal"> </div>
            </div>
            <div class="no-settings">
                {{'EstablishmentIsNotCurrentlyAcceptingOrders' | i18n}}
            </div>
        </div>

        <div class="resume-content" *ngIf="!parameterization.StoreIsOpen">
            <div class="header-modal">
                <i class="fa-solid fa-arrow-left" (click)="close()"></i>
                <div class="title-modal"> </div>
            </div>
            <div class="no-settings">
                {{'EstablishmentIsClosed' | i18n}}
            </div>
        </div>

        <div class="resume-content"
            *ngIf="(parameterization.IsDeliveryEnabled || parameterization.IsPickupEnabled) && parameterization.CanReceiveOrders && parameterization.StoreIsOpen">
            <div class="header-modal">
                <i class="fa-solid fa-arrow-left" (click)="close()"></i>
                <div class="title-modal"> {{'OrderSummary' | i18n}} </div>
            </div>

            <!--Delivery-->
            <div class="group-address" *ngIf="cart.IsDelivery">
                <div class="address">
                    <div class="address-selected">
                        <i class="fa-light fa-moped"></i>
                        <div class="address-delivery">
                            <strong>{{'HomeDelivery' | i18n}}</strong>
                            {{cart.Address?.ShortAddress}}
                            <p> {{cart.Address?.ShortCityAddress}} </p>
                            <div class="time" *ngIf="!cart.DeliveryFee?.CustomerMessage">
                                {{'InSomeMinutes' | i18n: [cart.DeliveryFee?.TimeToDeliver]}}
                            </div>
                        </div>
                    </div>
                    <p (click)="openOrderAddressSheet()">{{'ToReplace' | i18n}}</p>
                </div>
            </div>
            <!--PickUp-->
            <div class="group-address" *ngIf="cart.IsPickUp">
                <div class="address">
                    <div class="address-selected">
                        <i class="fa-light fa-store"></i>
                        <div class="address-delivery">
                            <strong>{{'PickUpAtTheStore' | i18n}}</strong>
                            {{parameterization.Address?.ShortAddress}}
                            <p> {{parameterization.Address?.ShortCityAddress}} </p>
                            <div class="time">
                                {{parameterization.EstimatedTimeToPrepare &&
                                parameterization.EstimatedTimeToPrepare > 0 ? ('InSomeMinutes' | i18n:
                                [parameterization.EstimatedTimeToPrepare]) : ''}}
                            </div>
                        </div>
                    </div>
                    <p *ngIf="parameterization.IsDeliveryEnabled" (click)="openOrderAddressSheet()">
                        {{'ToReplace' | i18n}}
                    </p>
                </div>
            </div>
            <!--items------------------------------------------------------------------------------------->
            <div class="group-items-resume list-items" *ngIf="cart.Items && cart.Items.length > 0">
                <div class="item-order" *ngFor="let item of cart.Items; index as i; trackBy:trackItem">
                    <div class="item" (click)="editItem(item, i)">
                        <div class="group-name-sell-value">
                            <div class="name-item">
                                <div class="quantity">{{item.Quantity}} x</div>
                                <div class="name">{{item.ProductName}}</div>
                            </div>
                            <div class="sell-value">
                                {{( item.IsCombo ? item.TotalValue :item.SellValue * item.Quantity ) | money}}
                            </div>
                        </div>
                        <div class="complement-item" *ngFor="let subItem of item.SubItems;; trackBy:trackSubItem">
                            {{ item.IsCombo ? '&bull;' : '+' }}
                            {{subItem.FractionedQuantity}} x
                            {{subItem.ProductName}}
                            <div class="sell-value" *ngIf="!item.IsCombo && subItem.SellValue > 0">
                                {{subItem.TotalValue * item.Quantity | money}}
                            </div>
                        </div>
                        <div class="observation-item" *ngIf="item.Observation">
                            **{{item.Observation}}
                        </div>
                    </div>
                    <i class="fa-light fa-trash-can icon-delete" (click)="removeItem(item)"></i>
                </div>

                <div class="total-order subtotal">
                    {{'Subtotal' | i18n: [cart.Summary.SubtotalValue | money]}}
                </div>
                <div class="total-order subtotal"
                    *ngIf="cart.IsDelivery && !cart.DeliveryFee?.CustomerMessage || cart.DiscountCoupon?.CouponCategory === 3">
                    {{'DeliveryFeeOrder' | i18n : [(cart.Summary?.DeliveryFee === 0 ? ('Free' | i18n) :
                    cart.DeliveryFee?.DeliveryFee | money )]}}
                </div>

                <div class="total-order subtotal" *ngIf="cart.IsPickUp">
                    {{'FreeWithdrawal' | i18n }}
                </div>

                <div class="total-order warning-messaage"
                    *ngIf="cart.IsDelivery && cart.DeliveryFee?.CustomerMessage && cart.DiscountCoupon?.CouponCategory !== 3">
                    {{cart.DeliveryFee?.CustomerMessage}}
                </div>
                <div class="total-order subtotal"
                    *ngIf="cart.DiscountCoupon?.Code && cart.DiscountCoupon?.CouponValue > 0">
                    {{'DiscountCouponApplied' | i18n: [cart.Summary.DiscountCouponValue | money]}}
                </div>

                <div class="total-order">
                    {{'Total' | i18n: [cart.Summary.TotalValue | money]}}
                </div>

                <div class="warning" *ngIf="cart.Summary.SubtotalValue < parameterization.OrderMinValue">
                    {{'MinimumOrderValueIs' | i18n: [parameterization.OrderMinValue | money]}}
                    {{'NotIncludingDeliveryFee' | i18n}}
                </div>
                <button type="button" class="btn-defaut" style="margin-top: 20px;" (click)="close()">
                    {{'AddMoreItems' | i18n}}</button>
            </div>
            <div class="group-items-resume no-items" *ngIf="!cart.Summary.HasItems">
                <i class="fa-duotone fa-cart-xmark"></i>
                {{'ThereAreNoProductsHere' | i18n}}
                <button type="button" class="btn-defaut" style="margin-top: 20px;" (click)="close()">
                    {{'AddItems' | i18n}}</button>
            </div>
            <!--discountCoupon------------------------------------------------------------------------------------->
            <div class="group-discount-coupon-input">
                <div class="group-text-coupn">
                    <div class="text-coupon">
                        <i class="fa-light fa-ticket"></i>
                        {{'GotACoupon' | i18n}}
                    </div>
                    <div *ngIf="!cart.HasDiscountCoupon" class="add-coupon" (click)="addDiscountCoupon()">
                        {{'ToApply' | i18n}}
                    </div>
                    <div *ngIf="cart.HasDiscountCoupon" class="add-coupon" (click)="removeDiscountCoupon()">
                        {{'ToRemove' | i18n}}
                    </div>
                </div>
                <div *ngIf="cart.HasDiscountCoupon" class="view-code-coupon">
                    <div class="group-coupon-text">
                        <img src="assets/images/cupom-desconto.png" alt="" srcset="">
                        <div class="coupon">
                            {{cart.DiscountCoupon?.Code}}
                        </div>
                    </div>
                </div>
            </div>
            <!--payment------------------------------------------------------------------------------------->
            <div class="group-items-resume">
                <div class="group-payment-form">
                    <div class="text-payment">
                        {{'PaymentForm' | i18n}}
                        <p>{{'HowDoYouWantToPay' | i18n}}</p>
                    </div>
                    <div class="group-payment">
                        <div (click)="openPaymentFormModal()">
                            {{(!cart.PaymentForm?.Name ? 'SelectPaymentMethod' : 'ToReplace' ) | i18n}}</div>
                    </div>
                </div>
                <div class="payment" (click)="openPaymentFormModal()" *ngIf="cart.PaymentForm?.Name">
                    <div class="group-name-payment">
                        <img [src]="'/assets/images/payment-form/' + cart.PaymentForm.Icon + '.png'"
                            [alt]="cart.PaymentForm.Name | i18n">
                        <div class="name-payment">
                            {{cart.PaymentForm.Name | i18n}}
                        </div>
                    </div>
                    <div class="change-value" *ngIf="cart.PaymentForm.ChangeValueFor > 0">
                        {{'ChangeValue' | i18n: [cart.PaymentForm.ChangeValueFor | money]}}
                    </div>
                </div>
            </div>
        </div>
        <div class="group-buttons-footer"
            *ngIf="(parameterization.IsDeliveryEnabled || parameterization.IsPickupEnabled) && parameterization.CanReceiveOrders && parameterization.StoreIsOpen">
            <button type=" button" class="btn-add" (click)="validateOrder()"
                [disabled]="cart.Summary.SubtotalValue < parameterization.OrderMinValue || !cart.PaymentForm?.Name">
                {{'ProceedCheckout' | i18n}}
            </button>
        </div>
    </div>
    <div @overlay class="overlay"></div>
</ng-container>
<modal-product-detail [parameterization]="parameterization"></modal-product-detail>
<modal-checkout [parameterization]="parameterization" (orderSent)="finishOrder()"></modal-checkout>
<modal-payment-form [paymentForms]="parameterization.PaymentForms" [cartTotalValue]="cart.Summary.TotalValue"
    [currentPaymentForm]="cart.PaymentForm"></modal-payment-form>
<modal-order-address [parameterization]="parameterization"></modal-order-address>