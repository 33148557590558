import { Component, HostListener, Input, OnInit } from '@angular/core';
import { CartItem, CartSubItem } from '../../models/cart.model';
import {
   DeliveryParameterization,
   DeliveryServiceProduct,
   DeliveryServiceProductStep,
   DeliveryServiceProductStepItem,
} from '../../models/delivery-service.model';
import { TrackAddToCart, TrackViewContent } from '../../models/tracking.model';
import { CartService } from '../../services/cart.service';
import { DeliveryService } from '../../services/delivery-service.service';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { transitionAnimation } from '../../utils/animations';
import { FunctionService } from '../../utils/function.service';
import { copyObject, generateId, isDecimal, normalizeAccentedChars, toFloat } from '../../utils/functions';
import { FacebookPixelService } from './../../services/facebook-pixel.service';

@Component({
   selector: 'modal-product-detail',
   templateUrl: './product-detail.component.html',
   styleUrls: ['./product-detail.component.scss'],
   animations: [transitionAnimation],
})
export class ProductDetailComponent implements OnInit {
   public showModal = false;
   public isEdit = false;
   public isViewMode = false;
   public stepItemsRequiredValuesComplete = true;
   public itemIndexToEdit = -1;
   public urlToBack: string;
   public orderItem: CartItem;
   public isMobile = this.functionService.isMobile();
   public urlImageToZoom: string;

   @Input() parameterization: DeliveryParameterization;

   @HostListener('window:popstate', ['$event'])
   onPopState(event: Event) {
      if (this.showModal) {
         this.close();
      }
   }

   constructor(
      private deliveryService: DeliveryService,
      private cartService: CartService,
      private functionService: FunctionService,
      private facebookPixelService: FacebookPixelService,
      private googleAnalyticsService: GoogleAnalyticsService
   ) {}

   ngOnInit(): void {}

   close() {
      history.pushState(null, 'Pedemais Delivery', this.urlToBack);
      this.deliveryService.overflowService(true);

      this.showModal = false;
   }

   open(product: DeliveryServiceProduct, url: string, isViewMode: boolean = false) {
      //this.isViewMode = isViewMode;
      this.isEdit = false;
      this.orderItem = new CartItem();
      this.convertProductToOrderItem(product);
      this.checkIfStepItemsRequiredValuesComplete();
      this.showModal = true;

      this.urlToBack = url;
      history.pushState(null, 'Pedemais Delivery | Detalhe do produto', this.urlToBack + '/detalhes');

      if (this.isMobile) this.deliveryService.overflowService(false);

      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);

      this.sendToFacebookPixelAndGoogleAnalytics('ViewContent');
   }

   openEdition(orderItem: CartItem, index: number) {
      this.itemIndexToEdit = index;
      this.isEdit = true;
      this.orderItem = copyObject(orderItem);
      this.orderItem.SubItems = [];
      this.showModal = true;
   }

   convertProductToOrderItem(product: DeliveryServiceProduct) {
      const isComposition = product.CompositionMembers?.length > 0;
      const hasComplements = product.ProductSteps?.length > 0 && !product.ProductIsCombo;

      this.orderItem = {
         Id: generateId(),
         ProductId: product.Id,
         ProductBarcode: product.MainBarcode,
         ProductName: product.Name,
         OriginalProductName: product.OriginalName,
         MarketingClassificationId: product.MarketingClassificationId,
         MarketingClassificationName: product.MarketingClassificationName,
         OriginalMarketingClassificationName: product.OriginalMarketingClassificationName,
         ProductUnitAcronym: product.ProductUnitAcronym,
         CanBeFractioned: product.ProductUnitCanBeFractioned,
         AdditionalInfo: product.AdditionalInfo,
         ImageUrl: product.ImageUrl,
         Observation: null,
         MaxQuantityOnSale: product.MaxQuantityOnSale,
         IsProductsAgeRestricted: product.IsProductsAgeRestricted,
         ServesHowManyPeople: product.ServesHowManyPeople,
         CostValue: product.CostValue,
         GrossValue: product.SellValue,
         Quantity: 1,
         QuantityToShow: 1,
         ProductStepMinValue: product.ProductStepMinValue,
         SellValue: product.SellValue,
         OriginalSellValue: product.SellValue,
         SubItemsValue: 0,
         TotalValue: product.SellValue,
         SubItems: [],
         ProductSteps: copyObject(product.ProductSteps),
         CompositionMembers: copyObject(product.CompositionMembers),
         IsCombo: product.ProductIsCombo,
         HasComplements: hasComplements,
         IsComposition: isComposition,
      };

      this.orderItem.ProductSteps?.forEach((step) => {
         step.Quantity = 0;
         step.StepItems.forEach((item) => {
            item.Quantity = 0;
            item.QuantityToShow = 0;
         });
      });

      if (product.ProductUnitCanBeFractioned) {
         this.orderItem.StepIndex = 0;
      }
   }

   decrementProduct() {
      if (this.orderItem.Quantity === 1 && this.orderItem.CanBeFractioned) {
         this.orderItem.Quantity = this.orderItem.Quantity / 2;
         this.orderItem.TotalValue = toFloat(this.orderItem.SellValue / 2);
      } else if (this.orderItem.Quantity === 0.5) {
         this.orderItem.TotalValue = toFloat(this.orderItem.SellValue / 2);
         return;
      } else if (this.orderItem.Quantity === 1) {
         return;
      } else {
         this.orderItem.Quantity--;

         this.calculateTotalValue();
      }
   }

   decrementStepProduct(step: DeliveryServiceProductStep, item: DeliveryServiceProductStepItem) {
      item.QuantityToShow = item.QuantityToShow || 0;
      if (item.QuantityToShow > 0) {
         item.QuantityToShow -= 1;
         step.Quantity -= 1;
         this.calculateStepTotalValue();

         this.scrollToNextStep(step);
      }
   }

   incrementProduct() {
      if (this.orderItem.Quantity === 0.5 && this.orderItem.CanBeFractioned) {
         this.orderItem.Quantity = 1;
      } else {
         this.orderItem.Quantity++;
      }
      this.calculateTotalValue();
   }

   incrementStepProduct(step: DeliveryServiceProductStep, item: DeliveryServiceProductStepItem) {
      item.QuantityToShow = item.QuantityToShow || 0;
      if (item.QuantityToShow <= step.MaxQuantity) {
         item.QuantityToShow += 1;
         step.Quantity += 1;
         this.calculateStepTotalValue();

         this.scrollToNextStep(step);
      }
   }

   private calculateTotalValue() {
      if (this.orderItem.ProductSteps && this.orderItem.ProductSteps.length > 0) {
         this.calculateStepTotalValue();
      } else {
         this.orderItem.TotalValue = toFloat(this.orderItem.SellValue * this.orderItem.Quantity);
         this.orderItem.GrossValue = this.orderItem.TotalValue;
      }
   }

   private calculateStepTotalValue() {
      if (this.orderItem.ProductSteps && this.orderItem.ProductSteps.length > 0) {
         const comboStepSumTotalValue = this.orderItem.ProductSteps.reduce((stepSum, step: DeliveryServiceProductStep) => {
            if (step.IsFractionItems) {
               const comboStepQuantity = this.getStepSelectedItemsCount(step);
               switch (step.ValueCalculationType) {
                  case 1:
                     // Biggest sellPrice
                     const productWithBiggestSellPrice = this.getProductWithBiggestSellPrice(step);

                     stepSum += step.StepItems.reduce((stepItemSum: number, item: DeliveryServiceProductStepItem) => {
                        const itemQuantity = item.QuantityToShow && item.QuantityToShow > 0 ? (1.0 / comboStepQuantity) * 1 : 0;
                        item.StepIndex = step.Position;
                        item.CanBeFractioned = true;
                        // item.SellPrice = productWithBiggestSellPrice;
                        item.Quantity = toFloat(itemQuantity);
                        item.TotalValue = toFloat(productWithBiggestSellPrice * itemQuantity);
                        item.GrossValue = item.TotalValue;
                        return item.TotalValue ? productWithBiggestSellPrice : stepItemSum;
                     }, 0);

                     return stepSum;

                  case 2:
                     // Lowest sellPrice
                     const productWithLowestSellPrice = this.getProductWithLowestSellPrice(step);

                     stepSum += step.StepItems.reduce((stepItemSum: number, item: DeliveryServiceProductStepItem) => {
                        const itemQuantity = item.QuantityToShow && item.QuantityToShow > 0 ? (1.0 / comboStepQuantity) * 1 : 0;
                        item.StepIndex = step.Position;
                        item.CanBeFractioned = true;
                        item.SellPrice = productWithLowestSellPrice;
                        item.Quantity = toFloat(itemQuantity);
                        item.TotalValue = toFloat(productWithLowestSellPrice * itemQuantity);
                        item.GrossValue = toFloat(productWithLowestSellPrice * itemQuantity);
                        return item.TotalValue ? productWithLowestSellPrice : stepItemSum;
                     }, 0);

                     return stepSum;

                  case 3:
                     stepSum += step.StepItems.reduce((stepItemSum: number, item: DeliveryServiceProductStepItem) => {
                        const itemQuantity = item.QuantityToShow && item.QuantityToShow > 0 ? item.QuantityToShow / comboStepQuantity : 0;
                        item.StepIndex = step.Position;
                        item.CanBeFractioned = true;
                        item.Quantity = toFloat(itemQuantity);
                        item.TotalValue = toFloat(item.SellPrice * itemQuantity);
                        item.GrossValue = item.TotalValue;
                        return item.TotalValue ? stepItemSum + item.TotalValue : stepItemSum;
                     }, 0);

                     return stepSum;
               }
            } else {
               return (stepSum += step.StepItems.reduce((stepItemSum: number, item: DeliveryServiceProductStepItem) => {
                  item.Quantity = item.QuantityToShow;
                  item.TotalValue = item.QuantityToShow && item.QuantityToShow > 0 ? item.SellPrice * item.QuantityToShow : 0;
                  item.GrossValue = item.QuantityToShow && item.QuantityToShow > 0 ? item.SellPrice * item.QuantityToShow : 0;
                  return item.TotalValue ? stepItemSum + item.TotalValue : stepItemSum;
               }, 0));
            }
         }, 0);

         if (this.orderItem.IsCombo) {
            this.orderItem.TotalValue = toFloat(this.orderItem.Quantity * comboStepSumTotalValue);
            // this.orderItem.SellValue = toFloat(this.orderItem.TotalValue / this.orderItem.Quantity);
            this.orderItem.GrossValue = this.orderItem.TotalValue;
         } else {
            const subItensTotal = comboStepSumTotalValue * (this.orderItem.HasComplements ? this.orderItem.Quantity : 1);
            const orderValue = this.orderItem.SellValue * this.orderItem.Quantity;
            this.orderItem.TotalValue = toFloat(orderValue + subItensTotal);
         }

         this.checkIfStepItemsRequiredValuesComplete();
      }
   }

   private checkIfStepItemsRequiredValuesComplete() {
      if (this.orderItem.ProductSteps && this.orderItem.ProductSteps.length > 0) {
         this.stepItemsRequiredValuesComplete = this.orderItem.ProductSteps.every((n) => n.Quantity >= n.MinQuantity);
      } else {
         this.stepItemsRequiredValuesComplete = true;
      }
   }

   private getStepSelectedItemsCount(step: DeliveryServiceProductStep) {
      return step.StepItems.reduce((sum, item) => {
         return item.QuantityToShow ? item.QuantityToShow + sum : sum;
      }, 0);
   }

   private getProductWithBiggestSellPrice(step: DeliveryServiceProductStep) {
      const productWithGreaterSellPriceFilter = step.StepItems.filter((n) => n.QuantityToShow > 0)
         .sort((item1, item2) => item1.SellPrice - item2.SellPrice)
         .reverse();
      let productWithGreaterSellPrice = null;
      if (productWithGreaterSellPriceFilter && productWithGreaterSellPriceFilter.length > 0) {
         productWithGreaterSellPrice = productWithGreaterSellPriceFilter[0].SellPrice;
      }

      return productWithGreaterSellPrice;
   }

   private getProductWithLowestSellPrice(step: DeliveryServiceProductStep) {
      const productWithGreaterSellPriceFilter = step.StepItems.filter((n) => n.QuantityToShow > 0).sort(
         (item1, item2) => item1.SellPrice - item2.SellPrice
      );
      let productWithGreaterSellPrice = null;
      if (productWithGreaterSellPriceFilter && productWithGreaterSellPriceFilter.length > 0) {
         productWithGreaterSellPrice = productWithGreaterSellPriceFilter[0].SellPrice;
      }

      return productWithGreaterSellPrice;
   }

   saveProduct() {
      this.sendToFacebookPixelAndGoogleAnalytics('AddToCart');
      this.orderItem.Observation = normalizeAccentedChars(this.orderItem.Observation);
      this.createOrderSubItems();

      this.updateProductOrder();

      this.close();
   }

   private updateProductOrder() {
      if (this.isEdit) {
         this.cartService.updateProduct(this.orderItem, this.itemIndexToEdit);
      } else {
         this.cartService.addProduct(this.orderItem);
      }
   }

   private createOrderSubItems() {
      if (this.orderItem.ProductSteps && this.orderItem.ProductSteps.length > 0) {
         this.orderItem.SubItems = [];
         this.orderItem.ProductSteps.forEach((step: DeliveryServiceProductStep) => {
            step.StepItems.filter((n) => n.QuantityToShow > 0).map((n) => {
               if (n.CanBeFractioned) {
                  for (let index = 0; index < n.QuantityToShow; index++) {
                     const subItem = this.getSubItem(n);
                     this.orderItem.SubItems.push(subItem);
                  }
               } else {
                  const subItem = this.getSubItem(n);
                  this.orderItem.SubItems.push(subItem);
               }
            });
         });

         this.orderItem.SubItems.forEach((subItem) => {
            subItem.FractionedQuantity = this.getProductQuantity(this.orderItem.SubItems, subItem, this.orderItem.Quantity);
         });
      }
   }

   private getProductQuantity = (subItems: CartSubItem[], subItem: CartSubItem, itemQuantity: number = 1): string => {
      const decimalItemsQuantity = subItems.filter((sub) => isDecimal(sub.Quantity) && sub.StepIndex == subItem.StepIndex).length;
      return isDecimal(subItem.Quantity) ? `1/${decimalItemsQuantity}` : (subItem.Quantity * itemQuantity).toString();
   };

   private getSubItem(n: DeliveryServiceProductStepItem): CartSubItem {
      return {
         Id: generateId(),
         ProductId: n.ProductId,
         ProductBarcode: n.MainBarcode,
         ProductName: n.ProductName,
         OriginalProductName: n.OriginalProductName,
         Quantity: n.Quantity,
         QuantityToShow: n.QuantityToShow,
         SellValue: toFloat(n.TotalValue / n.Quantity),
         OriginalSellValue: n.SellPrice,
         GrossValue: n.GrossValue,
         TotalValue: n.TotalValue,
         CanBeFractioned: n.CanBeFractioned,
         StepIndex: n.StepIndex,
         MarketingClassificationId: n.MarketingClassificationId,
         MarketingClassificationName: n.MarketingClassificationName,
         OriginalMarketingClassificationName: n.OriginalMarketingClassificationName,
      };
   }

   checkStepProduct(event: Event, step: DeliveryServiceProductStep, item: DeliveryServiceProductStepItem) {
      event.preventDefault();

      step.StepItems.forEach((n) => {
         n.QuantityToShow = 0;
         n.Quantity = 0;
      });

      const unchecked = step.Quantity === 0 || step.MinQuantity > 0;
      step.Quantity = unchecked ? 1 : 0;
      item.QuantityToShow = step.Quantity;
      item.Quantity = step.Quantity;

      this.calculateStepTotalValue();

      this.scrollToNextStep(step);
   }

   scrollToNextStep(step: DeliveryServiceProductStep) {
      if (step.Quantity === step.MaxQuantity) {
         setTimeout(() => {
            const index = step.Position + 1;
            const stepIndex = document.getElementById(`step-index-${index}`);
            if (stepIndex) {
               stepIndex.scrollIntoView({
                  behavior: 'smooth',
                  block: 'start',
                  inline: 'nearest',
               });
            }
         }, 100);
      }
   }

   handleImageZoom(isOpen: boolean, urlImage: string = null) {
      this.urlImageToZoom = urlImage;
      const element = document.getElementsByClassName('img-product-zoom')[0];

      if (isOpen) {
         element.classList.add('openZoom');
      } else {
         element.classList.remove('openZoom');
      }
   }

   //---FacebookPixelAndGoogleAnalytics-----------------------
   sendToFacebookPixelAndGoogleAnalytics(method: string) {
      const acceptedFacebookTracksAndGoogleAnalytics = {
         AddToCart: () => {
            const param: TrackAddToCart = {
               ContentIds: [this.orderItem.ProductId],
               ProductName: [this.orderItem.ProductName],
               TotalValue: this.orderItem.TotalValue,
            };
            this.facebookPixelService.trackAddToCart(this.parameterization.FacebookPixelId, param);
            this.googleAnalyticsService.trackAddToCart(
               this.parameterization.GoogleMetricId,
               this.parameterization.IsGoogleAnalyticEnabled,
               param
            );
         },
         ViewContent: () => {
            const param: TrackViewContent = {
               CategoryName: this.orderItem.MarketingClassificationName,
               ProductName: this.orderItem.ProductName,
               ProductValue: this.orderItem.TotalValue,
            };
            this.facebookPixelService.trackViewContent(this.parameterization.FacebookPixelId, param);
            this.googleAnalyticsService.trackViewContent(
               this.parameterization.GoogleMetricId,
               this.parameterization.IsGoogleAnalyticEnabled,
               param
            );
         },
      };
      const facebookTrackAndGoogleAnalytics = acceptedFacebookTracksAndGoogleAnalytics[method];
      if (facebookTrackAndGoogleAnalytics) {
         facebookTrackAndGoogleAnalytics();
      }
   }
}
