import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FriendlyNameGuard } from './core/guards/friendly-name.guard';

registerLocaleData(localePt);

const routes: Routes = [
   { path: '', redirectTo: 'empresa-nao-encontrada', pathMatch: 'full' },
   {
      path: 'empresa-nao-encontrada',
      loadChildren: () => import('./pages/company-not-found/company-not-found.module').then((m) => m.CompanyNotFoundPageModule),
   },
   {
      path: ':friendly-name',
      loadChildren: () => import('./pages/home/home.module').then((m) => m.HomePageModule),
      canActivate: [FriendlyNameGuard],
   },
   { path: '**', redirectTo: 'empresa-nao-encontrada' },
];

@NgModule({
   imports: [RouterModule.forRoot(routes)],
   exports: [RouterModule],
})
export class AppRoutingModule {}
