import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
   enableProdMode();
}

const googleKey = environment.googleApiKey;
const script = document.createElement('script');
script.type = 'text/javascript';
script.setAttribute('src', `https://maps.googleapis.com/maps/api/js?key=${googleKey}&libraries=places`);
document.getElementsByTagName('head')[0].appendChild(script);

// const user = JSON.parse(localStorage.getItem('pede-mais-delivery-user'));

// if (user && user.Theme === 'dark-mode') {
//    const $html = document.querySelector('html');
//    $html.classList.toggle('dark-mode');
// }

platformBrowserDynamic()
   .bootstrapModule(AppModule)
   .catch((err) => console.error(err));
