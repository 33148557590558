export class DeliveryServiceDeliveryFeeRequest {
   CompanyId: number;
   DestinationAddress: string;
   DestinationLatitude?: number;
   DestinationLongitude?: number;

   constructor(companyId: number, destinationAddress: string, destinationLatitude?: number, destinationLongitude?: number) {
      this.CompanyId = companyId;
      this.DestinationAddress = destinationAddress;
      this.DestinationLatitude = destinationLatitude;
      this.DestinationLongitude = destinationLongitude;
   }
}

export class DeliveryServiceDeliveryFeeResponse {
   CompanyMessage?: string;
   CustomerMessage?: string;
   DeliveryFee: number;
   TimeToDeliver: number;
   Distance?: number;
   IsOutsideCoverageArea: boolean;
}

export class DiscountCoupon {
   constructor(
      id: string,
      companyId: number,
      code: string,
      minimumOrderValue: number,
      isAvailable: boolean,
      couponType?: DiscountCouponType,
      couponValue?: number,
      couponCategory?: DiscountCouponCategoryType
   ) {
      this.Id = id;
      this.CompanyId = companyId;
      this.Code = code;
      this.MinimumOrderValue = minimumOrderValue;
      this.IsAvailable = isAvailable;
      this.CouponType = couponType || DiscountCouponType.Percentage;
      this.CouponValue = couponValue;
      this.CouponCategory = couponCategory || DiscountCouponCategoryType.OnFreeDelivery;
      this.CouponCategoryName = couponCategory?.toString();
      this.CouponTypeName = couponType?.toString();
   }
   Id: string;
   CompanyId: number;
   Code: string;
   MinimumOrderValue: number;
   IsAvailable: boolean;
   CouponType?: DiscountCouponType;
   CouponValue?: number;
   CouponCategory: DiscountCouponCategoryType;
   CouponCategoryName: string;
   CouponTypeName: string;
}

export enum DiscountCouponCategoryType {
   OnSale = 1,
   OnDeliveryFee = 2,
   OnFreeDelivery = 3,
}

export enum DiscountCouponType {
   Value = 1,
   Percentage = 2,
}

export class DiscountCouponServiceResponse {
   Id: string;
   Code: string;
   CouponCategory: number;
   MinimumOrderValue: number;
   CouponType?: number;
   CouponValue?: number;
   Quantity: number;
   StartDate: Date;
   EndDate: Date;
   IsActive: boolean;

   AllowedOnSunday: boolean;
   AllowedOnMonday: boolean;
   AllowedOnTuesday: boolean;
   AllowedOnWednesday: boolean;
   AllowedOnThursday: boolean;
   AllowedOnFriday: boolean;
   AllowedOnSaturday: boolean;

   CouponCategoryName: string;
   CouponTypeName: string;

   CompanyId: number;

   constructor(isActive?: boolean) {
      this.IsActive = isActive;
      this.AllowedOnSunday = true;
      this.AllowedOnMonday = true;
      this.AllowedOnTuesday = true;
      this.AllowedOnWednesday = true;
      this.AllowedOnThursday = true;
      this.AllowedOnFriday = true;
      this.AllowedOnSaturday = true;
   }
}
