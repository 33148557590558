import { Component, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Language } from 'src/app/shared/models/language.model';
import { I18nService } from 'src/app/shared/services/i18n.service';

@Component({
   selector: 'modal-lang',
   templateUrl: './lang.component.html',
   styleUrls: ['./lang.component.scss'],
})
export class LangComponent implements OnInit {
   public currentLanguage: Language;
   public languages: Language[];

   constructor(private i18nService: I18nService, private _bottomSheet: MatBottomSheet) {}

   ngOnInit(): void {
      this.languages = this.i18nService.getLanguages();
      this.currentLanguage = this.i18nService.getCurrentLanguage();
   }

   setLanguage(language: Language) {
      const languageChanged = this.currentLanguage.Key != language.Key;
      if (languageChanged) {
         this.i18nService.setCurrentLanguage(language);
      }
      this._bottomSheet.dismiss(languageChanged);
   }
}
