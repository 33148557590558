<ng-container *ngIf="showModal">
    <div class="modal multiple-dialog modal-customer" @modal>
        <div class="customer-content">
            <div class="header-modal">
                <i class="fa-solid fa-arrow-left" (click)="close()"></i>
                <div class="title-modal"> {{'UserProfile' | i18n}} </div>
            </div>
            <div class="profile-image">
                <div class="image"></div>
            </div>
            <mat-form-field class="example-full-width">
                <mat-label>{{'Name' | i18n}}</mat-label>
                <input matInput type="text" [(ngModel)]="user.Name" placeholder="{{'Name' | i18n}}" autofocus>
            </mat-form-field>
            <mat-form-field class="example-full-width">
                <mat-label>{{'CellPhone' | i18n}}</mat-label>
                <input #cellPhone matInput type="text" [(ngModel)]="user.CellPhone" placeholder="(00) 0 0000-0000"
                    mask="(00) 0 0000-0000" [dropSpecialCharacters]="false">
            </mat-form-field>
            <mat-form-field class="example-full-width">
                <mat-label>{{'DocumentNumber' | i18n}}</mat-label>
                <input #documentNumber matInput type="text" [(ngModel)]="user.DocumentNumber"
                    placeholder="000.000.000-00" mask="000.000.000-00" [dropSpecialCharacters]="false">
            </mat-form-field>
            <mat-form-field class="example-full-width">
                <mat-label>{{'Email' | i18n}}</mat-label>
                <input #email matInput type="text" [(ngModel)]="user.Email" placeholder="{{'EmailPlaceholder' | i18n}}">
            </mat-form-field>
        </div>
        <div class="group-buttons-footer">
            <button type="button" (click)="handleSaveCustomer()"
                [disabled]="!user.Name || !user.DocumentNumber || !user.CellPhone || !user.Email">
                {{'Save'| i18n}}
            </button>
        </div>
    </div>
    <div (click)="close()" @overlay class="overlay multiple-dialog"></div>
</ng-container>