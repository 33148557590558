import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import {
   Cart,
   CartAddress,
   CartItem,
   CartOnlinePaymentStatusType,
   CartPaymentForm,
   CartStatusType,
   CartSubItem,
} from '../models/cart.model';
import { DeliveryParameterization } from '../models/delivery-service.model';
import { DiscountCoupon } from '../models/discount-coupon.model';
import {
   CreateOrder,
   CreateOrderCustomer,
   CreateOrderDiscountCoupom,
   CreateOrderItem,
   CreateOrderPaymentForm,
   CreateOrderSubItem,
} from '../models/order.model';
import { UserAddress, UserSummary } from '../models/user.model';

@Injectable({
   providedIn: 'root',
})
export class OrderService {
   constructor() {}

   convertToCreateOrder(cart: Cart, userSummary: UserSummary, parameterization: DeliveryParameterization): CreateOrder {
      const timeToDeliver = cart.IsDelivery ? cart.DeliveryFee?.TimeToDeliver : cart.EstimatedTimeToPrepare;
      const deliveryDate = moment()
         .add(timeToDeliver || 0, 'm')
         .toDate();
      const deliveryFeeCustomerMessage = cart.IsDelivery ? cart.DeliveryFee?.CustomerMessage || '' : '';

      const paymentForms = this.getPaymentForm(cart.PaymentForm, cart.Summary.TotalValue, cart.OnlinePayment?.ReferenceId);
      const discountCoupom = this.getDiscountCoupon(cart.DiscountCoupon, cart.Summary.DiscountCouponValue);

      const customer = this.getCustomer(userSummary, cart.IsDelivery ? cart.Address : parameterization.Address);
      const items = this.getItems(cart.Items);

      const isPrepaid = cart.OnlinePayment?.Status == CartOnlinePaymentStatusType.Approved;
      const inPayment = cart.Status == CartStatusType.InPayment;

      const order: CreateOrder = {
         Id: cart.OnlinePayment?.OrderId,
         CompanyId: cart.CompanyId,
         IntegrationCode: cart.OnlinePayment?.IntegrationCode, // cart.IntegrationCode,
         AuthorizationCode: cart.OnlinePayment?.IntegrationId, // cart.AuthorizationCode,
         BuyValue: cart.Summary.SubtotalValue,
         DiscountValue: 0,
         AdditionValue: 0,
         DeliveryFee: cart.Summary.DeliveryFee,
         DeliveryFeeCustomerMessage: deliveryFeeCustomerMessage,
         TotalValue: cart.Summary.TotalValue,
         PaidValue: paymentForms[0].GrossValue,
         ChangeValue: paymentForms[0].ChangeValue,
         IsDelivery: cart.IsDelivery,
         IsScheduled: cart.IsScheduled,
         IsPrepaid: isPrepaid || inPayment,
         StatusCode: inPayment ? 'InPayment' : null, // cart.StatusCode,
         PaymentFormName: paymentForms[0].Name,
         DeliveryDate: deliveryDate,
         CustomerInfo: customer,
         Items: items,
         PaymentForms: paymentForms,
         DiscountCoupomInfo: discountCoupom,
         MediatorCode: cart.OnlinePayment?.MediatorCode,
         SkipOrderCenterOrder: isPrepaid && !inPayment,
         AppVersion: environment.version,
      };

      return order;
   }
   getItems(items: CartItem[]): CreateOrderItem[] {
      let orderItems: CreateOrderItem[] = [];
      items.forEach((item) => {
         const orderItem: CreateOrderItem = {
            ProductId: item.ProductId,
            ProductBarcode: item.ProductBarcode,
            ProductName: item.OriginalProductName,
            ProductUnitAcronym: item.ProductUnitAcronym,
            MarketingClassificationId: item.MarketingClassificationId,
            Quantity: item.Quantity,
            CostValue: item.CostValue,
            SellValue: item.OriginalSellValue,
            AdditionValue: 0, // item.AdditionValue,
            DiscountValue: 0, // item.DiscountValue,
            GrossValue: item.GrossValue,
            TotalValue: item.TotalValue,
            Observation: item.Observation,
            SubItems: this.getSubItems(item.SubItems),
         };

         this.checkComplementItems(orderItem);

         orderItems.push(orderItem);
      });

      return orderItems;
   }

   private getSubItems(subItems: CartSubItem[]): CreateOrderSubItem[] {
      let orderSubItems: CreateOrderSubItem[] = [];
      subItems?.forEach((subItem) => {
         const orderItem: CreateOrderSubItem = {
            ProductId: subItem.ProductId,
            ProductBarcode: subItem.ProductBarcode,
            ProductName: subItem.OriginalProductName,
            MarketingClassificationId: subItem.MarketingClassificationId,
            Quantity: subItem.Quantity,
            SellValue: subItem.OriginalSellValue,
            AdditionValue: 0, // item.AdditionValue,
            DiscountValue: 0, // item.DiscountValue,
            GrossValue: subItem.GrossValue,
            TotalValue: subItem.TotalValue,
         };

         orderSubItems.push(orderItem);
      });

      return orderSubItems;
   }

   private checkComplementItems(item: CreateOrderItem) {
      if (item.SellValue > 0 && item.SubItems && item.SubItems.length > 0) {
         item.SubItems.unshift({
            ProductId: item.ProductId,
            ProductBarcode: item.ProductBarcode,
            ProductName: item.ProductName,
            MarketingClassificationId: item.MarketingClassificationId,
            Quantity: 1,
            SellValue: item.SellValue,
            GrossValue: item.SellValue,
            TotalValue: item.SellValue,
            DiscountValue: 0,
            AdditionValue: 0,
         });

         item.GrossValue = item.SellValue * item.Quantity;
         item.TotalValue = item.GrossValue;
         item.SellValue = 0;
      }
      return item;
   }

   private getCustomer(userSummary: UserSummary, address: CartAddress): CreateOrderCustomer {
      return {
         CustomerId: null, // address.CustomerId,
         Name: userSummary.Name,
         Cpf: userSummary.DocumentNumber,
         Email: userSummary.Email,
         Phone: userSummary.CellPhone,
         FullAddress: address.FullAddress,
         StreetName: address.StreetName,
         StreetNumber: address.StreetNumber,
         Complement: address.Complement,
         Reference: address.Reference,
         Neighborhood: address.Neighborhood,
         City: address.City,
         PostalCode: address.PostalCode,
         State: address.State,
         Country: address.Country,
         Latitude: address.Latitude,
         Longitude: address.Longitude,
      };
   }

   private getDiscountCoupon(discountCoupom: DiscountCoupon, discountValue: number): CreateOrderDiscountCoupom {
      if (discountValue > 0 && discountCoupom.Code) {
         return { Code: discountCoupom.Code, Value: discountValue };
      }
      return null;
   }

   private getPaymentForm(paymentForm: CartPaymentForm, totalValue: number, referenceId: string): CreateOrderPaymentForm[] {
      const hasChangeValue = paymentForm.ChangeValueFor > totalValue;
      const changeValue = hasChangeValue ? paymentForm.ChangeValueFor - totalValue : 0;
      const grossValue = hasChangeValue ? paymentForm.ChangeValueFor : totalValue;

      return [
         {
            Id: paymentForm.Id,
            Name: paymentForm.OriginalName,
            GrossValue: grossValue,
            ChangeValue: changeValue,
            Value: totalValue,
            IsPrepaid: paymentForm.IsPrepaid,
            IntegrationId: null, //paymentForm.IntegrationId,
            Issuer: null, // paymentForm.Issuer,
            ReferenceId: referenceId,
         },
      ];
   }

   convertCartAddressToUserAddress(address: CartAddress, isDelivery): UserAddress {
      let userAddres = new UserAddress();
      userAddres.City = address.City;
      userAddres.Complement = address.Complement;
      userAddres.Country = address.Country;
      userAddres.FullAddress = address.FullAddress;
      userAddres.Id = address.Id;
      userAddres.IsFavorite = isDelivery;
      userAddres.Latitude = address.Latitude;
      userAddres.Longitude = address.Longitude;
      userAddres.Neighborhood = address.Neighborhood;
      userAddres.PostalCode = address.PostalCode;
      userAddres.Reference = address.Reference;
      userAddres.ShortAddress = address.ShortAddress;
      userAddres.ShortCityAddress = address.ShortCityAddress;
      userAddres.State = address.State;
      userAddres.StreetName = address.StreetName;
      userAddres.StreetNumber = address.StreetNumber;

      return userAddres;
   }
}
