<div class="header-bottom-sheet">
   <i class="fa-light fa-xmark" (click)="handleClose()"></i>
</div>
<div class="whatsapp-content">
   <i class="fa-brands fa-whatsapp"></i>

   <h3>{{'ProblemsSendingTheOrderViaWhatsApp' | i18n}}</h3>

   <div class="group-buttons">
      <button type="button" class="btn-defaut" (click)="handleSetAction()">{{'TryAgain' | i18n}}</button>
      <button type="button" (click)="handleSetAction(true)">{{'TrackOrder' | i18n}}</button>
   </div>
</div>