import { Component, HostListener, Input, OnInit } from '@angular/core';
import { DeliveryService } from '../../services/delivery-service.service';
import { transitionAnimation } from '../../utils/animations';
import { FunctionService } from '../../utils/function.service';
import { DeliveryParameterization } from './../../models/delivery-service.model';

@Component({
   selector: 'modal-about-company',
   templateUrl: './about-company.component.html',
   styleUrls: ['./about-company.component.scss'],
   animations: [transitionAnimation],
})
export class AboutCompanyComponent implements OnInit {
   public showModal = false;
   public urlToBack: string;
   public isMobile = this.functionService.isMobile();

   @Input() parameterization: DeliveryParameterization;

   @HostListener('window:popstate', ['$event'])
   onPopState(event: Event) {
      if (this.showModal) {
         this.close();
      }
   }

   constructor(private functionService: FunctionService, private deliveryService: DeliveryService) {}

   ngOnInit(): void {}

   open(url: string) {
      this.showModal = true;

      this.urlToBack = url;
      history.pushState(null, 'Pedemais Delivery | Sobre', this.urlToBack.split('produtos').join('sobre'));

      if (this.isMobile) this.deliveryService.overflowService(false);

      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
   }

   close() {
      history.pushState(null, 'Pedemais Delivery', this.urlToBack);
      this.deliveryService.overflowService(true);
      this.showModal = false;
   }
}
