<div class="group-address">
   <div class="group-input-search">
      <mat-form-field class="example-full-width">
         <mat-label>{{'SearchAddress' | i18n}}</mat-label>
         <input lowercase matInput id="searchAddress" type="text" [(ngModel)]="searchAddress"
            placeholder="{{ 'SearchAddress' | i18n}}" (input)="autocompleteChanged()" autofocus>
         <mat-hint [align]="'end'">
            <img class="by-google"
               src="https://maps.gstatic.com/mapfiles/api-3/images/powered-by-google-on-white3_hdpi.png" alt="">
         </mat-hint>
      </mat-form-field>
   </div>
   <ul class="address-autocomplete-results" *ngIf="suggestions && suggestions.length > 0">
      <li class="results-item" *ngFor='let item of suggestions; let i = index;'>
         <div id="address-sug-{{i}}" class="results-item-wrapper" (click)="addressClicked(item.place_id)">
            <i class="fa-thin fa-location-dot"></i>
            <div class="structured-formatting">
               <div class="main-text">
                  {{ item.structured_formatting.main_text }}
               </div>
               <div class="secondary-text">
                  {{ item.structured_formatting.secondary_text }}
               </div>
            </div>
         </div>
      </li>
   </ul>
</div>