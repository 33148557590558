import { Injectable } from '@angular/core';
import { TrackAddToCart, TrackInitiateCheckout, TrackViewContent } from '../models/tracking.model';

declare let gtag: Function;

@Injectable({
   providedIn: 'root',
})
export class GoogleAnalyticsService {
   public googleAnalyticsTagCreated: boolean;
   public googleMetricId: string;
   private eventTagName: string = 'event';

   constructor() {}

   setScriptTag(metricId: string) {
      if (metricId) {
         this.initGoogleAnalytics(metricId);
         this.googleAnalyticsTagCreated = true;
         this.googleMetricId = metricId;
      }
   }

   trackInitiateCheckout(metricId: string, isEnabled: boolean, param: TrackInitiateCheckout) {
      if (!isEnabled) return;

      if (!this.googleAnalyticsTagCreated && metricId) {
         this.setScriptTag(metricId);
      }

      if (this.googleAnalyticsTagCreated) {
         gtag(this.eventTagName, 'InitiateCheckout', {
            content_ids: param.ContentIds,
            value: param.TotalValue ? parseFloat(param.TotalValue.toFixed(2)) : 0.0,
            num_items: param.ProductsCount,
            currency: 'BRL',
            content_name: param.ProductNameList,
         });
      }
   }

   trackPageView(metricId: string, isEnabled: boolean, url: string) {
      if (!isEnabled) return;

      if (!this.googleAnalyticsTagCreated && metricId) {
         this.setScriptTag(metricId);
      }

      if (this.googleAnalyticsTagCreated) {
         gtag(this.eventTagName, 'PageView', { url: url });
      }
   }

   trackPurchase(metricId: string, isEnabled: boolean, totalValue: number) {
      if (!isEnabled) return;

      if (!this.googleAnalyticsTagCreated && metricId) {
         this.setScriptTag(metricId);
      }

      if (this.googleAnalyticsTagCreated) {
         gtag(this.eventTagName, 'Purchase', {
            currency: 'BRL',
            value: totalValue ? parseFloat(totalValue.toFixed(2)) : 0.0,
         });
      }
   }

   trackAddToCart(metricId: string, isEnabled: boolean, param: TrackAddToCart) {
      if (!isEnabled) return;

      if (!this.googleAnalyticsTagCreated && metricId) {
         this.setScriptTag(metricId);
      }

      if (this.googleAnalyticsTagCreated) {
         gtag(this.eventTagName, 'AddToCart', {
            currency: 'BRL',
            content_ids: param.ContentIds,
            value: param.TotalValue ? parseFloat(param.TotalValue.toFixed(2)) : 0.0,
            content_name: param.ProductName,
         });
      }
   }

   trackViewContent(metricId: string, isEnabled: boolean, param: TrackViewContent) {
      if (!isEnabled) return;

      if (!this.googleAnalyticsTagCreated && metricId) {
         this.setScriptTag(metricId);
      }

      if (this.googleAnalyticsTagCreated) {
         gtag(this.eventTagName, 'ViewContent', {
            currency: 'BRL',
            value: param.ProductValue ? parseFloat(param.ProductValue.toFixed(2)) : 0.0,
            content_name: param.ProductName,
            content_category: param.CategoryName,
         });
      }
   }

   initGoogleAnalytics(trackingID: string): void {
      let gaScript = document.createElement('script');
      gaScript.setAttribute('async', 'true');
      gaScript.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${trackingID}`);

      let gaScript2 = document.createElement('script');
      gaScript2.innerText = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}
    gtag(\'js\', new Date());gtag(\'config\', \'${trackingID}\', \'auto\');`;

      document.documentElement.firstChild.appendChild(gaScript);
      document.documentElement.firstChild.appendChild(gaScript2);
   }
}
