import { Component, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheet } from '@angular/material/bottom-sheet';
import { NotificationService } from './../../services/notification.service';

@Component({
   selector: 'bottom-sheet-change',
   templateUrl: './change.component.html',
   styleUrls: ['./change.component.scss'],
})
export class ChangeComponent implements OnInit {
   public changeValue: number;
   public cartTotalValue: number;

   constructor(
      private _bottomSheet: MatBottomSheet,
      @Inject(MAT_BOTTOM_SHEET_DATA) public data: { changeValue?: number; cartTotalValue: number },
      private notificationService: NotificationService
   ) {
      this.cartTotalValue = data.cartTotalValue;
      if (this.data.changeValue > 0) {
         this.changeValue = this.data.changeValue;
      }
   }

   ngOnInit(): void {}

   close() {
      this._bottomSheet.dismiss();
   }

   alterChangeValue() {
      if (this.changeValue < this.cartTotalValue) {
         this.notificationService.showAlertBox('TheAmountEnteredIsLessThanTheTotalAmountOfTheOrder');
      } else {
         this._bottomSheet.dismiss(Number(this.changeValue));
      }
   }
}
