import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CreateDeliveryPaymentRequest, CreateDeliveryPaymentResponse, GetPaymentStatusResponse } from '../models/pix-payment.model';
import { ApiService } from './api.service';

@Injectable({
   providedIn: 'root',
})
export class PixPaymentService {
   apiUrl = environment.pixPaymentApiUrl;
   domainName = 'Payment';

   constructor(private apiService: ApiService) {}

   generateCopyAndPastPix(request: CreateDeliveryPaymentRequest): Observable<CreateDeliveryPaymentResponse> {
      // generateCopyAndPastPix(request: CreateDeliveryPaymentRequest): Observable<DeliveryPaymentBaseResponse> {
      return this.apiService.post(`${this.domainName}/Delivery`, request, this.apiUrl);
   }

   getStatus(paymentId: string): Observable<GetPaymentStatusResponse> {
      // getStatus(paymentId: string): Observable<DeliveryPaymentBaseResponse> {
      return this.apiService.get(`${this.domainName}/Status/${paymentId}`, null, this.apiUrl);
   }
}
