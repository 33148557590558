import { NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheet, MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';

const MODULES = [
   MatProgressSpinnerModule,
   MatRadioModule,
   MatSlideToggleModule,
   MatExpansionModule,
   MatTabsModule,
   MatButtonToggleModule,
   MatBadgeModule,
   MatButtonModule,
   MatIconModule,
   MatButtonModule,
   MatBottomSheetModule,
   MatRadioModule,
   MatDialogModule,
   MatSelectModule,
   MatInputModule,
   MatFormFieldModule,
   MatCheckboxModule,
];

@NgModule({
   exports: [MODULES],
   providers: [{ provide: MatBottomSheet }, { provide: MAT_BOTTOM_SHEET_DATA, useValue: {} }],
})
export class MaterialModule {}
