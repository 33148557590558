import { Component, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheet } from '@angular/material/bottom-sheet';
import { CartSubItem } from 'src/app/core/models/cart.model';
import { isDecimal } from 'src/app/core/utils/functions';

@Component({
   selector: 'kcms-detail-order',
   templateUrl: './detail-order.component.html',
   styleUrls: ['./detail-order.component.scss'],
})
export class DetailOrderHistoryComponent implements OnInit {
   public orderHistory: any;

   constructor(private _bottomSheet: MatBottomSheet, @Inject(MAT_BOTTOM_SHEET_DATA) public data: any) {
      this.orderHistory = data.order;
   }

   ngOnInit(): void {}

   close() {
      this._bottomSheet.dismiss();
   }

   getProductQuantity = (subItems: CartSubItem[], subItem: CartSubItem, itemQuantity: number = 1): string => {
      const decimalItemsQuantity = subItems.filter((sub) => isDecimal(sub.Quantity) && sub.StepIndex == subItem.StepIndex).length;
      return isDecimal(subItem.Quantity) ? `1/${decimalItemsQuantity}` : (subItem.Quantity * itemQuantity).toString();
   };
}
