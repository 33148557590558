import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
   providedIn: 'root',
})
export class JsonApiService {
   private translationPageCache: BehaviorSubject<{}> = new BehaviorSubject(null);

   constructor(private http: HttpClient) {}

   public get(url, refreshData: boolean = false): Observable<any> {
      if (this.translationPageCache.getValue() !== null && !refreshData) {
         return new Observable<any>((observer) => {
            observer.next(this.translationPageCache.getValue());
         });
      }

      return this.http.get(this.getBaseUrl() + 'assets/api/' + url).pipe(
         map((res) => {
            this.translationPageCache.next(res);
            return this.translationPageCache.getValue();
         })
      );
   }

   private getBaseUrl() {
      return location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '') + '/';
   }
}
