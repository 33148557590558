import { Component, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheet } from '@angular/material/bottom-sheet';
import { User } from '../../models/user.model';
import { ReviewService } from '../../services/review.service';
import { ThemeService } from '../../services/theme.service';
import { UserService } from '../../services/user.service';

@Component({
   selector: 'bottom-sheet-rate',
   templateUrl: './rate.component.html',
   styleUrls: ['./rate.component.scss'],
})
export class RateComponent implements OnInit {
   public rating = 0;
   public orderId: string;
   public rateDescription = '';
   public companyId: number;
   public userInfo: User;
   public ratingArray = [];

   constructor(
      private userService: UserService,
      private reviewService: ReviewService,
      private themeService: ThemeService,
      private _bottomSheet: MatBottomSheet,
      @Inject(MAT_BOTTOM_SHEET_DATA) public data: { companyId: number; orderId?: string }
   ) {
      this.companyId = data.companyId;
      this.orderId = data.orderId;
   }

   ngOnInit(): void {
      this.userInfo = this.userService.getUserInfo();

      if (!this.userInfo.CanRateCompany) {
         this.setColorRate(this.userInfo.Rating);
         for (let i = 1; i <= this.userInfo.Rating; i++) {
            this.ratingArray.push(i);
         }
      }
   }

   close() {
      this._bottomSheet.dismiss();
   }

   saveRating() {
      const userName = this.userInfo.Name || 'Anônimo';

      this.reviewService.save(this.companyId, this.orderId, this.rating, this.rateDescription, userName).subscribe(
         (ret) => {
            if (!this.orderId) {
               this.userService.setCompanyRated(this.rating, this.rateDescription);
            }
            this.close();
         },
         (err) => {
            this.close();
         }
      );
   }

   click($event: any, quantity: number) {
      this.setColorRate(quantity);

      for (let i = 1; i <= 5; i++) {
         const element = document.getElementsByClassName(`star${i}`)[0];
         if (element) element.classList.remove('filled');
      }

      for (let i = 1; i <= quantity; i++) {
         const element = document.getElementsByClassName(`star${i}`)[0];
         if (element) element.classList.add('filled');
      }

      this.rating = quantity;
   }

   mouseEnter($event: any, quantity: number) {
      this.setColorRate(quantity);

      for (let i = 1; i <= quantity; i++) {
         const element = document.getElementsByClassName(`star${i}`)[0];

         if (element) element.classList.add('active');
      }
   }

   mouseLeave($event: any, quantity: number) {
      this.setColorRate(quantity);

      for (let i = 1; i <= 5; i++) {
         const element = document.getElementsByClassName(`star${i}`)[0];

         if (element) element.classList.remove('active');
      }
   }

   setColorRate(quantity) {
      this.themeService.setVariable('--color-rate', '#ffc118');

      /* if (quantity <= 2) {
         this.themeService.setVariable('--color-rate', '#c93737');
      } else if (quantity > 2 && quantity <= 4) {
         this.themeService.setVariable('--color-rate', '#ffc118');
      } else {
         this.themeService.setVariable('--color-rate', '#46a146');
      }*/
   }
}
