<ng-container *ngIf="showModal">
    <div class="modal modal-about" @modal>
        <div class="modal-content">
            <div class="header-modal">
                <i class="fa-solid fa-arrow-left" (click)="close()"></i>
                <div class="title-modal"> {{'About' | i18n: [parameterization.CompanyName]}} </div>
            </div>

            <div class="address">
                <section>
                    <div class="title-section">
                        <i class="fa-light fa-location-dot"></i>
                        {{'Address' | i18n}}
                    </div>
                    {{parameterization.Address?.FullAddress}}
                </section>
            </div>
            <hr style="margin: 30px 0;">
            <section>
                <div class="title-section">
                    <i class="fa-light fa-phone"></i>
                    {{'Phone' | i18n}}
                </div>
                <div class="content-section">
                    {{parameterization.PhoneNumber}}
                </div>
            </section>
            <hr style="margin: 30px 0;">
            <section>
                <div class="title-section">
                    <i class="fa-light fa-clock"></i>
                    {{'BusinessHours' | i18n}}
                </div>
                <div class="week-day" *ngFor="let weekDay of parameterization?.WeekBusinessHours">
                    <div class="week-day-name">
                        {{weekDay.WeekdayName | i18n}}
                    </div>
                    <div class="week-day-start-time">
                        {{weekDay.StartTime}}
                    </div>
                    <span>{{'To' | i18n}}</span>
                    <div class="week-day-end-time">
                        {{weekDay.EndTime}}
                    </div>
                </div>
            </section>
            <hr style="margin: 30px 0;">
            <section *ngIf="parameterization.IsDeliveryEnabled || parameterization.IsPickupEnabled">
                <div class="title-section">
                    {{'Available' | i18n}}
                </div>
                <div class="content-section">
                    <div class="method" *ngIf="parameterization.IsDeliveryEnabled">
                        <i class="fa-light fa-moped"></i>
                        {{'Delivery' | i18n}}
                    </div>
                    <div class="method" *ngIf="parameterization.IsPickupEnabled">
                        <i class="fa-light fa-store"></i>
                        {{'PickUpAtTheStore' | i18n}}
                    </div>
                </div>
            </section>
            <hr style="margin: 30px 0;">
            <section>
                <div class="title-section">
                    <i class="fa-light fa-money-bill"></i>
                    {{'PaymentMethods' | i18n}}
                </div>
                <div class="group-payments">
                    <div *ngFor="let payment of parameterization.PaymentForms">
                        <div class="payment">
                            <img src="assets/images/payment-form/{{payment.Icon}}.png" title="{{payment.Name | i18n}}">
                            <div class="name-payment">
                                {{payment.Name | i18n}}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
    <div (click)="close()" @overlay class="overlay"></div>
</ng-container>