import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

@Injectable({
   providedIn: 'root',
})
export class FriendlyNameGuard implements CanActivate {
   constructor(private router: Router) {}

   canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
      const friendlyUrl = route.paramMap.get('friendly-name');

      if (friendlyUrl && friendlyUrl.trim().length > 0) {
         return true;
      }
      this.redirectToCompanyNotFound();

      return false;
   }

   redirectToCompanyNotFound() {
      this.router.navigate(['empresa-nao-encontrada']);
   }
}
