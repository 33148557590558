import { animate, style, transition, trigger } from '@angular/animations';

const device = checkDevice();

export const transitionAnimation = [
   trigger('overlay', [
      transition(':enter', [style({ opacity: 0 }), animate('500ms', style({ opacity: 0.5 }))]),
      transition(':leave', [animate('500ms', style({ opacity: 0 }))]),
   ]),
   trigger('modal', [
      transition(':enter', [
         style(device ? { left: -999 } : { top: -999 }),
         animate('500ms', style(device ? { left: '50%' } : { top: '0' })),
      ]),
      transition(':leave', [animate('500ms', style(device ? { left: -999 } : { top: -999 }))]),
   ]),
];

function checkDevice() {
   if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
   ) {
      return true;
   } else {
      return false;
   }
}
