<div class="pix-content">
    <div class="group-pix">
        <div class="text-pix">
            <h3>{{'CopyQRCode' | i18n}}</h3>
            <span>{{'CopyQRCodeText' | i18n}}</span>
        </div>

        <img *ngIf="cart.OnlinePayment?.QrCode" loading="lazy"
            src="data:image/jpeg;base64,{{cart.OnlinePayment?.QrCode}}" alt="">

        <div class="copy-and-past" *ngIf="cart.OnlinePayment?.CopyAndPaste">
            {{cart.OnlinePayment?.CopyAndPaste}}
        </div>
    </div>

    <div class="group-buttons-pix" *ngIf="cart.OnlinePayment?.CopyAndPaste">
        <button type="button" [ngClass]="{'awaiting-payment': codeCopied}"
            [disabled]="!cart.OnlinePayment?.CopyAndPaste" (click)="copyCode()">
            {{(codeCopied ? 'AwaitingPayment' : codeCopied && status.toString() === 'Approved' ? 'PaymentApproved' :
            'CopyCode') | i18n}}
        </button>
    </div>
</div>