import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
   providedIn: 'root',
})
export class EventService {
   private picPayOrderChange = new Subject();
   public picPayOrderChanged$ = this.picPayOrderChange.asObservable();
   private pixSendOrder = new Subject();
   public pixOrderSent$ = this.pixSendOrder.asObservable();

   publicPicPayOrderChange(status: 'Cancelado' | 'Pago') {
      this.picPayOrderChange.next(status);
   }

   publishPixOrderSent() {
      this.pixSendOrder.next();
   }
}
