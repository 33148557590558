import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Language } from 'src/app/shared/models/language.model';
import { ThemeOptions } from '../models/theme.model';
import { AddUserAddress, UpdateUser, User, UserAddress, UserSummary } from '../models/user.model';
import { addDays, generateId, getFullAddress, getShortAddress, getShortCityAddress } from '../utils/functions';
import { LocalStorageService } from './local-storage.service';
import { ThemeService } from './theme.service';

@Injectable({ providedIn: 'root' })
export class UserService {
   private user: User;
   private userSummary: UserSummary;

   private userChange = new BehaviorSubject<User>(new User());
   private userSummaryChange = new BehaviorSubject<UserSummary>(new UserSummary());

   public userChanged$ = this.userChange.asObservable();
   public userSummaryChanged$ = this.userSummaryChange.asObservable();

   constructor(private localStorageService: LocalStorageService, private themeService: ThemeService) {
      this.user = this.getUserFromLocalStorage();
      this.mapUserSummary();
      if (!this.user.CanRateCompany) {
         this.user.CanRateCompany = addDays(new Date(this.user.CompanyRatedOn), 1) < new Date();
         this.saveUserOnLocalStorage(false);
      }

      this.themeService.setFontSize(this.user.FontSize);
      this.publishUserChange();
      this.publishUserSummaryChange();
   }

   private getUserFromLocalStorage(): User {
      const user = this.localStorageService.getUserInfo();
      return user || new User();
   }

   private mapUserSummary() {
      this.userSummary = this.user;
   }

   private publishUserChange() {
      this.userChange.next(this.user);
   }

   private publishUserSummaryChange() {
      this.userSummaryChange.next(this.userSummary);
   }

   private saveUserOnLocalStorage(publishChanges: boolean = true) {
      this.localStorageService.setUserInfo(this.user);

      if (publishChanges) {
         this.publishUserChange();
         this.publishUserSummaryChange();
      }
   }

   getUserInfo(): User {
      return this.user;
   }

   getUserSummaryInfo(): UserSummary {
      return this.userSummary;
   }

   update(request: UpdateUser, publishChanges: boolean = true) {
      this.user.Name = request.Name;
      this.user.CellPhone = request.CellPhone;
      this.user.DocumentNumber = request.DocumentNumber;
      this.user.Email = request.Email;
      this.mapUserSummary();
      this.saveUserOnLocalStorage(publishChanges);
   }

   hasDarkModeTheme(): boolean {
      return this.user.IsDarkModeTheme;
   }

   setTheme(theme: ThemeOptions) {
      if (theme) {
         this.user.Theme = theme;
         this.user.IsDarkModeTheme = theme == ThemeOptions.DarkMode;
         this.saveUserOnLocalStorage();
         this.themeService.setTheme(theme);
      }
   }

   setFontSize(size: number) {
      if (size && this.user.FontSize !== size) {
         this.user.FontSize = size;
         this.saveUserOnLocalStorage();
         this.themeService.setFontSize(size);
      }
   }

   setLanguage(language: Language) {
      if (language && this.user.Language?.Key !== language.Key) {
         this.user.Language = language;
         this.saveUserOnLocalStorage();
      }
   }

   setCompanyRated(rating: number, ratingDescription: string) {
      this.user.CompanyRatedOn = new Date();
      this.user.CanRateCompany = false;
      this.user.Rating = rating;
      this.user.RatingDescription = ratingDescription;
      this.saveUserOnLocalStorage();
   }

   setFavoriteAddress(addressId: string) {
      this.user.Addresses?.forEach((item) => {
         item.IsFavorite = item.Id === addressId;
      });
      this.saveUserOnLocalStorage();
   }

   removedFavoriteAddress() {
      this.user.Addresses?.filter((n) => n.IsFavorite).map((ret) => {
         if (ret && ret.Id) {
            ret.IsFavorite = false;
         }
      });
      this.saveUserOnLocalStorage();
   }

   addAddress(request: AddUserAddress): UserAddress {
      const address = this.mapAddAddresTo(request);
      if (!this.user.Addresses) {
         this.user.Addresses = [];
      }
      this.user.Addresses.push(address);
      this.setFavoriteAddress(address.Id);
      return address;
   }

   removeAddress(addressId: string) {
      this.user.Addresses = this.user.Addresses.filter((item) => item.Id !== addressId);
      this.saveUserOnLocalStorage();
   }

   removeAllAddress() {
      this.user.Addresses = [];
      this.saveUserOnLocalStorage();
   }

   // TODO: remover
   getLanguage(): Language {
      return this.user.Language;
   }

   private mapAddAddresTo(request: AddUserAddress): UserAddress {
      const shortAddress = getShortAddress(request);
      const shortCityAddress = getShortCityAddress(request);
      const fullAddress = getFullAddress(request);

      const address: UserAddress = {
         Id: generateId(),
         ...request,
         ShortAddress: shortAddress,
         ShortCityAddress: shortCityAddress,
         FullAddress: fullAddress,
         IsFavorite: true,
      };
      return address;
   }
}
