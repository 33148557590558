import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { OrderHistory } from '../models/order-history.model';
import { LocalStorageService } from './local-storage.service';

@Injectable({
   providedIn: 'root',
})
export class OrderHistoryService {
   private orderHistoryChange = new BehaviorSubject<OrderHistory[]>([]);
   public orderHistoryChanged$ = this.orderHistoryChange.asObservable();

   constructor(private localStorageService: LocalStorageService) {}

   getOrderHistoryIds(): string[] {
      const ids = this.localStorageService.getOrderHistoryIdsNew() || [];
      return ids;
   }

   setOrderHistoryId(id: string) {
      const ids = this.getOrderHistoryIds();
      ids.unshift(id);
      this.localStorageService.setOrderHistoryIdsNew(ids);
   }

   removeOrderHistory() {
      this.localStorageService.removeOrderHistory();
   }
}
