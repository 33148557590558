import { Injectable } from '@angular/core';
import { MetaDefinition } from '@angular/platform-browser';
import chroma from 'chroma-js';
import * as moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { I18nService } from 'src/app/shared/services/i18n.service';
import { DeliveryOrderQueryModel, DeliveryServiceDeliveryOrderResponse } from '../models/delivery-order';
import { DeliveryServiceParameterizationResponse } from '../models/delivery-service-configuration-response';
import {
   DeliveryParameterization,
   DeliveryServicePaymentForm,
   DeliveryServiceProduct,
   ParameterizationAddress,
} from '../models/delivery-service.model';
import { DeliveryServiceDeliveryFeeRequest, DeliveryServiceDeliveryFeeResponse } from '../models/discount-coupon.model';
import { DeliveryServiceOrderHistoryResponse } from '../models/order-history.model';
import { CreateOrder } from '../models/order.model';
import { getFullAddress, getShortAddress, getShortCityAddress } from '../utils/functions';
import { ApiService } from './api.service';
import { DocumentService } from './document.service';
import { ThemeService } from './theme.service';

@Injectable({
   providedIn: 'root',
})
export class DeliveryService {
   private domainName = 'DeliveryService';
   private themeApplyed = new BehaviorSubject(false);
   public onThemeApplyed$ = this.themeApplyed.asObservable();
   private hourFormat = 'HH:mm:ss';

   private productCache: BehaviorSubject<DeliveryServiceProduct[]> = new BehaviorSubject(null);
   private orderHistoryCache: BehaviorSubject<DeliveryServiceOrderHistoryResponse[]> = new BehaviorSubject(null);

   constructor(
      private apiService: ApiService,
      private i18nService: I18nService,
      private documentService: DocumentService,
      private themeService: ThemeService
   ) {}

   getParameterization(friendlyUrl: string): Observable<DeliveryParameterization> {
      return this.apiService.get(`${this.domainName}/Configuration/${friendlyUrl}`).pipe(
         map((parameterization: DeliveryServiceParameterizationResponse) => {
            const result = this.mapToParameterization(parameterization);
            return result;
         })
      );
   }

   private getLanguageCode() {
      const languageCode = this.i18nService.getCurrentLanguageKey();
      return languageCode == 'PT' ? null : languageCode;
   }

   getProducts(companyId: number, refreshData: boolean = false): Observable<DeliveryServiceProduct[]> {
      const languageCode = this.getLanguageCode();

      if (this.productCache.getValue() !== null && !refreshData) {
         return new Observable<any>((observer) => {
            observer.next(this.productCache.getValue());
         });
      }

      return this.apiService.post(`${this.domainName}/Products`, { companyId, languageCode }).pipe(
         map((res) => {
            this.productCache.next(res);
            return this.productCache.getValue();
         })
      );
   }

   getDeliveryFeeInfo(
      deliveryServiceDeliveryFeeRequest: DeliveryServiceDeliveryFeeRequest
   ): Observable<DeliveryServiceDeliveryFeeResponse> {
      return this.apiService.post(`${this.domainName}/Delivery`, deliveryServiceDeliveryFeeRequest);
   }

   getDeliveryPaymentForms(parameterization: DeliveryServiceParameterizationResponse): DeliveryServicePaymentForm[] {
      const paymentForms: DeliveryServicePaymentForm[] = [];

      if (parameterization.IsPaymentFormMoneyEnabled) {
         paymentForms.push({ Name: 'Money', OriginalName: 'Dinheiro', Icon: 'money', AllowChangeValue: true, IsPrepaid: false });
      }
      if (parameterization.IsPaymentFormCreditEnabled) {
         paymentForms.push({ Name: 'Credit', OriginalName: 'Credito', Icon: 'card', AllowChangeValue: false, IsPrepaid: false });
      }
      if (parameterization.IsPaymentFormDebitEnabled) {
         paymentForms.push({ Name: 'Debit', OriginalName: 'Debito', Icon: 'card', AllowChangeValue: false, IsPrepaid: false });
      }
      if (parameterization.IsPaymentFormVoucherEnabled) {
         paymentForms.push({ Name: 'Ticket', OriginalName: 'Ticket', Icon: 'ticket', AllowChangeValue: false, IsPrepaid: false });
      }
      if (parameterization.IsPaymentFormPixEnabled || parameterization.IsPaymentFormOnlinePixEnabled) {
         paymentForms.push({
            Name: 'Pix',
            OriginalName: 'Pix',
            Icon: 'pix',
            AllowChangeValue: false,
            IsPrepaid: parameterization.IsPaymentFormOnlinePixEnabled,
         });
      }

      return paymentForms;
   }

   //--------------------------------

   getDeliveryOrderList(orderIds: string[]): Observable<DeliveryServiceDeliveryOrderResponse[]> {
      const languageCode = this.getLanguageCode();
      return this.apiService.post(`${this.domainName}/Order/List`, { orderIds, languageCode });
   }

   getDeliveryOrderDetails(orderId: string): Observable<DeliveryOrderQueryModel> {
      const languageCode = this.getLanguageCode();
      return this.apiService.post(`${this.domainName}/Order/Details`, { orderId, languageCode });
   }

   getDeliveryOrderHistory(
      companyId: number,
      orderIds: string[],
      refreshData: boolean = false
   ): Observable<DeliveryServiceOrderHistoryResponse[]> {
      if (this.orderHistoryCache.getValue() !== null && !refreshData) {
         return new Observable<any>((observer) => {
            observer.next(this.orderHistoryCache.getValue());
         });
      }

      return this.apiService.post(`${this.domainName}/Order/History`, { companyId, orderIds }).pipe(
         map((res) => {
            this.orderHistoryCache.next(res);
            return this.orderHistoryCache.getValue();
         })
      );
   }

   saveOrder(order: CreateOrder): Observable<any> {
      return this.apiService.put(`${this.domainName}/Order`, order);
   }

   private storeIsOpen(parameterization: DeliveryServiceParameterizationResponse): boolean {
      let storeIsOpen: boolean;
      const weekDay = moment().format('dddd');

      if (parameterization.WeekBusinessHours) {
         const weekBusinessDay = parameterization.WeekBusinessHours.filter((n) => n.WeekdayName.toLowerCase() === weekDay.toLowerCase());
         const now = moment();

         if (weekBusinessDay.length > 0) {
            storeIsOpen = weekBusinessDay.some((n) => {
               const start = moment(n.StartTime, this.hourFormat);
               const end = moment(n.EndTime, this.hourFormat);

               if (end.get('hour') < start.get('hour')) {
                  return now.isBetween(start, end.add(1, 'days'), null, '[]');
               } else {
                  return now.isBetween(start, end, null, '[]');
               }
            });
         }

         if (!storeIsOpen) {
            const yesterday = moment().subtract(1, 'days').format('dddd');

            const weekBusinessDay = parameterization.WeekBusinessHours.filter(
               (n) =>
                  n.WeekdayName.toLowerCase() === yesterday.toLowerCase() &&
                  moment(n.EndTime, this.hourFormat).get('hour') < moment(n.StartTime, this.hourFormat).get('hour')
            );

            if (weekBusinessDay.length > 0) {
               storeIsOpen = weekBusinessDay.some((n) => {
                  const start = moment(n.StartTime, this.hourFormat).subtract(1, 'days');
                  const end = moment(n.EndTime, this.hourFormat);

                  return now.isBetween(start, end, null, '[]');
               });
            }
         }

         if (parameterization.OrderCenterConfigInactivityLimitDate && now.isBefore(parameterization.OrderCenterConfigInactivityLimitDate)) {
            const inactivityLimitDate = moment(parameterization.OrderCenterConfigInactivityLimitDate);
            return now.isAfter(inactivityLimitDate);
         }
      }

      return storeIsOpen;
   }

   private mapToParameterization(parameterization: DeliveryServiceParameterizationResponse): DeliveryParameterization {
      const storeIsOpen = this.storeIsOpen(parameterization);
      const paymentForms = this.getDeliveryPaymentForms(parameterization);

      const address = this.getAddress(parameterization);

      return {
         CompanyId: parameterization.CompanyId,
         CompanyName: parameterization.CompanyName,
         CompanyLogoUrl: parameterization.CompanyLogoUrl,
         FriendlyUrl: parameterization.FriendlyUrl,
         PrimaryColor: parameterization.PrimaryColor,
         SecondaryColor: parameterization.SecondaryColor,
         TextColor: parameterization.TextColor,
         PhoneNumber: parameterization.PhoneNumber,
         EstimatedTimeToPrepare: parameterization.EstimatedTimeToPrepare,
         OrderMinValue: parameterization.OrderMinValue,
         OrderMinValueForFreeDeliveryFee: parameterization.OrderMinValueForFreeDeliveryFee,
         Address: address,
         PaymentForms: paymentForms,
         WeekBusinessHours: parameterization.WeekBusinessHours,
         IsPaymentFormPicPayEnabled: parameterization.IsPaymentFormPicPayEnabled,
         PicPayToken: parameterization.PicPayToken,
         PicPaySellerToken: parameterization.PicPaySellerToken,
         PixKey: parameterization.PixKey,
         FacebookPixelId: parameterization.FacebookPixelId,
         IsFacebookPixelEnabled: parameterization.FacebookPixelId && parameterization.FacebookPixelId.length > 0,
         IsGoogleAnalyticEnabled: parameterization.IsGoogleAnalyticEnabled,
         GoogleMetricId: parameterization.GoogleMetricId,
         IsCustomerCpfEnabled: parameterization.IsCustomerCpfEnabled,
         IsDeliveryEnabled: parameterization.IsDeliveryEnabled,
         IsEnabled: parameterization.IsEnabled,
         IsPickupEnabled: parameterization.IsPickupEnabled,
         IsPaymentFormOnlinePixEnabled: parameterization.IsPaymentFormOnlinePixEnabled,
         IsWelcomePageEnabled: parameterization.IsWelcomePageEnabled,
         WelcomeMessage: parameterization.WelcomeMessage,
         StoreIsOpen: storeIsOpen,
         CanReceiveOrders: parameterization.CanReceiveOrders,
         OrderCenterConfigInactivityLimitDate: parameterization.OrderCenterConfigInactivityLimitDate,
         OrderCenterConfigSendOrderToMessageServiceApp: parameterization.OrderCenterConfigSendOrderToMessageServiceApp,
         OrderCenterConfigIsDeliveryServiceEnabled: parameterization.OrderCenterConfigIsDeliveryServiceEnabled,
      };
   }

   private getAddress(parameterization: DeliveryServiceParameterizationResponse): ParameterizationAddress {
      let address = new ParameterizationAddress(
         parameterization.AddressLine1,
         parameterization.AddressLine2,
         parameterization.AddressNumber,
         parameterization.AddressNeighborhood,
         parameterization.AddressStateName,
         parameterization.AddressCityName,
         parameterization.AddressZipCode,
         parameterization.AddressLatitude,
         parameterization.AddressLongitude
      );

      address.ShortAddress = getShortAddress(address);
      address.ShortCityAddress = getShortCityAddress(address);
      address.FullAddress = getFullAddress(address);

      return address;
   }

   setAppParameterization(parameterization: DeliveryParameterization) {
      if (parameterization.PrimaryColor !== null && parameterization.PrimaryColor && parameterization.PrimaryColor.length > 0) {
         this.themeService.setVariable('--primary-color', parameterization.PrimaryColor);
         let color = chroma(parameterization.PrimaryColor);
         if (chroma(parameterization.PrimaryColor).luminance() < 0.5) {
            this.themeService.setVariable('--text-primary-color', color.brighten(6));
         } else {
            this.themeService.setVariable('--text-primary-color', color.darken(6));
         }
      }
      if (parameterization.SecondaryColor !== null && parameterization.SecondaryColor && parameterization.SecondaryColor.length > 0) {
         this.themeService.setVariable('--secondary-color', parameterization.SecondaryColor);
         let color = chroma(parameterization.SecondaryColor);
         if (chroma(parameterization.SecondaryColor).luminance() < 0.5) {
            this.themeService.setVariable('--text-title-category', color.brighten(6));
         } else {
            this.themeService.setVariable('--text-title-category', color.darken(6));
         }
      }
      if (parameterization.TextColor !== null && parameterization.TextColor && parameterization.TextColor.length > 0) {
         this.themeService.setVariable('--text-color', parameterization.TextColor);
      }
      this.documentService.setDocumentTitle(parameterization.CompanyName);
      const ogTitle: MetaDefinition = { property: 'og:title', content: parameterization.CompanyName };
      this.documentService.updateMetaTag(ogTitle, true);
      if (parameterization.CompanyLogoUrl) {
         const logo: MetaDefinition = { property: 'og:image', content: parameterization.CompanyLogoUrl };
         this.documentService.updateMetaTag(logo, true);
      }
      this.themeApplyed.next(true);
   }

   overflowService(overflow: boolean = true) {
      const $html = document.querySelector('html');
      if (overflow) {
         $html.style.overflow = 'auto';
      } else {
         $html.style.overflow = 'hidden';
      }
   }
}
