import { Injectable } from '@angular/core';
import { NotificationService } from '../services/notification.service';

@Injectable({
   providedIn: 'root',
})
export class FunctionService {
   constructor(private notificationService: NotificationService) {}

   guid() {
      function s4() {
         return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
      }
      return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
   }

   areEqual(a, b) {
      const type1 = this.toType(a);
      const type2 = this.toType(b);
      const val1 = this.setDefaultValue(type1, a);
      const val2 = this.setDefaultValue(type2, b);
      return val1 === val2;
   }

   toType(obj) {
      return {}.toString
         .call(obj)
         .match(/\s([a-zA-Z]+)/)[1]
         .toLowerCase();
   }

   setDefaultValue(type, value): string {
      switch (type) {
         case 'boolean':
            value = value === false ? '' : value;
            break;
         case 'number':
            value = value === 0 ? '' : value;
            break;
         case 'null':
         case 'undefined':
            value = '';
            break;
         default:
            value = value.toString().trim();
            break;
      }

      return value.toString();
   }

   isMobile() {
      const ua = navigator.userAgent;
      const opts = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini|mobile|mobile|crios|windows phone/i;
      return opts.test(ua.toLowerCase());
   }

   copyTextToClipboard(text: string, confirmMessage: string = null) {
      const el = document.createElement('textarea');
      el.value = text;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);

      if (confirmMessage) {
         this.notificationService.showSuccessMessage(confirmMessage);
      }
   }
}
