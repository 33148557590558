import { WeekBusinessHours } from './delivery-service-configuration-response';

export class DeliveryParameterization {
   CompanyId: number;
   CompanyName: string;
   CompanyLogoUrl: string;
   FriendlyUrl: string;

   PrimaryColor: string;
   SecondaryColor: string;
   TextColor: string;

   PhoneNumber: string;
   EstimatedTimeToPrepare: number;
   OrderMinValue: number;
   OrderMinValueForFreeDeliveryFee?: number;

   Address: ParameterizationAddress;
   PaymentForms: DeliveryServicePaymentForm[];
   WeekBusinessHours: WeekBusinessHours[];

   IsPaymentFormPicPayEnabled: boolean;
   PicPayToken: string;
   PicPaySellerToken: string;
   PixKey: string;
   FacebookPixelId: string;
   IsFacebookPixelEnabled: boolean;
   IsGoogleAnalyticEnabled: boolean;
   GoogleMetricId: string;

   IsCustomerCpfEnabled: boolean;
   IsDeliveryEnabled: boolean;
   IsEnabled: boolean;
   IsPickupEnabled: boolean;
   IsPaymentFormOnlinePixEnabled: boolean;
   IsWelcomePageEnabled: boolean;
   WelcomeMessage: string;

   StoreIsOpen: boolean;
   CanReceiveOrders: boolean;

   OrderCenterConfigInactivityLimitDate: string;
   OrderCenterConfigSendOrderToMessageServiceApp: boolean;
   OrderCenterConfigIsDeliveryServiceEnabled: boolean;

   constructor() {
      this.PaymentForms = [];
   }
}

export class ParameterizationAddress {
   Id: string;
   FullAddress: string;
   ShortAddress: string;
   ShortCityAddress: string;
   StreetName: string;
   Complement: string;
   StreetNumber: string;
   Reference: string;
   Neighborhood: string;
   State: string;
   City: string;
   PostalCode: string;
   Country: string;
   Latitude?: number;
   Longitude?: number;
   HasCoordinates: boolean;

   constructor(
      addressLine1: string,
      addressLine2: string,
      addressNumber: string,
      addressNeighborhood: string,
      addressStateName: string,
      addressCityName: string,
      addressZipCode: string,
      addressLatitude?: number,
      addressLongitude?: number
   ) {
      this.StreetName = addressLine1;
      this.Complement = addressLine2;
      this.StreetNumber = addressNumber;
      this.Neighborhood = addressNeighborhood;
      this.State = addressStateName;
      this.City = addressCityName;
      this.PostalCode = addressZipCode;
      this.Latitude = addressLatitude;
      this.Longitude = addressLongitude;
      this.HasCoordinates = this.Latitude && this.Latitude != 0 && this.Longitude && this.Longitude != 0;
      this.Country = 'BRA';
   }
}

export class DeliveryServiceProduct {
   Id: string;
   Order: number;
   Name: string;
   OriginalName: string;
   AdditionalInfo: string;
   OriginalAdditionalInfo: string;
   MainBarcode: string;
   ProductUnitAcronym: string;
   ProductUnitCanBeFractioned: boolean;
   ProductIsCombo: boolean;
   MarketingClassificationId: string;
   MarketingClassificationName: string;
   OriginalMarketingClassificationName: string;
   CategoryOrder: number;
   CategoryName: string;
   OriginalCategoryName: string;
   CostValue: number;
   SellValue: number;
   ImageUrl: string;
   SmallImageUrl: string;
   IsProductsAgeRestricted: boolean;
   MaxQuantityOnSale: number;
   ServesHowManyPeople: number;
   ProductStepMinValue: number;
   ProductStepMaxValue: number;
   ProductSteps: DeliveryServiceProductStep[];
   CompositionMembers: DeliveryServiceProduct[];
}

export class DeliveryServiceProductStep {
   Id: string;
   Position: number;
   Name: string;
   Quantity: number;
   OriginalName: string;
   MinQuantity: number;
   MaxQuantity: number;
   MinValue: number;
   MaxValue: number;
   CanRepeatSameItem: boolean;
   IsFractionItems: boolean;
   ValueCalculationType: number;
   StepItems: DeliveryServiceProductStepItem[];
   ProductStepSelectedItemsCount: number;
   ProductSelectedIndex: string;
   StepIndex: string;

   constructor() {
      this.ProductStepSelectedItemsCount = 1;
      this.Quantity = 0;
   }
}

export class DeliveryServiceProductStepItem {
   Id: string;
   Position: number;
   ProductId: string;
   ProductName: string;
   OriginalProductName: string;
   AdditionalInfo: string;
   OriginalAdditionalInfo: string;
   MainBarcode: string;
   ProductTypeName: string;
   MarketingClassificationId: string;
   MarketingClassificationName: string;
   OriginalMarketingClassificationName: string;
   SellPrice: number;
   CanBeFractioned: boolean;
   ImageUrl: string;
   SmallImageUrl: string;

   Quantity: number;
   QuantityToShow?: number;
   GrossValue: number;
   TotalValue?: number;
   DiscountValue: number;
   AdditionValue: number;
   StepIndex?: number;

   constructor() {
      this.Quantity = 0;
      this.TotalValue = 0;
      this.AdditionValue = 0;
      this.DiscountValue = 0;
   }
}

export class GroupedDeliveryServiceProduct {
   public CategoryName: string;
   public Products: DeliveryServiceProduct[];
}

export class DeliveryServicePaymentForm {
   public Name: string;
   public OriginalName: string;
   public Icon: string;
   public IsPrepaid: boolean;
   public AllowChangeValue: boolean;

   constructor() {
      this.IsPrepaid = false;
      this.AllowChangeValue = false;
   }
}
