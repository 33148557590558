import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { DeliveryService } from '../../services/delivery-service.service';
import { transitionAnimation } from '../../utils/animations';

@Component({
   selector: 'modal-success',
   templateUrl: './success.component.html',
   styleUrls: ['./success.component.scss'],
   animations: [transitionAnimation],
})
export class SuccessComponent implements OnInit {
   public showModal = false;
   public friendlyUrl: string;
   public appVersion = environment.version;

   @HostListener('window:popstate', ['$event'])
   onPopState(event: Event) {
      if (this.showModal) {
         this.close();
      }
   }

   constructor(private deliveryService: DeliveryService, private router: Router) {}

   ngOnInit(): void {}

   open(friendlyUrl: string) {
      this.friendlyUrl = friendlyUrl;
      this.showModal = true;

      this.deliveryService.overflowService(false);

      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);

      this.redirectToOrders();
   }

   close() {
      this.deliveryService.overflowService(true);
      this.redirectToOrderHistory();
      this.showModal = false;
   }

   redirectToOrders() {
      setTimeout(() => {
         this.close();
      }, 5000);
   }

   redirectToOrderHistory() {
      this.router.navigate([`${this.friendlyUrl}/pedidos`]);
   }
}
