<div class="header-bottom-sheet">
    <i class="fa-light fa-xmark" (click)="close()"></i>
</div>
<div class="order-detail-content">
    <div class="top">
        <div class="code">#{{orderHistory.OrderCode}}</div>
        <div class="date">{{orderHistory.DeliveryDate | date: 'dd/MM/yyyy HH:mm'}}</div>
    </div>
    <!--items-->
    <section class="group-section">
        <div class="title-section">{{'OrderDetails' | i18n}}</div>
        <div class="group-item">
            <div *ngFor="let item of orderHistory.Items">
                <div class="item">
                    <div class="description-item">
                        <div class="quantity">{{item.Quantity}} x </div>
                        <div class="name">{{item.ProductName}}</div>
                    </div>
                    <div class="value-item">{{item.TotalValue | money}}</div>
                </div>
                <div *ngIf="item.SubItems && item.SubItems.length > 0">
                    <div class="sub-item" *ngFor="let sub of item.SubItems">
                        <div class="description-sub-item" *ngIf="sub.Quantity > 0">

                            <div class="quantity">{{ item.IsCombo ? '&bull; ' : '+ '
                                }}{{getProductQuantity(item.SubItems,
                                sub, item.Quantity)}} x </div>
                            <div class="name">{{sub.ProductName}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="label-title">
            <p>{{'DeliveryFee' | i18n}}</p>
            <div class="value">{{orderHistory.DeliveryFee > 0 ? (orderHistory.DeliveryFee | money) :
                orderHistory.DeliveryFee == 0 && orderHistory.DeliveryFeeCustomerMessage &&
                orderHistory.DeliveryFeeCustomerMessage.length > 0 ? orderHistory.DeliveryFeeCustomerMessage : ('Free' |
                i18n)}}
            </div>
        </div>
        <div class="label-title">
            <p>{{'TotalDetailOrder' | i18n}}</p>
            <div class="value">{{orderHistory.TotalValue | money}}</div>
        </div>
    </section>
    <!--payment-form-->
    <section class="group-section">
        <div class="title-section">{{'Payment' | i18n}}</div>
        <div *ngFor="let payment of orderHistory.PaymentForms">
            <div class="label-title">
                <p>{{'PaymentForm' | i18n}}</p>
                <div class="value">{{payment.Name | i18n}}</div>
            </div>
            <div class="label-title">
                <p>{{'PaymentValue' | i18n}}</p>
                <div class="value">{{payment.Value | money}}</div>
            </div>
            <div class="label-title" *ngIf="payment.ChangeValue > 0">
                <p>{{'ChangeTo' | i18n}}</p>
                <div class="value">{{payment.GrossValue | money}}</div>
            </div>
            <div class="label-title" *ngIf="payment.ChangeValue > 0">
                <p>{{'ChangeValueOrder' | i18n}}</p>
                <div class="value">{{payment.ChangeValue | money}}</div>
            </div>
            <div class="label-title" *ngIf="payment.ReferenceId && payment.ReferenceId.length > 0">
                <p>{{'Cód. Ref.:' | i18n}}</p>
                <div class="value">{{payment.ReferenceId}}</div>
            </div>
        </div>
    </section>
    <!--customer-->
    <section class="group-section">
        <div class="title-section">{{'Customer' | i18n}}</div>
        <div class="label-title">
            <p>{{'Name' | i18n}}</p>
            <div class="value">{{orderHistory.CustomerInfo.Name}}</div>
        </div>
        <div class="label-title">
            <p>{{'DocumentNumber' | i18n}}</p>
            <div class="value">{{orderHistory.CustomerInfo.Cpf ? orderHistory.CustomerInfo.Cpf : '-'}}</div>
        </div>
        <div class="label-title">
            <p>{{'Phone' | i18n}}</p>
            <div class="value">{{orderHistory.CustomerInfo.Phone}}</div>
        </div>
        <div class="label-title">
            <p>{{'Email' | i18n}}</p>
            <div class="value">{{orderHistory.CustomerInfo.Email ? orderHistory.CustomerInfo.Email : '-'}}</div>
        </div>
    </section>
    <!--address-->
    <section class="group-section" *ngIf="orderHistory.IsDelivery">
        <div class="title-section">{{'Address' | i18n}}</div>
        <div class="address">
            <p>{{orderHistory.CustomerInfo.FullAddress}}</p>
        </div>
    </section>
    <section class="group-section" style="text-align: center;"
        *ngIf="orderHistory.MediatorCode && orderHistory.MediatorCode.length > 0">
        <div style="margin-top: 10px;">
            <p>{{('Processado por ' + orderHistory.MediatorCode) | i18n}}</p>
        </div>
    </section>
</div>