<ng-container *ngIf="showModal">
    <div class="modal modal-order-history" @modal>
        <div class="resume-content">
            <div class="header-modal">
                <i class="fa-solid fa-arrow-left" (click)="close()"></i>
                <div class="title-modal"> {{'MyOrders' | i18n}} </div>
            </div>
            <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
                <mat-tab label="{{'InProgress' | i18n}}">
                    <kcms-card-order [parameterization]="parameterization"
                        [orderHistory]="doingItems"></kcms-card-order>
                </mat-tab>
                <mat-tab label="{{'Previous' | i18n}}">
                    <kcms-card-order [parameterization]="parameterization" [isRepeat]="true" [isReview]="true"
                        [orderHistory]="finishedItems"></kcms-card-order>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
    <div (click)="close()" @overlay class="overlay"></div>
</ng-container>