import { Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { DeliveryParameterization } from 'src/app/core/models/delivery-service.model';
import { AddUserAddress } from 'src/app/core/models/user.model';
import { AddressService } from 'src/app/core/services/address.service';

@Component({
   selector: 'kcms-address-autocomplete',
   templateUrl: './address-autocomplete.component.html',
   styleUrls: ['./address-autocomplete.component.scss'],
})
export class AddressAutocompleteComponent implements OnInit, OnDestroy {
   public searchAddress: string = null;
   public suggestions: any[];
   public isZeroResults = false;
   public sessionToken: any;
   public timeoutAddressAutocomplete: any;
   public userAddress = new AddUserAddress();

   @Input() parameterization: DeliveryParameterization;
   @Input() placeholder = 'SearchAddressAutocomplete';

   @Output() placeChanged = new EventEmitter<AddUserAddress>();

   constructor(private zone: NgZone, private addressService: AddressService) {}

   ngOnInit() {}

   autocompleteChanged() {
      this.userAddress.Latitude = null;
      this.userAddress.Longitude = null;

      this.isZeroResults = false;
      clearTimeout(this.timeoutAddressAutocomplete);

      this.timeoutAddressAutocomplete = setTimeout(() => {
         if (this.searchAddress && this.searchAddress.length > 3) {
            if (!this.sessionToken) {
               this.sessionToken = new google.maps.places.AutocompleteSessionToken();
            }

            const bounds = this.getBounds();

            let opts: google.maps.places.AutocompletionRequest = {
               input: this.searchAddress,
               sessionToken: this.sessionToken,
               types: ['address'],
               componentRestrictions: {
                  country: 'br',
               },
               bounds: bounds,
            };

            const autocompleteService = new google.maps.places.AutocompleteService();
            autocompleteService.getPlacePredictions(opts, (predictions, status) => {
               if (status !== google.maps.places.PlacesServiceStatus.OK) {
                  if (status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
                     this.isZeroResults = true;
                  } else {
                     return;
                  }
               }

               this.zone.run(() => {
                  this.suggestions = predictions;
               });
            });
         } else {
            this.suggestions = null;
         }
      }, 1000);
   }

   addressClicked(placeId: any) {
      const placesService = new google.maps.places.PlacesService(document.createElement('div'));
      placesService.getDetails(
         {
            placeId: placeId,
            sessionToken: this.sessionToken,
            fields: ['formatted_address', 'address_component', 'geometry'],
         },
         (place: google.maps.places.PlaceResult, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
               this.zone.run(() => {
                  this.addressChanged(place);
               });
            }
            this.sessionToken = null;
         }
      );
   }
   addressChanged(address: google.maps.places.PlaceResult) {
      const convertedAddress = this.addressService.transform(address);

      if (!this.userAddress) {
         this.userAddress = new AddUserAddress();
      }

      if (convertedAddress) {
         this.userAddress.PostalCode = convertedAddress.PostalCode;
         this.userAddress.StreetName = convertedAddress.StreetName;
         this.userAddress.StreetNumber = convertedAddress.StreetNumber;
         this.userAddress.Neighborhood = convertedAddress.Neighborhood;
         this.userAddress.City = convertedAddress.City;
         this.userAddress.State = convertedAddress.State;
         this.userAddress.Latitude = convertedAddress.Latitude;
         this.userAddress.Longitude = convertedAddress.Longitude;
         this.placeChanged.emit(this.userAddress);
         // if (this.userAddress.AddressNumber) {
         //    this.mapComponent.open();
         // } else {
         //    this.addressNumberComponent.open(this.userAddress);
         // }
      } else {
         this.userAddress = new AddUserAddress();
      }
   }

   // private initAutoComplete() {
   //    const bounds = this.getBounds();

   //    let opts: google.maps.places.AutocompleteOptions = {
   //       types: ['address'],
   //       fields: ['formatted_address', 'address_components', 'geometry.location'],
   //       componentRestrictions: {
   //          country: 'br',
   //       },
   //       bounds: bounds,
   //       strictBounds: this.parameterization.Address?.HasCoordinates,
   //    };

   //    this.autocomplete = new google.maps.places.Autocomplete(this.inputField.nativeElement, opts);

   //    this.autocomplete.addListener('place_changed', () => {
   //       this.zone.run(() => {
   //          const place = this.autocomplete?.getPlace();
   //          if (!place?.geometry) {
   //             // console.log(place);
   //          } else {
   //             const result = this.addressService.transform(place);
   //             this.placeChanged.emit(result);
   //          }
   //       });
   //    });
   // }

   private getBounds(): google.maps.LatLngBoundsLiteral {
      if (this.parameterization.Address?.HasCoordinates) {
         const bounds = this.addressService.getBoundsLiteral(
            this.parameterization.Address.Latitude,
            this.parameterization.Address.Longitude,
            0.4
         );
         return bounds;
      }
      return null;
   }

   ngOnDestroy() {
      // if (this.autocomplete) {
      //    google.maps.event.clearInstanceListeners(this.autocomplete);
      // }
   }
}
