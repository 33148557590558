<footer class="footer-toolbar">
   <div class="item-toolbar" routerLink="/{{friendlyUrl}}/produtos">
      <i class="fa-light fa-book-open-cover"></i>
      {{'Menu' | i18n}}
   </div>
   <div class="item-toolbar" routerLink="/{{friendlyUrl}}/pedidos">
      <i class="fa-light fa-memo"></i>
      {{'Orders' | i18n}}
   </div>
   <!-- <div class="item-toolbar" routerLink="/{{friendlyUrl}}/perfil">
      <i class="fa-light fa-user"></i>
      {{'Profile' | i18n}}
   </div> -->
   <!-- <div class="item-toolbar" routerLink="/{{friendlyUrl}}/finalizarpedido">
      <i class="fa-light fa-bag-shopping"></i>
      {{'Checkout' | i18n}}
   </div> -->
</footer>