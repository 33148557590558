import { Injectable } from '@angular/core';
import { TrackAddToCart, TrackInitiateCheckout, TrackViewContent } from '../models/tracking.model';

declare let fbq: Function;

@Injectable({
   providedIn: 'root',
})
export class FacebookPixelService {
   facebookTagCreated: boolean;
   facebookPixelId: string;

   constructor() {}

   setScriptTag(pixelId: string) {
      if (pixelId) {
         const template = this.getFacebookPixelTagTemplate(pixelId);
         this.addFacebookPixelTag(template);
         this.facebookTagCreated = true;
         this.facebookPixelId = pixelId;
      }
   }

   trackInitiateCheckout(pixelId: string, param: TrackInitiateCheckout) {
      if (!this.facebookTagCreated && pixelId) {
         this.setScriptTag(pixelId);
      }

      if (this.facebookTagCreated) {
         fbq('track', 'InitiateCheckout', {
            content_ids: param.ContentIds,
            value: param.TotalValue ? parseFloat(param.TotalValue.toFixed(2)) : 0.0,
            num_items: param.ProductsCount,
            currency: 'BRL',
            content_name: param.ProductNameList,
         });
      }
   }

   trackPageView(pixelId: string, url: string) {
      if (!this.facebookTagCreated && pixelId) {
         this.setScriptTag(pixelId);
      }

      if (this.facebookTagCreated) {
         fbq('track', 'PageView', { url: url });
      }
   }

   trackPurchase(pixelId: string, totalValue: number) {
      if (!this.facebookTagCreated && pixelId) {
         this.setScriptTag(pixelId);
      }

      if (this.facebookTagCreated) {
         fbq('track', 'Purchase', {
            currency: 'BRL',
            value: totalValue ? parseFloat(totalValue.toFixed(2)) : 0.0,
         });
      }
   }

   trackAddToCart(pixelId: string, param: TrackAddToCart) {
      if (!this.facebookTagCreated && pixelId) {
         this.setScriptTag(pixelId);
      }

      if (this.facebookTagCreated) {
         fbq('track', 'AddToCart', {
            currency: 'BRL',
            content_ids: param.ContentIds,
            value: param.TotalValue ? parseFloat(param.TotalValue.toFixed(2)) : 0.0,
            content_name: param.ProductName,
         });
      }
   }

   trackViewContent(pixelId: string, param: TrackViewContent) {
      if (!this.facebookTagCreated && pixelId) {
         this.setScriptTag(pixelId);
      }

      if (this.facebookTagCreated) {
         fbq('track', 'ViewContent', {
            currency: 'BRL',
            value: param.ProductValue ? parseFloat(param.ProductValue.toFixed(2)) : 0.0,
            content_name: param.ProductName,
            content_category: param.CategoryName,
         });
      }
   }

   getFacebookPixelTagTemplate(pixelId: string) {
      return `
    !function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '${pixelId}');`;
   }

   addFacebookPixelTag(template: string) {
      let node = document.createElement('script');
      node.text = template;
      node.type = 'text/javascript';
      node.async = true;
      document.getElementsByTagName('head')[0].appendChild(node);
   }
}
