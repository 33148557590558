import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
   providedIn: 'root',
})
export class ReviewService {
   private domainName = 'Review';

   constructor(private apiService: ApiService) {}

   save(companyId: number, orderId: string, rating: number, reviewRating: string, createdBy: string) {
      return this.apiService.put(`${this.domainName}`, {
         orderId,
         rating,
         reviewRating,
         origin: 'delivery',
         companyId,
         createdBy,
      });
   }
}
