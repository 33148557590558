<ng-container *ngIf="showModal && orderItem">
  <div class="modal modal-detail-products" @modal>
    <div class="img-product-zoom" *ngIf="orderItem.ImageUrl">
      <div class="image-zoom">
        <img loading="lazy" src="{{ urlImageToZoom }}" alt="" />
        <i class="fa-regular fa-xmark btn-close-image-zoom" (click)="handleImageZoom(false)"></i>
      </div>
    </div>
    <div class="back" (click)="close()">
      <i class="fa-regular fa-arrow-left"></i>
    </div>
    <div class="product-content">
      <div class="img-product" *ngIf="orderItem.ImageUrl" (click)="handleImageZoom(true, orderItem.ImageUrl)">
        <img loading="lazy" src="{{ orderItem.ImageUrl }}" alt="{{ orderItem.ProductName }}" />
        <i class="fa-light fa-magnifying-glass-plus"></i>
      </div>
      <div class="group-detail-product">
        <div class="detail-product" [ngClass]="{'no-image' : !orderItem.ImageUrl}">
          <div *ngIf="orderItem.IsProductsAgeRestricted" class="warning-18">
            <i class="fa-duotone fa-triangle-exclamation"></i>
            {{'AgeRestricted' | i18n}}
          </div>
          <div class="group-product-name">
            <div class="product-name">
              {{ orderItem.ProductName }}
            </div>

          </div>
          <div class="product-additional-inf">
            {{ orderItem.AdditionalInfo }}
          </div>
          <div class="group-value-item">
            <div *ngIf="!orderItem.IsComposition" class="product-sell-value">
              {{ orderItem.IsCombo ? ('From' | i18n) : "" }}
              {{ (orderItem.IsCombo ? orderItem.ProductStepMinValue : orderItem.SellValue) |
              money }}
            </div>
            <div class="product-server-many" *ngIf="orderItem.ServesHowManyPeople > 0">
              {{(orderItem.ServesHowManyPeople > 1 ?'ServesHowManyPeoplePlural':'ServesHowManyPeople' ) | i18n :
              [orderItem.ServesHowManyPeople]}}
            </div>
          </div>
        </div>
        <!--Combo------------------>
        <div *ngIf="orderItem.ProductSteps && orderItem.ProductSteps.length > 0">
          <section *ngFor="let step of orderItem.ProductSteps">
            <div class="title-section" [id]="'step-index-' + step.Position">
              <div class="left">
                <span>{{ step.Name }}</span>
                <p>{{'Of' | i18n: [step.Quantity, step.MaxQuantity]}}</p>
              </div>
              <div class="rigth">
                <div *ngIf="step.MinQuantity > 0 && step.Quantity !== step.MaxQuantity" class="required">
                  {{'Required' | i18n}}
                </div>
                <i class="fa-duotone fa-check-double checked" *ngIf="step.Quantity === step.MaxQuantity"></i>
              </div>
            </div>
            <div class="group-add-items" *ngIf="step.MaxQuantity > 1; else productRadio">
              <div class="items-to-add" *ngFor="let item of step.StepItems">
                <div class="group-info-products">
                  <img *ngIf="item.SmallImageUrl" loading="lazy" [src]="item.SmallImageUrl" [alt]="item.ProductName"
                    (click)="handleImageZoom(true, item.ImageUrl)" srcset="">
                  <div class="list-item">
                    {{ item.ProductName }}
                    <div class="additional-info">
                      {{item.AdditionalInfo}}
                    </div>
                    <span>{{ item.SellPrice | money }}</span>
                  </div>
                </div>
                <div class="btn-more-minus" *ngIf="!isViewMode">
                  <button type="button" [disabled]="item.QuantityToShow === 0" class="btn-minus"
                    (click)="decrementStepProduct(step, item)">
                    -
                  </button>
                  {{ item.QuantityToShow }}
                  <button type="button" class="btn-more" [disabled]="
                  item.QuantityToShow === step.MaxQuantity ||
                  step.Quantity === step.MaxQuantity ||
                  step.ProductStepSelectedItemsCount >= step.MaxQuantity ||
                  (!step.CanRepeatSameItem && item.QuantityToShow > 0)
                  " (click)="incrementStepProduct(step, item)">
                    +
                  </button>
                </div>
              </div>
            </div>
            <ng-template #productRadio>
              <mat-radio-group class="radio-group">
                <mat-radio-button class="radio-button" *ngFor="let item of step.StepItems" [value]="item"
                  [checked]="item.QuantityToShow > 0" (click)="checkStepProduct($event, step, item)">
                  <div class="group-info-products">
                    <img *ngIf="item.SmallImageUrl" loading="lazy" [src]="item.SmallImageUrl" [alt]="item.ProductName"
                      (click)="handleImageZoom(true, item.ImageUrl)" srcset="">
                    <div class="list-item">
                      {{ item.ProductName }}
                      <div class="additional-info">
                        {{item.AdditionalInfo}}
                      </div>
                      <span>{{ item.SellPrice | money }}</span>
                    </div>
                  </div>
                </mat-radio-button>
              </mat-radio-group>
              <div class="radio-group" *ngIf="isViewMode">
                <div class="radio-button" *ngFor="let item of step.StepItems">
                  <div class="group-info-products">
                    <img *ngIf="item.SmallImageUrl" loading="lazy" [src]="item.SmallImageUrl" [alt]="item.ProductName"
                      (click)="handleImageZoom(true, item.ImageUrl)" srcset="">
                    <div class="list-item">
                      {{ item.ProductName }}
                      <div class="additional-info">
                        {{item.AdditionalInfo}}
                      </div>
                      <span>{{ item.SellPrice | money }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </section>
        </div>
        <section>
          <div class="title-section">{{'AnyNotes' | i18n}}</div>
          <mat-form-field style="margin-top: 15px;">
            <mat-label>{{'AddANote' | i18n}}</mat-label>
            <textarea matInput name="textArea" id="orderItemObservation" [(ngModel)]="orderItem.Observation"
              rows="4"></textarea>
          </mat-form-field>
        </section>
      </div>
    </div>
    <div class="group-buttons-footer" *ngIf="!parameterization?.StoreIsOpen || parameterization.CanReceiveOrders">
      <div class="btn-more-minus">
        <button type="button" class="btn-minus" [disabled]="orderItem.Quantity === 1"
          (click)="decrementProduct()">-</button>
        <div class="quantity-product">
          {{ orderItem.Quantity }}
        </div>
        <button type="button" class="btn-more"
          [disabled]="orderItem.MaxQuantityOnSale > 0 && (orderItem.Quantity >= orderItem.MaxQuantityOnSale)"
          (click)="incrementProduct()">+</button>
      </div>
      <button type=" button" class="btn-add" [disabled]="!stepItemsRequiredValuesComplete" (click)="saveProduct()">
        <span *ngIf="!isEdit">{{'Add' | i18n}}</span>
        <span *ngIf="isEdit">{{'Update' | i18n}}</span>
        <span> {{ orderItem.TotalValue | money }}</span>
      </button>
    </div>
  </div>
  <div (click)="close()" @overlay class="overlay"></div>
</ng-container>