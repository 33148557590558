<div class="header-bottom-sheet">
    <i class="fa-light fa-xmark" (click)="close()"></i>
</div>
<div class="group-rate">
    <div class="star-rating">
        <i class="fa-duotone fa-star star{{number}}" name="star" *ngFor="let number of [1,2,3,4,5]"
            (mouseenter)="mouseEnter($event, number)" (mouseleave)="mouseLeave($event, number)"
            (click)="click($event, number)">
        </i>
    </div>
    <hr style="margin: 20px 0;">
    <mat-form-field>
        <mat-label>{{'WriteYourReviewHere' | i18n}}</mat-label>
        <textarea matInput name="textArea" [(ngModel)]="rateDescription" rows="4"></textarea>
    </mat-form-field>
    <button type="button" [disabled]="rating === 0" (click)="handleAddRate()">{{'RateTheOrder' | i18n}}</button>
</div>