<div class="pix-content">
    <div class="group-pix">
        <div class="text-pix">
            <h3>{{'CopyQRCode' | i18n}}</h3>
            <span>{{'CopyQRCodeText' | i18n}}</span>
        </div>

        <div class="copy-and-past">
            {{pixCopyAndPast}}
        </div>

        <div class="group-buttons-pix">
            <button type="button" [ngClass]="{'code-copied' : codeCopied}" [disabled]="codeCopied" (click)="copyCode()">
                {{(codeCopied ? 'CodeCopied' : 'CopyCode') | i18n}}
            </button>
            <button type="button" (click)="close()" [disabled]="!codeCopied">
                {{ 'ProceedPayment' | i18n}}
            </button>
        </div>
    </div>
</div>